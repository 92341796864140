.modal-dialog.survey-question-picker-modal {
  min-width: 67rem;
}
.modal-dialog.survey-question-picker-modal input {
  cursor: pointer;
}
.modal-dialog.survey-question-picker-modal table tr :nth-child(3) {
  width: 10rem;
  max-width: 10rem;
}
.modal-dialog.survey-question-picker-modal table tr th:last-child {
  padding: 0 0.5rem;
  text-align: center;
}
.modal-dialog.survey-question-picker-modal table tr td {
  vertical-align: top;
}
.modal-dialog.survey-question-picker-modal table tr td:nth-child(2) {
  font-weight: 600;
  padding-right: 0.25rem;
}
.modal-dialog.survey-question-picker-modal table tr td:nth-child(4) {
  padding-right: 0.5rem;
}
.modal-dialog.survey-question-picker-modal table tr td:nth-child(5) {
  padding-right: 0.5rem;
  white-space: nowrap;
}
.modal-dialog.survey-question-picker-modal table tr td:last-child {
  text-align: center;
}
.modal-dialog.survey-question-picker-modal table tr td:last-child img {
  max-width: 5rem;
  max-height: 5rem;
}

