.project-recruit-collections .body-container {
  height: 100%;
}
.project-recruit-collections table {
  width: fit-content;
}
.project-recruit-collections table td,
.project-recruit-collections table th {
  white-space: nowrap;
}

