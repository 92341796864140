.ranked-edit {
  .rows-value-selector {
    width: 90%;
    font-size: 80%;
    font-weight: 500;
    .ant-select-selection__rendered {
      margin-left: 0.25rem;
    }
  }
  .choice-question-selector {
    min-width: 60%;
  }
  .rows-area {
    min-height: 6rem;
  }
  .ant-select-dropdown-menu-item {
    font-size: 80%;
    margin-left: 0.125rem;
  }
  .choices {
    display: flex;
    .editor-right-column {
      .column-title {
        font-size: 75%;
      }
      > div {
        display: flex;
        align-items: center;
        > .first {
          margin-right: 0.25rem;
          width: 66%;
        }
        > .second {
          margin-right: 0.25rem;
          text-align: center;
          width: auto;
          .image,
          img {
            height: 2rem;
            width: 2.5rem;
          }
          img:hover {
            cursor: pointer;
          }
        }
        > .third {
          margin-right: 0.5rem;
          width: 7rem;
        }
      }
    }
  }
}

.ranked-options {
  label {
    display: block;
    margin-bottom: .5rem;
  }
  .min-choices {
    display: flex;
    align-items: baseline;
    flex-wrap: nowrap;
    white-space: nowrap;
    select {
      margin: 0 0.5rem;
      width: 5rem;
    }
  }
}
