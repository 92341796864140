.project-status-bar {
  .participant-counts-widget {
    display: flex;
    align-items: center;
    flex-flow: column;
    padding: 0.25rem;
    margin-top: 0.25rem;
    width: 8rem;
    height: 2rem;
    user-select: none;
    .active-counts {
      display: flex;
      flex-flow: row;
      align-items: center;
      font-size: 70%;
      line-height: 2;
      width: 100%;
    }
    i.fas {
      font-size: 1rem !important;
    }
    .bubble {
      border-radius: 0.5rem;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.1rem;
      margin-right: 0.5rem;
      width: 2rem;
    }
    .bubble-active {
      background-color: #316500;
    }
    .bubble-remove {
      background-color: var(--red);
    }
    .bubble-accept {
      background-color: var(--status-bar-blue);
    }
    .bubble-invite {
      background-color: var(--gray-700);
    }
  }
  .view-all {
    z-index: 10;
    background-color: var(--white);
    border-radius: 4px;
    height: auto;
    position: absolute;
    top: -20px;
    left: -64px;
  }
}
