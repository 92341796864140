.short-url-options > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.short-url-options > div > div:first-child {
  width: 8rem;
  white-space: nowrap;
}
.short-url-options > div input[type=number] {
  width: 44px;
  height: 22px;
  text-align: end;
  padding: 0;
}

