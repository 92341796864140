$mediaPreviewHeight: 120px;
$mediaPreviewWidth: 240px;

.joiner-editor-media {
  display: flex;
  white-space: nowrap;
  label {
    display: block;
  }
  .row {
    flex-wrap: nowrap;
  }
  .left {
    > .row {
      margin-bottom: 1rem;
      > .label {
        width: 7rem;
      }
      > div:nth-child(2) {
        width: 100%;
      }
    }
  }
  .right {
    width: 100%;
    > .row {
      margin-bottom: 1rem;
      > .label {
        width: 6rem;
      }
      > div:nth-child(2) {
        width: 100%;
      }
    }
  }
}
