.vote-summary {
  background-color: #fff;
  border: 1px solid #d7d7d7;
  font-size: 0.7rem;
}
.vote-summary > div {
  display: flex;
}
.vote-summary > div:first-child {
  font-variant-numeric: tabular-nums;
  justify-content: space-between;
  padding: 0 0.3rem;
}
.vote-summary > div:first-child > div:first-child {
  margin-right: 1rem;
}
.vote-summary > div:nth-child(2) {
  background-color: #eeeeee;
  border-top: 1px solid #d7d7d7;
  height: 0.5rem;
}
.vote-summary > div:nth-child(2) > div:first-child {
  background-color: #71a6cb;
}
.vote-summary > div:nth-child(2) > div:nth-child(3) {
  background-color: #d9d382;
}

.vote-summary-tooltip .tooltip-inner {
  background-color: #f5f5f5;
  border: 1px solid #aaaaaa;
  color: var(--black);
  font-size: 0.75rem;
  max-width: none;
  text-align: left;
  text-transform: lowercase;
}
.vote-summary-tooltip .tooltip-inner .summary-tooltip {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.vote-summary-tooltip .tooltip-inner .box {
  height: 0.5rem;
  width: 1rem;
  border: 1px solid #aaaaaa;
  display: inline-block;
  margin: 0 0.5rem;
}

