.participant-enrollment-select {
  display: flex;
  align-items: center;
  white-space: nowrap;
  .unselected {
    border: 2px solid var(--red);
  }
  .ant-select * {
    cursor: pointer !important;
  }
  .download-icon {
    position: relative;
    width: 2rem;
    &:hover {
      .dropdown-menu {
        display: block;
        top: 0;
        left: 25px;
        li {
          padding-left: 0.5rem;
          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }

  }
}
