@import '../../../../styles/styleConstants.scss';

.modal-body {
  .participant-data-columns {
    overflow-y: auto;
    .hidden-section,
    .visible-section {
      display: inline-block;
      width: 48%;
      border: $border;
      padding: 0.5rem;
      font-size: 90%;
      vertical-align: top;
      .col-sm-2 {
        padding-left: 0;
      }
      .visible-columns {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $gray-500;
        margin-bottom: 0.25rem;
        height: 2rem;
      }
      .col-number {
        width: 12%;
      }
      .column-item {
        width: 88%;
      }
      .temp {
        display: flex;
        align-items: center;
      }
      .place-holder {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        height: 2em;
        border: 1px dashed $gray-500;
      }
      .column {
        width: 100%;
        height: 90%;
        display: inline-flex;
        align-items: baseline;
        justify-content: space-between;
        .title,
        .visible-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: inherit;
          display: inline-flex;
          align-items: center;
        }
        .title {
          width: 8rem;
          font-size: inherit;
          font-weight: 400;
        }
        .visible-title {
          width: 6.6rem;
        }
        .no-style {
          border: none;
          background: none;
          .fas {
            color: $black;
          }
        }
        .no-style:hover {
          cursor: pointer;
        }
      }
      .wider {
        width: 100%;
      }
    }
    .hidden-section {
      margin-right: 0.5rem;
    }
    .visible-section {
      margin-left: 0.5rem;
    }
    .column-name {
      border: $border;
      margin: 0.25rem 0.5rem;
      vertical-align: middle;
    }
  }
}
