.panel-configs .panel-name-row {
  display: flex;
  align-items: baseline;
}
.panel-configs .panel {
  padding-left: 0.5rem;
}
.panel-configs .selected-panel {
  border: var(--border);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  min-width: max-content;
}
.panel-configs .selected-panel .param-overrides {
  display: flex;
}
.panel-configs .selected-panel .param-overrides > div {
  margin-right: 1rem;
}
.panel-configs .selected-panel .param-overrides .param-override-row {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.panel-configs .selected-panel .param-overrides .param-override-row > * {
  margin-right: 0.5rem;
}
.panel-configs .selected-panel .param-overrides .param-override-row input {
  width: 6rem;
}
.panel-configs .selected-panel .param-overrides .param-override-row .param-type-small {
  font-size: 80%;
  white-space: nowrap;
}
.panel-configs .samplify-config {
  margin-left: 1rem;
}
.panel-configs .samplify-config > div {
  margin-bottom: 0.5rem;
}
.panel-configs .samplify-config input {
  display: inline-block;
}
.panel-configs .samplify-config .read-only-url-row {
  margin-left: 0.5rem;
}
.panel-configs .samplify-config .read-only-url-row > * {
  font-size: 85%;
}
.panel-configs .samplify-config .read-only-url-row > label {
  white-space: nowrap;
  width: 6rem;
}
.panel-configs .samplify-config .read-only-url-row > input {
  width: 50rem;
}
.panel-configs .custom-panel-config {
  border: var(--border);
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  width: min-content;
}
.panel-configs .custom-panel-config .add-link-param-link {
  margin-right: 2rem;
}
.panel-configs .custom-panel-config .btn-warn {
  display: block;
  margin: 0 0 0 auto;
}

