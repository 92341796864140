.read-only-banner {
  position: fixed;
  left: 50%;
  top: 3rem;
  transform: translateX(-50%);
  z-index: 500;
  h2 {
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    padding: 0.5rem 1rem;
    display: inline-block;
    color: white;
    text-shadow: 2px 2px black;
    margin: 0;
  }
}
