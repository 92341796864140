.modal-dialog.edit-media-modal {
  max-width: 50rem;
}
.modal-dialog.edit-media-modal .modal-body .modal-left-column {
  width: 10rem;
}
.modal-dialog.edit-media-modal .modal-body .modal-left-column .label {
  font-weight: bolder;
}
.modal-dialog.edit-media-modal .modal-body .modal-left-column .stim-type-selector {
  font-weight: 600;
}
.modal-dialog.edit-media-modal .modal-body .modal-right-column {
  max-width: 70%;
}
.modal-dialog.edit-media-modal .modal-body .modal-right-column .text-stim-quill {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--white);
}
.modal-dialog.edit-media-modal .modal-body .modal-right-column .text-stim-quill .ql-toolbar {
  border-radius: 4px 4px 0 0;
}
.modal-dialog.edit-media-modal .modal-body .modal-right-column .quill-text-field {
  height: 8rem;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
}

