.joiner-editor-media {
  display: flex;
  white-space: nowrap;
}
.joiner-editor-media label {
  display: block;
}
.joiner-editor-media .row {
  flex-wrap: nowrap;
}
.joiner-editor-media .left > .row {
  margin-bottom: 1rem;
}
.joiner-editor-media .left > .row > .label {
  width: 7rem;
}
.joiner-editor-media .left > .row > div:nth-child(2) {
  width: 100%;
}
.joiner-editor-media .right {
  width: 100%;
}
.joiner-editor-media .right > .row {
  margin-bottom: 1rem;
}
.joiner-editor-media .right > .row > .label {
  width: 6rem;
}
.joiner-editor-media .right > .row > div:nth-child(2) {
  width: 100%;
}

