.data-export {
  width: 50%;
}
.data-export .title {
  font-weight: 600;
}
.data-export .data-export-buttons {
  margin: 1rem 0;
}
.data-export .data-export-buttons .button {
  margin-right: 1rem;
}
.data-export .file-title {
  margin: 1rem 0;
  font-weight: 600;
}

