.survey-comparative-views {
  .translate-label {
    margin-left: 0.4rem;
  }
  .filter-bar {
    margin: 0 0.4rem;
    border-radius: 5px;
  }
  .comparative-view {
    border: 1px solid var(--gray-300);
    border-radius: 5px;
    margin: 0.4rem;
    padding: 1rem;
    width: auto;
    min-width: fit-content;
    .name-and-config {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      .view-title {
        max-width: 15rem;
      }
      .select {
        max-width: 12rem;
      }
      .display-options {
        width: 9rem;
        display: inline-block;
      }
      .copy-clone {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 5.5rem;
        > * {
          margin: 0 0.5rem;
        }
      }
      i:hover,
      .display-options:hover,
      .select:hover {
        cursor: pointer;
      }
    }
    .table-and-sig-testing {
      display: flex;
      justify-content: space-between;
      .comparative-table-view {
        min-width: 80%;
        table {
          width: 100%;
          tr {
            line-height: 2;
          }
        }
      }
      .sig-testing-selection {
        margin: 0 1rem;
      }
    }
  }
}

.modal .comparative-view-question-answer {
  max-width: 70vw;
  margin: 1.75rem auto;
  .field-name {
    width: 10rem;
    display: inline-block;
    margin-left: 1rem;
  }
  .survey-content {
    border-bottom: 4px solid var(--gray-700);
  }
  .truncated {
    display: inline-block;
    max-width: inherit;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .non-truncated {
    color: var(--gray-900);
    white-space: normal;
  }
  .selected-row {
    background-color: var(--lightblue);
    border: 2px solid #95cafe;
  }
  .disabled-row {
    cursor: default;
    opacity: 0.25;
    &:hover {
      background-color: inherit;
    }
  }
  .hidden-answer-selections {
    display: none;
  }
  .answer-selections {
    display: block;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    white-space: normal;
    a {
      padding: 0 0.3rem;
    }
  }
  .selected-answer {
    background-color: var(--lightblue);
  }
  .capitalize {
    text-transform: capitalize;
  }
}
