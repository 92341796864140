.detect-options {
  margin-top: 1rem;
}
.detect-options .detect-options-row {
  white-space: nowrap;
  align-items: center;
}
.detect-options .detect-options-row > :first-child {
  width: 14rem;
}
.detect-options .detect-options-row > :nth-child(2) select {
  display: inline-block;
  margin-left: 1rem;
  width: auto;
}

