.fa.fa-exclamation-triangle,
.far.fa-exclamation-triangle,
.fas.fa-exclamation-triangle {
  color: darkorange;
}
.fa.fa-check,
.far.fa-check,
.fas.fa-check {
  color: green;
}

