.report-common-fields {
  .flex-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    > div:first-child {
      width: 8rem;
    }
    > div:nth-child(2) {
      flex-grow: 1;
    }
    input {
      width: 40rem;
    }
  }
}
