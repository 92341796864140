.uploadLogoModal {
  .modal-content {
    width: 40rem;
  }

  .instructionsHeader {
    font-weight: 600;
  }

  .selectImgButton {
    margin: 1rem 1rem 1rem 0;
  }

  .previewContainer {
    border: var(--border);
    margin: 1rem 0;
    > div:first-child {
      border-bottom: var(--border);
      padding: 1rem;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0;
    > button {
      margin-left: 2rem;
    }
  }
}
