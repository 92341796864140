.rd-status-bar .rd-time-remaining-widget {
  display: flex;
  align-items: center;
  line-height: 1;
}
.rd-status-bar .rd-time-remaining-widget .time-span {
  font-size: 0.9rem !important;
  font-weight: 600;
  text-align: right;
}
.rd-status-bar .rd-time-remaining-widget .time-span > span {
  white-space: nowrap;
}
.rd-status-bar .rd-time-remaining-widget .time-span > :last-child {
  margin-right: 0.6rem;
}
.rd-status-bar .rd-time-remaining-widget .clock {
  margin-right: 0.5rem;
  font-size: 1.2rem !important;
}
.rd-status-bar .rd-time-remaining-widget .time-remaining-switch {
  margin-left: 0.5rem;
  width: 5.5rem;
  min-width: 5.5rem;
}
.rd-status-bar .rd-time-remaining-widget .time-remaining-switch.ant-switch-checked {
  background-color: var(--status-bar-blue);
}

