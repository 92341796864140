@import '../../../../styles/styleConstants.scss';

.choice-options {
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .choice-types {
    margin-left: .5rem;
  }
  .select-option-rows {
    align-items: baseline;
  }
  .select-options {
    width: 21%;
  }
  .select-option-input {
    width: 9%;
  }
  .select-option-input-min {
    width: 9%;
  }
  .select-option-input-max {
    width: 12%;
  }
}

.choice-editor {
  white-space: nowrap;
  > .mb-2 {
    display: flex;
    flex-wrap: nowrap;
    > .editor-right-column {
      width: 90%;
      .add-remove-icons {
        margin-left: 1rem;
      }
    }
    .choice-row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 0.25rem;
      > :first-child {
        width: 28rem;
      }
      > :nth-child(2) {
        width: auto;
      }
      > :nth-child(3) {
        width: 6rem;
      }
      > :nth-child(4) {
        padding: 0.25rem 0.5rem;
        width: 4rem;
      }
    }
    .other-choice-row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 0.25rem;
      > :first-child {
        width: 25rem;
      }
    }
    .other-input-select {
      width: 25rem;
    }
    .media-input {
      text-align: center;
      border: $border;
      border-radius: 3px;
      padding: 0 3px;
      background-color: $white;
      line-height: initial;
      .fa-image {
        color: $gray-800;
      }
    }
    .add-text {
      font-size: 1rem;
      font-weight: 600;
      color: #4fc328;
      cursor: pointer;
    }
  }
  .label-text {
    font-size: 75%;
    margin-left: 0.5%;
  }
  .image,
  .img {
    width: 2.5rem;
    height: 2rem;
  }
  .image:hover {
    cursor: pointer;
  }
}
