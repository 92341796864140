.choice-question-details .controls-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
}
.choice-question-details .controls-row button {
  margin: 0 0 0 1rem;
}
.choice-question-details .controls-row .export-buttons {
  margin-left: auto;
}
.choice-question-details .controls-row .export-buttons .fa-file-excel {
  color: var(--green);
  margin: 0 1rem;
}
.choice-question-details .controls-row .export-buttons .fa-file-powerpoint {
  color: var(--red);
  margin: 0 1rem;
}
.choice-question-details .table.invoke-table {
  font-size: 0.9rem;
}
.choice-question-details .table.invoke-table td {
  vertical-align: middle;
}
.choice-question-details .thumbnail {
  width: 4rem;
}
.choice-question-details .status-bar {
  background-color: var(--light-blue);
}

.modal-body .choice-question-custom-nets .center {
  margin: 0 auto;
  width: 100%;
}
.modal-body .choice-question-custom-nets th {
  background-color: var(--gray-300);
  min-width: 2rem;
}
.modal-body .choice-question-custom-nets tr td:first-child {
  padding-left: 0.3rem;
}
.modal-body .choice-question-custom-nets .odd-row {
  background-color: rgba(0, 0, 0, 0.0509803922);
}
.modal-body .choice-question-custom-nets .nets {
  width: 5rem;
  text-align: center;
}
.modal-body .choice-question-custom-nets .text-ellipsis {
  max-width: 15rem;
}
.modal-body .choice-question-custom-nets .thumbnail {
  width: 4rem;
}

