.participant-enrollment-select {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.participant-enrollment-select .unselected {
  border: 2px solid var(--red);
}
.participant-enrollment-select .ant-select * {
  cursor: pointer !important;
}
.participant-enrollment-select .download-icon {
  position: relative;
  width: 2rem;
}
.participant-enrollment-select .download-icon:hover .dropdown-menu {
  display: block;
  top: 0;
  left: 25px;
}
.participant-enrollment-select .download-icon:hover .dropdown-menu li {
  padding-left: 0.5rem;
}
.participant-enrollment-select .download-icon:hover .dropdown-menu li a {
  color: inherit;
  text-decoration: none;
}

