.rd-position-filters {
  height: 2rem;
  margin: 0 0.5rem;
}
.rd-position-filters .toggle-selector {
  display: flex;
  position: relative;
  height: 2rem;
  font-size: 0.9rem;
  border: 1px solid #d7d7d7;
  background-color: #fff;
}
.rd-position-filters .toggle-selector input {
  border: none;
  padding-left: 0.5rem;
  width: 90%;
}
.rd-position-filters .toggle-selector .selector-button {
  position: absolute;
  right: 0;
  display: inline-flex;
  flex-flow: column;
  padding: 0.1rem 0.5rem 0 0.3rem;
  min-width: 1rem;
  align-self: center;
}
.rd-position-filters .selector {
  z-index: 20;
  position: sticky;
  border: 1px solid #d7d7d7;
  color: #949494;
  background: #fff;
  padding: 0.01rem 0;
  list-style: none;
  font-size: 0.9rem;
}
.rd-position-filters .selector .position-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
}
.rd-position-filters .selector .position-option .for-checkbox {
  position: relative;
  margin: 0.3rem 0.5rem 0 0;
  padding: 0;
  bottom: 2px;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  line-height: inherit;
  font-weight: 600;
}
.rd-position-filters .selector .position-option:hover {
  background-color: #daeff7;
}

