.session-emotion-data .settings-area,
.session-dial-data .settings-area {
  display: flex;
  align-items: baseline;
}
.session-emotion-data .settings-area label,
.session-dial-data .settings-area label {
  font-size: 0.9rem;
}
.session-emotion-data .settings-area label:hover,
.session-dial-data .settings-area label:hover {
  cursor: pointer;
}
.session-emotion-data .settings-area .question-select,
.session-dial-data .settings-area .question-select {
  width: auto;
}
.session-emotion-data .emotion-graph-video,
.session-emotion-data .dial-data-graph-and-video,
.session-dial-data .emotion-graph-video,
.session-dial-data .dial-data-graph-and-video {
  display: flex;
  margin: 1rem 0;
}
.session-emotion-data .emotion-graph-video .dial-data-graph .recharts-wrapper .custom-tooltip,
.session-emotion-data .dial-data-graph-and-video .dial-data-graph .recharts-wrapper .custom-tooltip,
.session-dial-data .emotion-graph-video .dial-data-graph .recharts-wrapper .custom-tooltip,
.session-dial-data .dial-data-graph-and-video .dial-data-graph .recharts-wrapper .custom-tooltip {
  border: 1px solid var(--black);
  background-color: rgb(239, 247, 223);
  font-size: 80%;
  padding: 0 0.25rem;
}
.session-emotion-data .emotion-graph-video .dial-data-graph .recharts-wrapper .custom-tooltip p,
.session-emotion-data .dial-data-graph-and-video .dial-data-graph .recharts-wrapper .custom-tooltip p,
.session-dial-data .emotion-graph-video .dial-data-graph .recharts-wrapper .custom-tooltip p,
.session-dial-data .dial-data-graph-and-video .dial-data-graph .recharts-wrapper .custom-tooltip p {
  margin-bottom: 0;
}
.session-emotion-data .emotion-graph-video .emotion-graph,
.session-emotion-data .dial-data-graph-and-video .emotion-graph,
.session-dial-data .emotion-graph-video .emotion-graph,
.session-dial-data .dial-data-graph-and-video .emotion-graph {
  margin-right: 2rem;
  background-color: var(--white);
}
.session-emotion-data .emotion-graph-video .emotion-graph .recharts-wrapper g.recharts-xAxis g.recharts-cartesian-axis-ticks > g.recharts-cartesian-axis-tick > text > tspan,
.session-emotion-data .dial-data-graph-and-video .emotion-graph .recharts-wrapper g.recharts-xAxis g.recharts-cartesian-axis-ticks > g.recharts-cartesian-axis-tick > text > tspan,
.session-dial-data .emotion-graph-video .emotion-graph .recharts-wrapper g.recharts-xAxis g.recharts-cartesian-axis-ticks > g.recharts-cartesian-axis-tick > text > tspan,
.session-dial-data .dial-data-graph-and-video .emotion-graph .recharts-wrapper g.recharts-xAxis g.recharts-cartesian-axis-ticks > g.recharts-cartesian-axis-tick > text > tspan {
  font-size: 80%;
}
.session-emotion-data .emotion-graph-video .emotion-graph .recharts-wrapper .custom-tooltip,
.session-emotion-data .dial-data-graph-and-video .emotion-graph .recharts-wrapper .custom-tooltip,
.session-dial-data .emotion-graph-video .emotion-graph .recharts-wrapper .custom-tooltip,
.session-dial-data .dial-data-graph-and-video .emotion-graph .recharts-wrapper .custom-tooltip {
  border: 1px solid var(--black);
  background-color: rgb(239, 247, 223);
  font-size: 80%;
}
.session-emotion-data .emotion-graph-video .emotion-graph .recharts-wrapper .custom-tooltip p,
.session-emotion-data .dial-data-graph-and-video .emotion-graph .recharts-wrapper .custom-tooltip p,
.session-dial-data .emotion-graph-video .emotion-graph .recharts-wrapper .custom-tooltip p,
.session-dial-data .dial-data-graph-and-video .emotion-graph .recharts-wrapper .custom-tooltip p {
  margin-bottom: 0;
}
.session-emotion-data .emotion-graph-video .emotion-graph .graph-controls,
.session-emotion-data .dial-data-graph-and-video .emotion-graph .graph-controls,
.session-dial-data .emotion-graph-video .emotion-graph .graph-controls,
.session-dial-data .dial-data-graph-and-video .emotion-graph .graph-controls {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 0.5rem;
}
.session-emotion-data .emotion-graph-video .emotion-graph .graph-controls label,
.session-emotion-data .emotion-graph-video .emotion-graph .graph-controls select,
.session-emotion-data .dial-data-graph-and-video .emotion-graph .graph-controls label,
.session-emotion-data .dial-data-graph-and-video .emotion-graph .graph-controls select,
.session-dial-data .emotion-graph-video .emotion-graph .graph-controls label,
.session-dial-data .emotion-graph-video .emotion-graph .graph-controls select,
.session-dial-data .dial-data-graph-and-video .emotion-graph .graph-controls label,
.session-dial-data .dial-data-graph-and-video .emotion-graph .graph-controls select {
  margin: 0 1rem 0 0;
}
.session-emotion-data .emotion-graph-video .emotion-graph .graph-controls select,
.session-emotion-data .dial-data-graph-and-video .emotion-graph .graph-controls select,
.session-dial-data .emotion-graph-video .emotion-graph .graph-controls select,
.session-dial-data .dial-data-graph-and-video .emotion-graph .graph-controls select {
  max-width: 20rem;
  width: auto;
}
.session-emotion-data .emotion-graph-video .video,
.session-emotion-data .dial-data-graph-and-video .video,
.session-dial-data .emotion-graph-video .video,
.session-dial-data .dial-data-graph-and-video .video {
  height: 300px;
  width: 600px;
  margin: 0 auto;
}
.session-emotion-data .emotion-graph-video .video video,
.session-emotion-data .dial-data-graph-and-video .video video,
.session-dial-data .emotion-graph-video .video video,
.session-dial-data .dial-data-graph-and-video .video video {
  height: 100%;
  width: 100%;
  margin: auto;
}
.session-emotion-data .emotion-graph-video .video video:focus,
.session-emotion-data .dial-data-graph-and-video .video video:focus,
.session-dial-data .emotion-graph-video .video video:focus,
.session-dial-data .dial-data-graph-and-video .video video:focus {
  outline: none;
}
.session-emotion-data .overlay-graph-and-video,
.session-dial-data .overlay-graph-and-video {
  width: 960px;
  height: 560px;
  position: relative;
  margin: 0 auto;
}
.session-emotion-data .overlay-graph-and-video .dial-data-graph,
.session-emotion-data .overlay-graph-and-video .emotion-data-graph,
.session-emotion-data .overlay-graph-and-video .video,
.session-dial-data .overlay-graph-and-video .dial-data-graph,
.session-dial-data .overlay-graph-and-video .emotion-data-graph,
.session-dial-data .overlay-graph-and-video .video {
  width: inherit !important;
  height: inherit !important;
  position: absolute;
  top: 0;
  left: 0;
}
.session-emotion-data .overlay-graph-and-video .dial-data-graph .vjs-control-bar,
.session-emotion-data .overlay-graph-and-video .emotion-data-graph .vjs-control-bar,
.session-emotion-data .overlay-graph-and-video .video .vjs-control-bar,
.session-dial-data .overlay-graph-and-video .dial-data-graph .vjs-control-bar,
.session-dial-data .overlay-graph-and-video .emotion-data-graph .vjs-control-bar,
.session-dial-data .overlay-graph-and-video .video .vjs-control-bar {
  z-index: 101;
}
.session-emotion-data .overlay-graph-and-video .dial-data-graph,
.session-emotion-data .overlay-graph-and-video .emotion-graph,
.session-dial-data .overlay-graph-and-video .dial-data-graph,
.session-dial-data .overlay-graph-and-video .emotion-graph {
  position: relative;
  z-index: 100;
}
.session-emotion-data .emotion-filters .emotion-filters-tables table th:first-child,
.session-emotion-data .emotion-filters .emotion-filters-tables table td:first-child,
.session-emotion-data .emotion-filters .dial-data-filters table th:first-child,
.session-emotion-data .emotion-filters .dial-data-filters table td:first-child,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables table th:first-child,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables table td:first-child,
.session-emotion-data .dial-data-filters-and-export .dial-data-filters table th:first-child,
.session-emotion-data .dial-data-filters-and-export .dial-data-filters table td:first-child,
.session-dial-data .emotion-filters .emotion-filters-tables table th:first-child,
.session-dial-data .emotion-filters .emotion-filters-tables table td:first-child,
.session-dial-data .emotion-filters .dial-data-filters table th:first-child,
.session-dial-data .emotion-filters .dial-data-filters table td:first-child,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables table th:first-child,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables table td:first-child,
.session-dial-data .dial-data-filters-and-export .dial-data-filters table th:first-child,
.session-dial-data .dial-data-filters-and-export .dial-data-filters table td:first-child {
  text-align: left;
}
.session-emotion-data .emotion-filters .emotion-filters-tables table th,
.session-emotion-data .emotion-filters .emotion-filters-tables table td,
.session-emotion-data .emotion-filters .dial-data-filters table th,
.session-emotion-data .emotion-filters .dial-data-filters table td,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables table th,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables table td,
.session-emotion-data .dial-data-filters-and-export .dial-data-filters table th,
.session-emotion-data .dial-data-filters-and-export .dial-data-filters table td,
.session-dial-data .emotion-filters .emotion-filters-tables table th,
.session-dial-data .emotion-filters .emotion-filters-tables table td,
.session-dial-data .emotion-filters .dial-data-filters table th,
.session-dial-data .emotion-filters .dial-data-filters table td,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables table th,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables table td,
.session-dial-data .dial-data-filters-and-export .dial-data-filters table th,
.session-dial-data .dial-data-filters-and-export .dial-data-filters table td {
  text-align: left;
}
.session-emotion-data .emotion-filters .emotion-filters-tables table th label,
.session-emotion-data .emotion-filters .emotion-filters-tables table td label,
.session-emotion-data .emotion-filters .dial-data-filters table th label,
.session-emotion-data .emotion-filters .dial-data-filters table td label,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables table th label,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables table td label,
.session-emotion-data .dial-data-filters-and-export .dial-data-filters table th label,
.session-emotion-data .dial-data-filters-and-export .dial-data-filters table td label,
.session-dial-data .emotion-filters .emotion-filters-tables table th label,
.session-dial-data .emotion-filters .emotion-filters-tables table td label,
.session-dial-data .emotion-filters .dial-data-filters table th label,
.session-dial-data .emotion-filters .dial-data-filters table td label,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables table th label,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables table td label,
.session-dial-data .dial-data-filters-and-export .dial-data-filters table th label,
.session-dial-data .dial-data-filters-and-export .dial-data-filters table td label {
  margin-bottom: 0;
}
.session-emotion-data .emotion-filters .emotion-filters-tables .color-box,
.session-emotion-data .emotion-filters .dial-data-filters .color-box,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables .color-box,
.session-emotion-data .dial-data-filters-and-export .dial-data-filters .color-box,
.session-dial-data .emotion-filters .emotion-filters-tables .color-box,
.session-dial-data .emotion-filters .dial-data-filters .color-box,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables .color-box,
.session-dial-data .dial-data-filters-and-export .dial-data-filters .color-box {
  border: var(--border);
  height: 1rem;
  width: 1rem;
  margin: auto;
}
.session-emotion-data .emotion-filters .emotion-filters-tables,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables,
.session-dial-data .emotion-filters .emotion-filters-tables,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables {
  padding: 0 0.5rem;
  background-color: var(--white);
  border: 1px solid var(--gray-400);
  margin-right: 1.5rem;
  display: flex;
  max-height: 16rem;
  overflow-y: overlay;
}
.session-emotion-data .emotion-filters .emotion-filters-tables table,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables table,
.session-dial-data .emotion-filters .emotion-filters-tables table,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables table {
  display: inline-block;
}
.session-emotion-data .emotion-filters .emotion-filters-tables::-webkit-scrollbar,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables::-webkit-scrollbar,
.session-dial-data .emotion-filters .emotion-filters-tables::-webkit-scrollbar,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.session-emotion-data .emotion-filters .emotion-filters-tables::-webkit-scrollbar-thumb,
.session-emotion-data .dial-data-filters-and-export .emotion-filters-tables::-webkit-scrollbar-thumb,
.session-dial-data .emotion-filters .emotion-filters-tables::-webkit-scrollbar-thumb,
.session-dial-data .dial-data-filters-and-export .emotion-filters-tables::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.session-emotion-data .dial-data-filters-and-export,
.session-dial-data .dial-data-filters-and-export {
  display: flex;
  margin: 1rem 0;
}
.session-emotion-data .dial-data-filters-and-export .dial-data-filters,
.session-dial-data .dial-data-filters-and-export .dial-data-filters {
  margin-right: 2rem;
}
.session-emotion-data .dial-data-filters-and-export .dial-data-filters table,
.session-dial-data .dial-data-filters-and-export .dial-data-filters table {
  width: 100%;
  display: table;
}
.session-emotion-data .dial-data-filters-and-export .dial-data-filters table th:nth-child(2),
.session-dial-data .dial-data-filters-and-export .dial-data-filters table th:nth-child(2) {
  text-align: center;
}
.session-emotion-data .dial-data-filters-and-export .dial-data-filters .title,
.session-dial-data .dial-data-filters-and-export .dial-data-filters .title {
  font-weight: 600;
}
.session-emotion-data .dial-data-filters-and-export .dial-data-filters .filters-table,
.session-dial-data .dial-data-filters-and-export .dial-data-filters .filters-table {
  margin: 1rem 0;
  background-color: var(--white);
  border: var(--border);
  width: 600px;
  padding: 0.5rem;
}
.session-emotion-data .dial-data-filters-and-export .data-export,
.session-dial-data .dial-data-filters-and-export .data-export {
  margin: 0 auto;
}
.session-emotion-data .fa.fa-exclamation-triangle,
.session-emotion-data .far.fa-exclamation-triangle,
.session-emotion-data .fas.fa-exclamation-triangle,
.session-dial-data .fa.fa-exclamation-triangle,
.session-dial-data .far.fa-exclamation-triangle,
.session-dial-data .fas.fa-exclamation-triangle {
  color: red;
}
.session-emotion-data .fa.fa-check,
.session-emotion-data .far.fa-check,
.session-emotion-data .fas.fa-check,
.session-dial-data .fa.fa-check,
.session-dial-data .far.fa-check,
.session-dial-data .fas.fa-check {
  color: green;
}

.modal .emotion-settings-modal {
  background: inherit;
}

