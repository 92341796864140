.whitelist-modal-content {
  .ant-select-selection__choice__content {
    font-weight: 600;
  }
  .ant-select-selection__rendered {
    min-height: 5rem;
  }
}

.whitelist-modal-instructions {
  margin-bottom: 0.7rem;
}
