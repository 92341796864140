.crosstab-report .config-area .config-right {
  border: var(--border);
  flex-grow: 1;
  height: 100%;
  padding: 0.25rem 0.5rem;
}
.crosstab-report .config-area .config-right .title-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.crosstab-report .config-area .config-right .title-row > div:first-child {
  font-weight: 600;
  margin-right: 2rem;
}
.crosstab-report .config-area .config-right #banners {
  height: auto;
  margin-bottom: 0.5rem;
}
.crosstab-report .config-area .config-right #banners > div {
  border: var(--border);
  display: flex;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}
.crosstab-report .config-area .config-right #banners > div > div:first-child {
  font-size: 80%;
  width: 5rem;
  min-width: 5rem;
}
.crosstab-report .config-area .config-right #banners > div > div:nth-child(2) {
  margin-right: 1rem;
}
.crosstab-report .config-area .config-right #banners > div > div:last-child {
  margin-left: auto;
}
.crosstab-report .config-area .config-right #banners > div > div:last-child > i.fa-times {
  color: var(--gray-700);
  cursor: pointer;
  vertical-align: top;
}
.crosstab-report .config-area .config-right .buttons-row button {
  margin: 0 0.5rem 0 0;
}

