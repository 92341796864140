.modal-dialog.joiner-preview-modal {
  max-width: 90%;
  height: 90%;
  .modal-content {
    height: 100%;
    .embed-responsive {
      width: 100%;
      height: 100%;
      iframe {
        width: inherit;
        height: inherit;
      }
    }
  }
}
