.vfg-icon {
  display: grid;
  place-content: center;
}
.vfg-icon i {
  border: 1px solid;
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
}

