@import '../../../../styles/styleConstants.scss';

.modal-dialog.joiner-editor-modal {
  max-width: 58rem;
  .modal-content {
    border: 1px solid $gray-900;
    .modal-body {
      display: flex;
      padding: 0;
      .joiner-editor-tabs {
        background-color: $gray-300;
        border: 1px solid $gray-700;
        margin: 0;
        min-height: 23rem;
        list-style-type: none;
        padding: 0;
        li {
          border-top: 1px solid $gray-700;
          border-bottom: 1px solid $gray-700;
          cursor: pointer;
          padding: 0.5rem 0.5rem 0.2rem 0.5rem;
          text-align: center;
          &.selected {
            background-color: #f2f2f2;
          }
          .fa,
          .fas,
          .far {
            color: $gray-800;
          }
          > div:nth-child(2) {
            font-size: 0.75rem;
          }
        }
      }
      .joiner-editor {
        background-color: $gray-100;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .joiner-editor-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          margin: 0.5rem 0.5rem 0 0.5rem;
          > div:first-child {
            display: inline-flex;
            justify-content: space-around;
            align-items: center;
            height: 2.5rem;
            .joiner-icon {
              max-height: 100%;
              margin-right: 0.5rem;
              text-align: center;
              &.fa-at {
                color: $gray-600;
                font-size: 120%;
              }
            }
          }
          > div:nth-child(2) {
            > * {
              margin-left: 1rem;
            }
          }
        }
        .joiner-editor-form {
          position: relative;
          border: $border;
          border-radius: 0.3rem;
          height: 100%;
          margin: 0.5rem;
          padding: 0.5rem;
          width: 98%;
          .editor-left-column {
            width: 9rem;
          }
          .editor-right-column {
            width: 100%;
          }
          .editor-right-column-flush {
            margin-left: 8rem;
          }
          .editor-right-column-video-radio1 {
            margin-left: 2rem;
          }
          .editor-right-column-video-radio2 {
            margin-left: 4.88rem;
          }
          .editor-right-column-web-radio1 {
            margin-left: 1rem;
          }
          .editor-right-column-web-radio2 {
            margin-left: 7.5rem;
          }
          .label {
            font-weight: 600;
          }
          .import-and-language {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
          }
          .stim-text-translation {
            float: right;
          }
          .joiner-editor-common-fields {
            white-space: nowrap;
            > div {
              display: flex;
              flex-wrap: nowrap;
              margin-bottom: 1rem;
              > .editor-right-column {
                display: flex;
              }
            }
            .editor-right-column {
              align-items: start;
              .ql-formats {
                margin-right: 0.75rem;
                button {
                  background-color: initial !important;
                }
              }
            }
            .question-title-field {
              margin-right: 4rem;
              width: 100%;
              max-width: 16rem;
            }
            .select-concept {
              width: 100%;
            }
            .question-group-field {
              margin-left: 1rem;
              width: 100%;
            }
            .editor-quill-container {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              width: 0;
              background-color: $white;
            }
            .ql-toolbar {
              display: flex;
              flex-wrap: wrap;
            }
            .question-text-field {
              min-height: 10rem;
              max-height: 30rem;
              overflow-y: auto;
            }
          }

          // Custom editor styles
          .dialsmith-stim-edit,
          .matrix-edit,
          .ranked-edit,
          .concept-rotation-media {
            .editor-left-column {
              width: 13rem;
            }
          }
        }
        .joiner-editor-buttons {
          padding: 0.5rem;
          text-align: right;
        }
      }
    }
  }
}
