.modal-dialog.participant-data-picker-modal {
  min-width: 67rem;
  input {
    cursor: pointer;
  }
  table {
    // header & cell styles
    tr {
      :nth-child(2) {
        // label
        width: 10rem;
        max-width: 10rem;
      }
      :nth-child(3) {
        // value
        width: 53rem;
        max-width: 53rem;
      }
    }

    // cell-only styles
    tr td {
      vertical-align: top;
      &:nth-child(2) {
        // label
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
