.modal-dialog.edit-media-modal {
  max-width: 50rem;
  .modal-body {
    .modal-left-column {
      width: 10rem;
      .label {
        font-weight: bolder;
      }
      .stim-type-selector {
        font-weight: 600;
      }
    }
    .modal-right-column {
      max-width: 70%;
      .text-stim-quill {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background-color: var(--white);
        .ql-toolbar {
          border-radius: 4px 4px 0 0;
        }
      }
      .quill-text-field {
        height: 8rem;
        overflow-y: auto;
        border-radius: 0 0 4px 4px;
      }
    }
  }
}
