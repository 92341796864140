@import '../../../../styles/styleConstants.scss';

.matrix-options {
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .matrix-types {
    margin-left: .5rem;
  }
  .range-labels {
    .option-label {
      font-weight: 600;
    }
    label {
      margin-bottom: 0.25rem;
    }
  }
}

.matrix-edit {
  white-space: nowrap;
  .rows-value-selector {
    width: 90%;
    font-size: 80%;
    font-weight: 500;
    .ant-select-selection__rendered {
      margin-left: 0.25rem;
    }
  }
  .choice-question-selector {
    min-width: 60%;
  }
  .rows-area {
    min-height: 6rem;
  }
  .ant-select-dropdown-menu-item {
    font-size: 80%;
    margin-left: 0.125rem;
  }
  .mb-2 {
    display: flex;
    flex-wrap: nowrap;
    .editor-right-column {
      display: flex;
      align-items: center;
    }
    .labels {
      width: 54%;
      .label-input {
        width: 100%;
      }
    }
    .media-column {
      width: 7%;
    }
    .media-input {
      border: $border;
      border-radius: 3px;
      padding: 0 3px;
      background-color: $white;
      text-align: center;
      line-height: initial;
      .fa-image {
        color: $gray-800;
      }
      .image,
      .img {
        width: 2.5rem;
        height: 2rem;
      }
      .real-image {
        width: 2.5rem;
        height: 2rem;
      }
    }
    .row-title-input {
      width: 20%;
    }
    .add-text {
      font-size: 1.25rem;
    }
    .add-input {
      color: #4fc328;
      font-weight: 600;
    }
    .remove-input {
      color: red;
    }
    .media-input,
    .add-input > .add-area :hover {
      cursor: pointer;
    }
    .choices {
      width: 50%;
    }
    .abbreviate-input {
      width: 20%;
    }
    .value-input {
      width: 10%;
    }
    .bipolar-labels {
      width: 35%;
      .label-input {
        width: 100%;
      }
    }
    .bipolar-labels-title {
      width: 17.5%;
      .label-input,
      .row-title-input {
        width: 100%;
      }
    }
  }
}
