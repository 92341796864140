.filter-label {
  background-color: #c3daea;
  cursor: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2;
  margin: 0.1rem 0.3rem 0.1rem 0;
  max-width: 100%;
  padding: 0 0.5rem;
  font-size: 0.7rem;
  font-weight: 600;
  white-space: nowrap;
  &.clickable {
    cursor: pointer;
  }
  .fa-times {
    color: var(--gray-700);
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}
