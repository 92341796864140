.rd-session-mgmt {
  display: flex;
}
.rd-session-mgmt .question-list.collapsed-view {
  max-width: 15%;
  min-width: 15%;
}
.rd-session-mgmt .rd-question-details {
  border: 1px solid black;
  padding: 0.3rem;
  min-width: 85%;
  width: 100%;
}
.rd-session-mgmt .rd-question-details .show-new-responses {
  background-color: #e5f9ee;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.7rem;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}
.rd-session-mgmt .ql-enter,
.rd-session-mgmt .ql-appear {
  transform: rotateY(90deg);
  transform-origin: left;
  transform-style: preserve-3D;
}
.rd-session-mgmt .ql-enter-active,
.rd-session-mgmt .ql-appear-active {
  transform: rotateY(0deg);
  transform-origin: left;
  transform-style: preserve-3D;
  transition: transform 500ms;
}
.rd-session-mgmt .ql-exit {
  transform: rotateY(0deg);
  transform-origin: left;
  transform-style: preserve-3D;
}
.rd-session-mgmt .ql-exit-active {
  transform: rotateY(90deg);
  transform-origin: left;
  transform-style: preserve-3D;
  transition: transform 500ms;
}
.rd-session-mgmt .ql-expand-enter {
  transform: rotateY(90deg);
  transform-origin: left;
  transform-style: preserve-3D;
}
.rd-session-mgmt .ql-expand-enter-active {
  transform: rotateY(0deg);
  transform-origin: left;
  transform-style: preserve-3D;
  transition: transform 500ms;
}
.rd-session-mgmt .qd-horizontal {
  animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

