.modal-dialog.select-survey-modal {
  max-width: 50rem;
  .survey-name-input {
    display: inline-block;
    margin-left: 1rem;
    width: 20rem;
  }
  .survey-table-container {
    margin: 1rem 0 0 1rem;
  }
}
