.template-container,
.upload-translations-container {
  border: var(--border);
  font-size: 0.9rem;
  min-height: 10rem;
  width: 30rem;
  margin-bottom: 0.5rem;
}

.delete-translations-container {
  border: var(--border);
  font-size: 0.9rem;
  min-height: 5rem;
  width: 30rem;
  margin-bottom: 0.5rem;
}

.heading {
  font-weight: 600;
  margin: 0 0.3rem;
}

.text {
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0.3rem 0 0.3rem;
}

.btn {
  margin: 0.2rem 0 0.4rem 0.4rem;
}

.file {
  margin-left: 0.8rem;
}

.file-select {
  margin-bottom: 0.3rem;
}

.survey-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  white-space: nowrap;
  select {
    margin: 0 1rem;
    width: auto;
  }
}

.select-survey {
  font-weight: 600;
}
