.chat-window {
  border: 2px solid #949494;
  box-shadow: 0 0 4px #cccccc;
  letter-spacing: normal;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 20rem;
  z-index: 99999;
}
.chat-window .chat-header {
  background-color: #fff;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.5rem;
  white-space: nowrap;
}
.chat-window .chat-header span {
  align-items: inherit;
  display: inherit;
}
.chat-window .chat-header.minimized {
  background: linear-gradient(to top, #eeeeee, #fff);
}
.chat-window .chat-header .window-title {
  font-weight: 600;
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.chat-window .chat-header .chat-window-command-icon {
  background-color: #eeeeee;
  border-radius: 1px;
  color: #076ac7;
  cursor: pointer;
  height: 1.4rem;
  width: 1.4rem;
  margin-left: 0.5rem;
  padding: 5px 4px;
}
.chat-window .chat-header .chat-window-command-icon span {
  height: 100%;
  width: 100%;
}
.chat-window .chat-header .chat-window-command-icon span.minimize-chat-window-icon {
  border-bottom: 2px solid;
}
.chat-window .chat-header .chat-window-command-icon span.maximize-chat-window-icon {
  border-top: 2px solid;
}
.chat-window .chat-header .chat-window-command-icon span.close-chat-window-icon {
  font-size: 1.5rem;
  justify-content: flex-end;
  padding-bottom: 4px;
}
.chat-window .chat-question-container {
  background: linear-gradient(to top, #eeeeee, #fff);
  border-bottom: 1px solid #dddddd;
  height: 9rem;
  padding: 1.2rem;
}
.chat-window .chat-question-container > div {
  display: flex;
}
.chat-window .chat-question-container .question {
  margin-bottom: 1rem;
  height: 67%;
  line-height: 1.1rem;
  overflow-y: scroll;
}
.chat-window .chat-question-container .question > span:first-child {
  font-weight: 600;
}
.chat-window .chat-question-container .question > span:nth-child(2) {
  color: #545454;
}
.chat-window .chat-question-container .question > span:nth-child(2) .fa {
  padding-right: 0.3rem;
}
.chat-window .chat-question-container .question img {
  float: left;
  margin: 0 0.5rem 0.25rem 0;
  width: 2.66rem;
}
.chat-window .chat-question-container .answer > span:first-child {
  font-weight: 600;
}
.chat-window .chat-question-container .answer > span:nth-child(2) {
  color: #212529;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-window .chat-question-container .answer > span:nth-child(2) select {
  border: none;
  max-width: 16rem;
  padding: 0;
}
.chat-window .chat-question-container .question span,
.chat-window .chat-question-container .answer span {
  padding-right: 0.3rem;
}
.chat-window .chat-area {
  background: linear-gradient(to top, #eeeeee, #fff);
  padding: 0.5rem;
}
.chat-window .chat-area .messages {
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  height: 20rem;
  margin-bottom: 0.5rem;
  overflow-y: scroll;
}
.chat-window .chat-area .messages .text-bubble {
  border-radius: 5px;
  box-shadow: 1px 1px #949494;
  min-height: 1rem;
  min-width: 10rem;
  max-width: 18.7rem;
  margin: 7px 10px;
  padding: 5px;
}
.chat-window .chat-area .messages .text-bubble.right {
  background-color: #d0edf7;
  clear: both;
  float: right;
}
.chat-window .chat-area .messages .text-bubble.left {
  background-color: #fcfad0;
  clear: both;
  float: left;
}
.chat-window .chat-area textarea {
  border: 1px solid #cccccc;
  border-radius: 2px;
  color: #545454;
  height: 5rem;
  margin-bottom: 0.4rem;
  width: 100%;
}
.chat-window .chat-area .send-button-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-window .end-chat-link {
  color: #076ac7;
  cursor: pointer;
}

