.cluster-analysis-settings-modal .modal-body > div {
  border-bottom: var(--border);
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  width: fit-content;
}
.cluster-analysis-settings-modal .modal-body > div > div:first-child {
  font-weight: 600;
  white-space: nowrap;
  width: 10.5rem;
  min-width: 10.5rem;
}
.cluster-analysis-settings-modal .modal-body > div > div:nth-child(2) > * {
  display: block;
  margin-bottom: 0;
}
.cluster-analysis-settings-modal .modal-body > div > div:nth-child(2) .fixed-count-field {
  display: inline-block;
  margin-left: 1rem;
  padding: 0 0 0 0.25rem;
  width: 3rem;
}
.cluster-analysis-settings-modal .modal-body > div:last-child {
  border-bottom: none;
  width: 100%;
}

