.rd-status-bar .rd-deploy-widget {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.rd-status-bar .rd-deploy-widget > * {
  margin-right: 0.5rem;
}
.rd-status-bar .rd-deploy-widget .survey-progress {
  border: 2px solid #d7d7d7;
  font-weight: 600;
  min-width: 7.1rem;
  padding: 0 0.5rem;
  text-align: center;
}
.rd-status-bar .rd-deploy-widget .deploy-button {
  color: #3b7bdb;
  padding: 0 0.75rem 0.1rem 0.75rem;
}
.rd-status-bar .rd-deploy-widget .auto-manual-deploy-switch {
  width: 5.5rem;
}
.rd-status-bar .rd-deploy-widget .auto-manual-deploy-switch.ant-switch-checked {
  background-color: #3b7bdb;
}
.rd-status-bar .rd-deploy-widget .auto-deploy-percent-input {
  width: 2.5rem;
  padding: 0;
  text-align: center;
  height: 1.6rem;
}

