.add-remove-icons {
  display: flex;
}
.add-remove-icons > div {
  cursor: pointer;
}
.add-remove-icons > div[disabled] {
  color: gray;
  cursor: not-allowed;
}
.add-remove-icons > .add {
  color: #4fc328;
  margin-right: 1rem;
}
.add-remove-icons > .remove {
  color: #bf2828;
}

