.screener-summary {
  min-height: stretch;
}
.screener-summary .panel-stats-table {
  width: 100%;
}
.screener-summary .panel-stats-table th,
.screener-summary .panel-stats-table td {
  padding-right: 0.5rem;
  text-align: right;
  width: 3.75rem;
}
.screener-summary .panel-stats-table th:first-child,
.screener-summary .panel-stats-table td:first-child {
  text-align: left;
  width: auto;
}
.screener-summary .panel-stats-table .totals-row td {
  font-weight: bolder;
}
.screener-summary .completed-total {
  border: 1px solid #aaaaaa;
  background-color: #f5f5f5;
}
.screener-summary .align-baseline {
  align-items: baseline;
}
.screener-summary .bolder {
  font-weight: bolder;
}
.screener-summary .space-between {
  justify-content: space-between;
}
.screener-summary .subtotal {
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  height: stretch;
}
.screener-summary .subtotal .row-content {
  padding: 0 0.5rem;
  border-bottom: 1px solid #c0c0c0;
}
.screener-summary .subtotal .row-content label {
  margin-bottom: 0;
}
.screener-summary .subtotal .row-content .counts {
  width: 3rem;
  text-align: end;
}

