.completed-vfg {
  display: flex;
}
.completed-vfg .recording-and-bookmarks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: 2rem;
  min-width: fit-content;
}
.completed-vfg .recording-and-bookmarks .recording {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.completed-vfg .recording-and-bookmarks .recording .video {
  width: 640px;
  height: 360px;
  margin-left: 1rem;
}
.completed-vfg .recording-and-bookmarks .bookmarks-section {
  height: 40%;
}
.completed-vfg .recording-and-bookmarks .bookmarks-section .bookmarks {
  width: inherit;
  overflow-y: auto;
}
.completed-vfg .recording-and-bookmarks .bookmarks-section .bookmarks .bookmark {
  display: flex;
}
.completed-vfg .recording-and-bookmarks .bookmarks-section .bookmarks .bookmark .time {
  width: 5rem;
  color: var(--blue);
}
.completed-vfg .recording-and-bookmarks .bookmarks-section .bookmarks .bookmark .speaker {
  width: 8rem;
  color: var(--blue);
}
.completed-vfg .recording-and-bookmarks .bookmarks-section .bookmarks .bookmark .comment {
  width: 65%;
  margin-right: 0;
  margin-left: auto;
  padding-right: 2rem;
}
.completed-vfg .transcripts-section {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.completed-vfg .transcripts-section .search-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
}
.completed-vfg .transcripts-section .transcripts {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
.completed-vfg .transcripts-section .transcripts .transcript-detail {
  width: inherit;
  display: flex;
}
.completed-vfg .transcripts-section .transcripts .transcript-detail .speaker {
  width: 15%;
  cursor: pointer;
}
.completed-vfg .transcripts-section .transcripts .transcript-detail .speach {
  width: 70%;
  cursor: pointer;
}
.completed-vfg .transcripts-section .transcripts .transcript-detail .time {
  width: 10%;
  text-align: end;
}
.completed-vfg .transcripts-section .transcripts .transcript-detail .participant {
  color: var(--blue);
}
.completed-vfg .moderator {
  text-transform: capitalize;
}
.completed-vfg a .fa-download {
  color: black;
}

