.functions-list {
  width: 55rem;
}

.add-function-modal {
  max-width: 50rem !important;
}

.modal-dialog.functions-modal {
  max-width: min-content;
}
