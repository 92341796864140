@import '../../styles/styleConstants.scss';

.video-response-icon {
  cursor: pointer;
  margin: auto 0.5rem auto 0;
  &:hover {
    color: $linkColor;
  }
  &[disabled] {
    color: $gray-600;
    cursor: not-allowed;
  }
}

.participant-video {
  display: block;
  width: 100%;
  min-height: 350px;
}
