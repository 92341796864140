.modal-dialog.view-participant-modal {
  max-width: 55rem;
}
.modal-dialog.view-participant-modal label {
  font-weight: 600;
}
.modal-dialog.view-participant-modal .search {
  width: 12rem;
}

