.modal-dialog.cluster-analysis-create-filter-modal {
  max-width: fit-content;
  min-width: 600px;
  .enrollees {
    display: flex;
    font-size: 85%;
    margin-top: 0.5rem;
    ul {
      li {
        white-space: nowrap;
      }
    }
  }
  .over-50-msg {
    font-size: 75%;
  }
}
