.choice-answers-table {
  display: flex;
  margin-bottom: 2rem;
}
.choice-answers-table table thead th {
  padding-right: 2rem;
}
.choice-answers-table table thead th .fa-caret-up,
.choice-answers-table table thead th .fa-caret-down {
  margin-left: 0.5rem;
}
.choice-answers-table table thead th .column-header {
  display: flex;
  align-items: center;
  width: 100%;
}
.choice-answers-table table thead th .column-header .name-and-sort-caret {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  width: 6rem;
}
.choice-answers-table table thead th .column-header .name-and-sort-caret .column-name {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.choice-answers-table table thead th .column-header .fa-window-close {
  cursor: pointer;
  margin-left: auto;
}
.choice-answers-table table tbody tr.selected {
  background-color: #d4e3f8;
}
.choice-answers-table table tbody tr.selected td:first-child {
  visibility: visible;
}
.choice-answers-table table tbody tr td {
  padding: 0.5rem 2rem 0.5rem 0;
}
.choice-answers-table table tbody tr td:first-child {
  background-color: var(--white);
  color: #296ca9;
  padding-right: 0;
  visibility: hidden;
}
.choice-answers-table table tbody tr td:nth-child(2) {
  line-height: 1;
  width: 20rem;
}
.choice-answers-table table tbody tr td:nth-child(2) ul {
  margin: 0;
  padding-left: 1.5rem;
}
.choice-answers-table table tbody tr td:nth-child(2) ul li {
  font-size: larger;
}
.choice-answers-table table tbody tr td:nth-child(2) ul li > span {
  color: initial;
  font-size: initial;
  position: relative;
  left: -5px;
}
.choice-answers-table table tbody tr td.response-rate-cell {
  min-width: 5rem;
}
.choice-answers-table table tbody tr td.response-rate-cell .response-rate-div {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  width: 100%;
}
.choice-answers-table table tbody tr td.response-rate-cell .response-rate-div .response-rate-bar {
  height: 1.5rem;
  margin-right: 0.5rem;
}
.choice-answers-table table tbody tr td.response-rate-cell .response-rate-div .response-rate {
  font-weight: 600;
  white-space: nowrap;
}
.choice-answers-table .compare-link {
  margin: 0 0 0 2rem;
  padding: 0;
  vertical-align: top;
}

.other-answers-table {
  margin: 0 0 2rem 3.25rem;
}

