.filter-selector-modal {
  .filter-group {
    margin-bottom: 1rem;
    .group-name {
      font-weight: 600;
    }
    .group-item {
      padding: 0.25rem 0.5rem;
      label {
        margin: 0;
      }
    }
  }
}
