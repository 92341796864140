.filter-summaries {
  display: flex;
  align-items: center;
  margin: 0.5rem 0.5rem 0 0;
}
.filter-summaries .filter-summary-box {
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 0.3rem;
  width: 7rem;
  margin: 0 0.5rem 0.5rem 0;
  position: relative;
}
.filter-summaries .filter-summary-box .quota-filter {
  color: #cccccc;
  font-size: 0.8rem;
  line-height: 1;
  position: absolute;
  top: 0.2rem;
  left: 0.1rem;
}
.filter-summaries .filter-summary-box .quota-filter.full {
  color: #ff6600;
}
.filter-summaries .filter-summary-box .remove-filter {
  color: #cccccc;
  cursor: pointer;
  line-height: 1;
  position: absolute;
  right: 0.25rem;
}
.filter-summaries .filter-summary-box .filter-count {
  font-weight: 600;
  text-align: center;
}
.filter-summaries .filter-summary-box .filter-stats {
  text-align: center;
}
.filter-summaries .filter-summary-box .filter-name {
  background-color: #f5f5f5;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter-summaries .filter-select {
  color: #949494;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
}
.filter-summaries .filter-select .add-filter-text {
  font-size: 0.75rem;
}
.filter-summaries .filter-select .filter-select-list {
  background-color: #eeeeee;
  border: 1px solid #d7d7d7;
  border-radius: 0.3rem;
  color: #000;
  padding: 0.25rem;
  position: absolute;
  left: 0.7rem;
  top: 1rem;
  width: 7rem;
  z-index: 9999;
}
.filter-summaries .filter-select .filter-select-list > div {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

