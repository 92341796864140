.modal-dialog.survey-question-picker-modal {
  min-width: 67rem;
  input {
    cursor: pointer;
  }
  table {
    // header & cell styles
    tr {
      :nth-child(3) {
        // title
        width: 10rem;
        max-width: 10rem;
      }
      th:last-child {
        // media column header
        padding: 0 0.5rem;
        text-align: center;
      }
    }

    // cell-only styles
    tr td {
      vertical-align: top;
      &:nth-child(2) {
        // qNum
        font-weight: 600;
        padding-right: 0.25rem;
      }
      &:nth-child(4) {
        // question text
        padding-right: 0.5rem;
      }
      &:nth-child(5) {
        // type
        padding-right: 0.5rem;
        white-space: nowrap;
      }
      &:last-child {
        text-align: center;
        img {
          // media
          max-width: 5rem;
          max-height: 5rem;
        }
      }
    }
  }
}
