.dial-config {
  margin-left: 3rem;
  .row {
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  > .row {
    > :first-child {
      width: 4rem;
    }
    > :nth-child(2),
    > :nth-child(4) {
      width: 6rem;
    }
    > :nth-child(3) {
      margin: 0 1rem;
      width: 10rem;
    }
  }
  .action-button-controls {
    .row {
      align-items: unset;
      .action-button-field {
        height: fit-content;
        width: 14rem;
        margin: 0 1rem 0 4rem;
      }
      .action-button-options {
        margin: 0 1rem 0 0;
        .show-question-control {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          white-space: nowrap;
          > label {
            margin: 0 0.5rem 0 0;
          }
          select {
            min-width: 8rem;
          }
        }
      }
    }
  }
}
