.rd-session-mgmt {
  display: flex;
  .question-list {
    &.collapsed-view {
      max-width: 15%;
      min-width: 15%;
    }
  }
  .rd-question-details {
    border: 1px solid black;
    padding: 0.3rem;
    min-width: 85%;
    width: 100%;
    .show-new-responses {
      background-color: #e5f9ee;
      font-size: 0.9rem;
      font-weight: 500;
      line-height: 1.7rem;
      position: fixed;
      bottom: 0;
      left: 0;
      text-align: center;
      white-space: nowrap;
      width: 100%;
    }
  }

  .ql-enter,
  .ql-appear {
    transform: rotateY(90deg);
    transform-origin: left;
    transform-style: preserve-3D;
  }

  .ql-enter-active,
  .ql-appear-active {
    transform: rotateY(0deg);
    transform-origin: left;
    transform-style: preserve-3D;
    transition: transform 500ms;
  }

  .ql-exit {
    transform: rotateY(0deg);
    transform-origin: left;
    transform-style: preserve-3D;
  }

  .ql-exit-active {
    transform: rotateY(90deg);
    transform-origin: left;
    transform-style: preserve-3D;
    transition: transform 500ms;
  }

  .ql-expand-enter {
    transform: rotateY(90deg);
    transform-origin: left;
    transform-style: preserve-3D;
  }

  .ql-expand-enter-active {
    transform: rotateY(0deg);
    transform-origin: left;
    transform-style: preserve-3D;
    transition: transform 500ms;
  }

  .qd-horizontal {
    animation: fadeIn ease 0.5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
