.modal-dialog.rd-edit-filters-modal {
  max-width: 900px;
}
.modal-dialog.rd-edit-filters-modal .filters-container {
  border: 1px solid #d7d7d7;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .title-bar {
  background-color: #eeeeee;
  border-bottom: inherit;
  border-radius: inherit;
  font-weight: 600;
  padding-left: 0.5rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container {
  padding: 0.5rem 1rem 1rem 1rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls {
  width: 100%;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .label-cell {
  width: 12rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .quota-cell {
  width: 4rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .quota-cell input {
  padding-right: 0;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .filter-cell {
  width: 34rem;
  max-width: 34rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .filter-cell .filter-bar .filter-label {
  max-width: 80%;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .filter-cell .filter-bar .add-filter-icon {
  display: flex;
  align-items: center;
  width: 100%;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .filter-cell .filter-bar .add-filter-icon .fa-filter {
  cursor: pointer;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .filter-cell .filter-bar .filter-bar-placeholder {
  color: #949494;
  font-size: 0.9rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .filter-cell .filter-bar .link-button {
  margin: 0 0 0 auto;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .quota-filter-cell {
  width: 30rem;
  max-width: 30rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .quota-filter-cell .filter-bar .filter-label {
  max-width: 76%;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .convert-filter-cell {
  color: #949494;
  text-align: center;
  width: 1.5rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .convert-filter-cell .fa-level-down-alt,
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .convert-filter-cell .fa-level-up-alt {
  cursor: pointer;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr .add-remove-cell {
  width: 3rem;
}
.modal-dialog.rd-edit-filters-modal .filters-container .filters-form-container .filters-form-controls tr td {
  padding: 0.5rem 0.5rem 0 0;
}

