.custom-report-config {
  .forms {
    background-color: var(--gray-100);
  }
  .full-width {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .title {
    font-size: 1rem;
  }
  .question-options {
    font-size: 0.75rem;
  }
  .column3 {
    width: 24%;
    overflow: auto;
    height: 100%;
  }
  .content-column-height {
    overflow-y: auto;
    max-height: 45vh;
  }
  .label-text {
    font-weight: 600;
  }
  .label-text-width {
    width: 75%;
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  .bordered-list {
    border: 1px solid var(--gray-400);
    border-radius: 5px;
  }
}
.modal-dialog.custom-report-config {
  max-width: 800px !important;
  margin: 1.75rem auto;
  .included-title-text {
    position: fixed;
    left: 52%;
  }
}
