.options-display {
  font-size: 1rem;
  color: var(--blue);
}
.options-display .selected-option {
  min-width: 2rem;
  border: 1px solid var(--blue);
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.options-display .display-option {
  min-width: 2rem;
  display: inline-block;
  margin-right: 0.5rem;
  border: 1px solid transparent;
  text-align: center;
}
.options-display .selected-option,
.options-display .display-option:hover {
  cursor: pointer;
}

