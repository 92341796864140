.ranked-edit .rows-value-selector {
  width: 90%;
  font-size: 80%;
  font-weight: 500;
}
.ranked-edit .rows-value-selector .ant-select-selection__rendered {
  margin-left: 0.25rem;
}
.ranked-edit .choice-question-selector {
  min-width: 60%;
}
.ranked-edit .rows-area {
  min-height: 6rem;
}
.ranked-edit .ant-select-dropdown-menu-item {
  font-size: 80%;
  margin-left: 0.125rem;
}
.ranked-edit .choices {
  display: flex;
}
.ranked-edit .choices .editor-right-column .column-title {
  font-size: 75%;
}
.ranked-edit .choices .editor-right-column > div {
  display: flex;
  align-items: center;
}
.ranked-edit .choices .editor-right-column > div > .first {
  margin-right: 0.25rem;
  width: 66%;
}
.ranked-edit .choices .editor-right-column > div > .second {
  margin-right: 0.25rem;
  text-align: center;
  width: auto;
}
.ranked-edit .choices .editor-right-column > div > .second .image,
.ranked-edit .choices .editor-right-column > div > .second img {
  height: 2rem;
  width: 2.5rem;
}
.ranked-edit .choices .editor-right-column > div > .second img:hover {
  cursor: pointer;
}
.ranked-edit .choices .editor-right-column > div > .third {
  margin-right: 0.5rem;
  width: 7rem;
}

.ranked-options label {
  display: block;
  margin-bottom: 0.5rem;
}
.ranked-options .min-choices {
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.ranked-options .min-choices select {
  margin: 0 0.5rem;
  width: 5rem;
}

