.raw-data-report {
  .config-area {
    .config-column {
      .not-answered-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 0.25rem 1rem;
        input {
          height: 1.75rem;
          padding: 0 0.25rem;
          width: 4rem;
        }
      }
    }
  }
}
