.custom-login-time > div {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.custom-login-time > div > * {
  white-space: nowrap;
}
.custom-login-time > div > :first-child {
  width: 4.5rem;
}
.custom-login-time > div > :nth-child(2) {
  margin-right: 0.3rem;
}
.custom-login-time > div > :nth-child(3) {
  width: 11.5rem;
}
.custom-login-time > div > :nth-child(4) {
  margin-right: 0.3rem;
}
.custom-login-time > div > :nth-child(5) {
  margin-right: 0.5rem;
  width: 3rem;
}
.custom-login-time > div input {
  margin: 0;
  position: initial;
}

