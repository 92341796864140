.dialsmith-stim-edit {
  .link-params {
    display: flex;
    .link-param-row {
      display: flex;
      margin-bottom: 0.5rem;
      > :first-child,
      > :nth-child(2) {
        margin-right: 1rem;
        width: 12rem;
      }
    }
  }
}
