.modal-dialog.custom-nets-modal {
  display: table;
}
.modal-dialog.custom-nets-modal .modal-content {
  max-width: 98vw;
}
.modal-dialog.custom-nets-modal .modal-content .modal-body {
  margin-right: 1rem;
  overflow-x: auto;
}
.modal-dialog.custom-nets-modal table {
  width: auto;
}
.modal-dialog.custom-nets-modal table thead tr th:first-child {
  background-color: #fff;
}
.modal-dialog.custom-nets-modal table thead tr th {
  background-color: #dddddd;
  min-width: 7rem;
  max-width: 7rem;
  width: 7rem;
  padding: 0 0.25rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-dialog.custom-nets-modal table tbody td:first-child {
  border: none !important;
  padding-right: 1rem;
  text-align: left;
}
.modal-dialog.custom-nets-modal table tbody td {
  border: 1px solid #c0c0c0 !important;
  height: 3rem;
  text-align: center;
  vertical-align: middle;
}
.modal-dialog.custom-nets-modal table tbody td input {
  margin: auto;
  position: initial;
}

