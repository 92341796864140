.modal-dialog.view-participant-modal {
  max-width: 55rem;

  label {
    font-weight: 600;
  }

  .search {
    width: 12rem;
  }
}
