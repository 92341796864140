.heading {
  font-weight: 600;
  font-size: 1.1rem;
}

.title {
  font-weight: 600;
  font-size: 1.2rem;
}

