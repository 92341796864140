.modal-dialog.joiner-editor-modal {
  max-width: 58rem;
}
.modal-dialog.joiner-editor-modal .modal-content {
  border: 1px solid #212529;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body {
  display: flex;
  padding: 0;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor-tabs {
  background-color: #dddddd;
  border: 1px solid #949494;
  margin: 0;
  min-height: 23rem;
  list-style-type: none;
  padding: 0;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor-tabs li {
  border-top: 1px solid #949494;
  border-bottom: 1px solid #949494;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.2rem 0.5rem;
  text-align: center;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor-tabs li.selected {
  background-color: #f2f2f2;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor-tabs li .fa,
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor-tabs li .fas,
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor-tabs li .far {
  color: #545454;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor-tabs li > div:nth-child(2) {
  font-size: 0.75rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  margin: 0.5rem 0.5rem 0 0.5rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-header > div:first-child {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  height: 2.5rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-header > div:first-child .joiner-icon {
  max-height: 100%;
  margin-right: 0.5rem;
  text-align: center;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-header > div:first-child .joiner-icon.fa-at {
  color: #aaaaaa;
  font-size: 120%;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-header > div:nth-child(2) > * {
  margin-left: 1rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form {
  position: relative;
  border: 1px solid #d7d7d7;
  border-radius: 0.3rem;
  height: 100%;
  margin: 0.5rem;
  padding: 0.5rem;
  width: 98%;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .editor-left-column {
  width: 9rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .editor-right-column {
  width: 100%;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .editor-right-column-flush {
  margin-left: 8rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .editor-right-column-video-radio1 {
  margin-left: 2rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .editor-right-column-video-radio2 {
  margin-left: 4.88rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .editor-right-column-web-radio1 {
  margin-left: 1rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .editor-right-column-web-radio2 {
  margin-left: 7.5rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .label {
  font-weight: 600;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .import-and-language {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .stim-text-translation {
  float: right;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields {
  white-space: nowrap;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields > div {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields > div > .editor-right-column {
  display: flex;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields .editor-right-column {
  align-items: start;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields .editor-right-column .ql-formats {
  margin-right: 0.75rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields .editor-right-column .ql-formats button {
  background-color: initial !important;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields .question-title-field {
  margin-right: 4rem;
  width: 100%;
  max-width: 16rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields .select-concept {
  width: 100%;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields .question-group-field {
  margin-left: 1rem;
  width: 100%;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields .editor-quill-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 0;
  background-color: #fff;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields .ql-toolbar {
  display: flex;
  flex-wrap: wrap;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .joiner-editor-common-fields .question-text-field {
  min-height: 10rem;
  max-height: 30rem;
  overflow-y: auto;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .dialsmith-stim-edit .editor-left-column,
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .matrix-edit .editor-left-column,
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .ranked-edit .editor-left-column,
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-form .concept-rotation-media .editor-left-column {
  width: 13rem;
}
.modal-dialog.joiner-editor-modal .modal-content .modal-body .joiner-editor .joiner-editor-buttons {
  padding: 0.5rem;
  text-align: right;
}

