.flags-dropdown {
  margin-left: -0.75rem;
  background-color: var(--white);
  z-index: 2;
  width: fit-content;

  .fa-flag,
  .fa-ban {
    font-size: 1.5rem;
    margin-right: 1.5rem;
  }
  .fa-flag,
  .fa-ban {
    padding: 0.125rem 0.5rem;
    margin-right: 0;
    cursor: pointer;
  }
  .fa-ban {
    color: #8b0000;
  }
  .fa-flag {
    display: block;
  }
  .colored-flags {
    visibility: hidden;
  }
}

.all-flags {
  border: var(--border);
  .colored-flags {
    visibility: inherit;
    background-color: var(--white);
    display: flex;
    flex-flow: column;
    .fa-flag:hover,
    .fa-ban:hover {
      background-color: #c3daea;
    }
  }
}

.flags-dropdown.selected {
  position: absolute;
}

.flags-dropdown.unselected {
  background-color: inherit;
}
