@import '../../../../../styles/styleConstants.scss';

$mediaPreviewHeight: 120px;
$mediaPreviewWidth: 240px;

.concept-rotation-media {
  label {
    display: block;
  }
  .row {
    flex-wrap: nowrap;
    align-items: center;
  }
  .add-input {
    color: #4fc328;
    padding: 0 1rem;
  }
  .remove-input {
    color: #bf2828;
    padding: 0 1rem;
  }
  .text-only-media {
    max-width: 75%;
    padding: 0.25rem 0.5rem;
    border: $border;
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .row .editor-right-column .add-input,
  .editor-right-column .row .remove-input,
  .editor-right-column .row .text-only-media:hover {
    cursor: pointer;
  }
  .editor-right-column .flex-row .small-preview {
    border: $border;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: $white;
    img,
    video {
      max-height: 2.5rem;
    }
  }
}
