@import '../../../styles/styleConstants.scss';

.filter-summaries {
  display: flex;
  align-items: center;
  margin: 0.5rem 0.5rem 0 0;
  .filter-summary-box {
    background-color: $white;
    border: $border;
    border-radius: 0.3rem;
    width: 7rem;
    margin: 0 0.5rem 0.5rem 0;
    position: relative;
    .quota-filter {
      color: $gray-400;
      font-size: 0.8rem;
      line-height: 1;
      position: absolute;
      top: 0.2rem;
      left: 0.1rem;
      &.full {
        color: #ff6600;
      }
    }
    .remove-filter {
      color: $gray-400;
      cursor: pointer;
      line-height: 1;
      position: absolute;
      right: 0.25rem;
    }
    .filter-count {
      font-weight: 600;
      text-align: center;
    }
    .filter-stats {
      text-align: center;
    }
    .filter-name {
      background-color: $gray-100;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      font-size: 0.9rem;
      padding: 0.2rem 0.5rem;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .filter-select {
    color: $gray-700;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.5rem;
    position: relative;
    .add-filter-text {
      font-size: 0.75rem;
    }
    .filter-select-list {
      background-color: $gray-200;
      border: $border;
      border-radius: 0.3rem;
      color: $black;
      padding: 0.25rem;
      position: absolute;
      left: 0.7rem;
      top: 1rem;
      width: 7rem;
      z-index: 9999;
      > div {
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
