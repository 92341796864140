.modal-dialog.panel-config-modal {
  max-width: 60rem;
}
.edit-panel-config {
  > div {
    display: flex;
    align-items: baseline;
    margin-bottom: 0.5rem;
  }
  .link-param-ui {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    > * {
      font-size: 90%;
      margin-right: 0.5rem;
    }
    input {
      width: 6rem;
    }
    select {
      width: auto;
    }
    .add-remove-icons {
      font-size: initial;
    }
  }
  .url-input-row {
    > label {
      width: 10rem;
    }
    > :nth-child(2) {
      width: 35rem;
    }
    .url-example {
      color: var(--gray-800);
      font-size: 90%;
      margin: 0.5rem;
    }
  }
  .add-link-param-link {
    color: var(--link-color);
    cursor: pointer;
    margin-left: 1rem;
    white-space: nowrap;
  }
}
