.modal-dialog.add-or-edit-concept-modal {
  max-width: 50rem;
  .modal-body {
    height: 21rem;
    > div {
      display: flex;
      margin-bottom: 0.5rem;
      > :first-child {
        font-weight: 600;
        margin-right: 1rem;
        width: 9rem;
      }
    }
    .stim-type-selector {
      width: inherit;
      font-weight: 600;
    }
    .caption-input {
      width: 38rem;
    }
    .text-stim-quill {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background-color: var(--white);
      .ql-toolbar {
        border-radius: 4px 4px 0 0;
      }
    }
    .quill-text-field {
      height: 8rem;
      overflow-y: auto;
      border-radius: 0 0 4px 4px;
    }
  }
}
