.rd-session-mgmt .question-list tbody tr.selected {
  border: 2px solid #09549a;
}
.rd-session-mgmt .question-list td {
  color: var(--gray-800);
  font-size: 0.9rem;
}
.rd-session-mgmt .question-list td.deploy-status-cell {
  height: 1px;
  padding: 0;
}
.rd-session-mgmt .question-list td.deploy-status-cell > div {
  background-color: var(--gray-300);
  height: 100%;
}
.rd-session-mgmt .question-list td.deploy-status-cell .child-joiner {
  width: 50%;
}
.rd-session-mgmt .question-list td.deploy-status-cell .deployed {
  background-color: #095499;
}
.rd-session-mgmt .question-list td.deploy-status-cell .current {
  background-color: #6fd016;
}
.rd-session-mgmt .question-list td.question-number-cell {
  color: black;
  font-weight: 600;
}
.rd-session-mgmt .question-list td.num-answers-cell {
  padding-right: 0.5rem;
  text-align: right;
}
.rd-session-mgmt .question-list td.percent-cell {
  text-align: right;
}
.rd-session-mgmt .question-list td.media-thumbnail-cell {
  padding-left: 0;
  text-align: center;
  vertical-align: middle;
}
.rd-session-mgmt .question-list td.media-thumbnail-cell .media-thumbnail {
  max-height: 1.33rem;
  max-width: 6.67rem;
  vertical-align: middle;
}
.rd-session-mgmt .question-list td .hidden-label {
  margin-right: 0.25rem;
  font-weight: 600;
  color: #ff8e00;
}

