.modal-dialog.select-survey-modal {
  max-width: 50rem;
}
.modal-dialog.select-survey-modal .survey-name-input {
  display: inline-block;
  margin-left: 1rem;
  width: 20rem;
}
.modal-dialog.select-survey-modal .survey-table-container {
  margin: 1rem 0 0 1rem;
}

