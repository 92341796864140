.target-criteria-modal.modal-dialog {
  max-width: 40%;
}
.target-criteria-modal.modal-dialog .rule-name, .target-criteria-modal.modal-dialog .rule-quota {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.target-criteria-modal.modal-dialog .rule-name strong, .target-criteria-modal.modal-dialog .rule-quota strong {
  width: 15%;
}
.target-criteria-modal.modal-dialog .rule-name .text-input, .target-criteria-modal.modal-dialog .rule-quota .text-input {
  width: 50%;
}
.target-criteria-modal.modal-dialog .rule-criteria {
  border: 1px solid #ccc;
  padding: 0.5rem;
  margin-top: 0.5rem;
}
.target-criteria-modal.modal-dialog .rule-criteria .fa-trash-alt {
  margin: 0 1rem;
  color: #ccc;
}
.target-criteria-modal.modal-dialog .rule-criteria .ant-select {
  margin-right: 3rem;
  width: -webkit-fill-available;
}
.target-criteria-modal.modal-dialog .rule-criteria .ant-select-selector {
  border-radius: 4px;
}
.target-criteria-modal.modal-dialog .rule-criteria .from-to {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.target-criteria-modal.modal-dialog .rule-criteria .from-to strong {
  width: 15%;
}
.target-criteria-modal.modal-dialog .rule-criteria .single-option {
  width: auto;
}
.target-criteria-modal.modal-dialog .rule-criteria .radio-option {
  display: block;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.target-criteria-modal.modal-dialog .number-input {
  width: 10%;
  min-width: 4rem;
  padding-left: 0.5rem;
  padding-right: 5px;
}

