@import '../../../../../styles/styleConstants.scss';

.vote-summary {
  background-color: $white;
  border: $border;
  font-size: 0.7rem;
  > div {
    display: flex;
  }
  > div:first-child {
    font-variant-numeric: tabular-nums;
    justify-content: space-between;
    padding: 0 0.3rem;
    > div:first-child {
      margin-right: 1rem;
    }
  }
  > div:nth-child(2) {
    background-color: $gray-200;
    border-top: $border;
    height: 0.5rem;
    > div:first-child {
      background-color: #71a6cb;
    }
    > div:nth-child(3) {
      background-color: #d9d382;
    }
  }
}
.vote-summary-tooltip {
  .tooltip-inner {
    background-color: $gray-100;
    border: 1px solid $gray-600;
    color: var(--black);
    font-size: 0.75rem;
    max-width: none;
    text-align: left;
    text-transform: lowercase;
    .summary-tooltip {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .box {
      height: 0.5rem;
      width: 1rem;
      border: 1px solid $gray-600;
      display: inline-block;
      margin: 0 0.5rem;
    }
  }
}
