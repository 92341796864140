.custom-ui-form {
  font-size: 90%;
  img {
    height: 2.5rem;
    width: auto;
  }
  input {
    max-width: 75%;
  }
  .row {
    align-items: center;
  }
  .logo-controls {
    padding-left: 0;
  }
  .logoImgPreview {
    max-height: 3rem;
    margin: 0.5rem 0;
    .app-logo {
      margin-left: 0;
    }
  }
  .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.5rem;
    margin: 0.5rem 0;
  }
  .square {
    border: 1px solid black;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
  }
  .update-buttons {
    display: flex;
    justify-content: space-between;
    width: 33%;
    margin-top: 1.5rem;
    button {
      margin-left: 0;
    }
  }
}

.modal-dialog.preview-custom-ui-modal {
  max-width: 750px;
  .preview-custom-ui {
    .container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .center {
      justify-content: center;
    }
  }
}
