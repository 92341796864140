.media-preview {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  height: 10rem;
  width: 10rem;
  .fa-image {
    color: var(--gray-400);
  }
  img,
  video {
    max-width: 10rem;
    max-height: 10rem;
  }
  iframe {
    max-width: 20rem;
    border: var(--border);
    width: 200%;
    transform: scale(0.5);
  }
}
