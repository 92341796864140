.rd-participant-details .top-row {
  display: flex;
  margin-bottom: 2rem;
}
.rd-participant-details .top-row > div {
  margin-right: 7rem;
}
.rd-participant-details .top-row .participant-controls {
  display: flex;
  align-items: center;
}
.rd-participant-details .top-row .participant-controls .chat-icon,
.rd-participant-details .top-row .participant-controls .favorite-icon,
.rd-participant-details .top-row .participant-controls .flag-icon {
  font-size: 1.5rem;
  margin-right: 1.5rem;
}
.rd-participant-details .top-row .participant-controls .flags-dropdown.all-flags.selected {
  margin-top: -1rem;
}

.chat-icon.disabled,
.favorite-icon.disabled,
.flag-icon.disabled {
  cursor: not-allowed !important;
  color: var(--gray-400) !important;
  pointer-events: all !important;
}

