.org-admin .client-settings .max-password-age-input {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 3rem;
}
.org-admin .client-settings .archive-input {
  display: inline-block;
  padding: 0;
  border-radius: 4px;
  height: 2rem;
  text-align: center;
  width: 4rem;
}
.org-admin .client-settings .archive-select {
  display: inline-block;
  text-align: center;
  width: 14rem;
  height: 2rem;
}
.org-admin .client-roles {
  height: 100%;
}
.org-admin .client-users {
  height: 100%;
}
.org-admin .client-users .active-users-checkbox {
  margin-bottom: 0.2rem;
}
.org-admin .client-users .active-users-checkbox input {
  margin-right: 0.5rem;
}

.logoImgPreview {
  height: 140px;
  max-width: 320px;
  padding: 10px;
}
.logoImgPreview img {
  max-height: 120px;
  max-width: 300px;
}

