.active-projects {
  .search-input {
    display: inline-block;
  }
  .selected {
    border: 1px solid var(--blue);
    padding: 0.5rem;
  }
  .unselected {
    border: 1px solid transparent;
    padding: 0.5rem;
  }
  .project-card {
    border: var(--border);
    padding: 0.5rem;
    background: var(--white);
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    width: 300px;
    height: 200px;
    .users-media {
      display: flex;
      .media-thumbnail {
        max-width: 120px;
        max-height: 80px;
      }
    }
  }
  .project-card.active {
    border: 1px solid var(--blue);
    box-shadow: 0 3px 5px;
  }
}
