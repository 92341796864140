@import '../../../styles/styleConstants.scss';

.modal-dialog.rd-edit-filters-modal {
  max-width: 900px;
  .filters-container {
    border: $border;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    .title-bar {
      background-color: $gray-200;
      border-bottom: inherit;
      border-radius: inherit;
      font-weight: 600;
      padding-left: 0.5rem;
    }
    .filters-form-container {
      padding: 0.5rem 1rem 1rem 1rem;
      .filters-form-controls {
        width: 100%;
        tr {
          .label-cell {
            width: 12rem;
          }
          .quota-cell {
            width: 4rem;
            input {
              padding-right: 0;
            }
          }
          .filter-cell {
            width: 34rem;
            max-width: 34rem;
            .filter-bar {
              .filter-label {
                max-width: 80%;
              }
              .add-filter-icon {
                display: flex;
                align-items: center;
                width: 100%;
                .fa-filter {
                  cursor: pointer;
                }
              }
              .filter-bar-placeholder {
                color: $gray-700;
                font-size: 0.9rem;
              }
              .link-button {
                margin: 0 0 0 auto;
              }
            }
          }
          .quota-filter-cell {
            width: 30rem;
            max-width: 30rem;
            .filter-bar {
              .filter-label {
                max-width: 76%;
              }
            }
          }
          .convert-filter-cell {
            color: $gray-700;
            text-align: center;
            width: 1.5rem;
            .fa-level-down-alt,
            .fa-level-up-alt {
              cursor: pointer;
            }
          }
          .add-remove-cell {
            width: 3rem;
          }
          td {
            padding: 0.5rem 0.5rem 0 0;
          }
        }
      }
    }
  }
}
