.sentiment-summary-keywords-table {
  tr {
    > td:first-child {
      font-size: 0.9rem;
      padding-right: 0.5rem;
      text-align: right;
      white-space: nowrap;
    }
    > td:nth-child(2) {
      width: 100%;
    }
  }
}

.keyword-sentiments-bar {
  display: flex;
  height: 6px;
}
