.participant-list-container {
  white-space: nowrap;
  max-width: 100%;
  .invoke-table-container {
    .table {
      table-layout: auto;
    }
  }
  button {
    display: block;
    margin-top: 1rem;
  }
}
