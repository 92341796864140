@import '../../styles/styleConstants.scss';

.report-config-norms-modal {
  width: 700px !important;
  h6 {
    font-weight: bolder;
  }
  .top-section {
    padding: 0 1rem 0.5rem 0.5rem;
    border-bottom: 1px solid $gray-200;
  }
  .main-section {
    padding: 0.5rem 0.5rem 0 0.5rem;
    height: 400px;
    overflow-y: auto;
    .row-answer-data {
      display: flex;
      justify-content: space-between;
      margin: 0.25rem 0;
      padding-left: 0.5rem;
      input {
        height: 80%;
        width: 4rem;
      }
    }
  }
}
