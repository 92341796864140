.project-status-bar {
  background-color: var(--app-body-l2-bg-color);
  display: flex;
  line-height: 40px;
  max-height: 40px;
  .project-name {
    background-color: var(--app-body-l1-bg-color);
    border: 1px solid var(--gray-3);
    border-top: none;
    font-weight: 600;
    padding: 0 1rem 0 0.5rem;
    white-space: nowrap;
  }
  .session-status {
    background-color: var(--gray-200);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > div:first-child {
      background-color: var(--gray-300);
      font-weight: 600;
      padding: 0 3rem 0 1rem;
    }
    .session-timestamp {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      > div:first-child {
        color: var(--gray-800);
        font-size: 75%;
        margin-right: 1rem;
      }
      > div:last-child {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
