.allowed-devices {
  width: 12rem;
}
.allowed-devices > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

