.footer {
  background-color: var(--footerBackground);
  color: var(--footerTextColor);
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  height: var(--footer-height);
  width: 100%;
  padding: 0 1rem;
  > div:first-child {
    margin-right: 2rem;
  }
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}
