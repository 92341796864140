.keywords {
  height: 17rem;
  overflow-y: scroll;
}

// This is the same as sentiments-header in Sentiments.scss
.keywords-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .keywords-header-wordcloud {
    font-size: 0.7rem;
    margin-right: 0.3rem;
    white-space: nowrap;
  }
}
