@import '../../styles/styleConstants.scss';

$chatWindowWidth: 20rem;
$chatWindowPadding: 0.5rem;
$gradient: linear-gradient(to top, $gray-200, $white);
$closeWindowIconSize: 1.4rem;

.chat-window {
  border: 2px solid $gray-700;
  box-shadow: 0 0 4px $gray-400;
  letter-spacing: normal;
  position: fixed;
  bottom: 0;
  right: 0;
  width: $chatWindowWidth;
  z-index: 99999;

  .chat-header {
    background-color: $white;
    border-bottom: 1px solid $gray-300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem $chatWindowPadding;
    white-space: nowrap;

    span {
      align-items: inherit;
      display: inherit;
    }

    &.minimized {
      background: $gradient;
    }

    .window-title {
      font-weight: 600;
      margin-right: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .chat-window-command-icon {
      background-color: $gray-200;
      border-radius: 1px;
      color: $linkColor;
      cursor: pointer;
      height: $closeWindowIconSize;
      width: $closeWindowIconSize;
      margin-left: 0.5rem;
      padding: 5px 4px;

      span {
        height: 100%;
        width: 100%;
        &.minimize-chat-window-icon {
          border-bottom: 2px solid;
        }
        &.maximize-chat-window-icon {
          border-top: 2px solid;
        }
        &.close-chat-window-icon {
          font-size: 1.5rem;
          justify-content: flex-end;
          padding-bottom: 4px;
        }
      }
    }
  }

  .chat-question-container {
    background: $gradient;
    border-bottom: 1px solid $gray-300;
    height: 9rem;
    padding: 1.2rem;

    > div {
      display: flex;
    }

    .question {
      margin-bottom: 1rem;
      height: 67%;
      line-height: 1.1rem;
      overflow-y: scroll;
      > span:first-child {
        font-weight: 600;
      }
      > span:nth-child(2) {
        color: $gray-800;
        .fa {
          padding-right: 0.3rem;
        }
      }
      img {
        float: left;
        margin: 0 0.5rem 0.25rem 0;
        width: 2.66rem;
      }
    }

    .answer {
      > span:first-child {
        font-weight: 600;
      }
      > span:nth-child(2) {
        color: $gray-900;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        select {
          border: none;
          max-width: $chatWindowWidth - 4;
          padding: 0;
        }
      }
    }

    .question span,
    .answer span {
      padding-right: 0.3rem;
    }
  }

  .chat-area {
    background: $gradient;
    padding: $chatWindowPadding;

    .messages {
      background-color: $white;
      border: 1px solid $gray-400;
      border-radius: 2px;
      height: 20rem;
      margin-bottom: $chatWindowPadding;
      overflow-y: scroll;

      .text-bubble {
        border-radius: 5px;
        box-shadow: 1px 1px $gray-700;
        min-height: 1rem;
        min-width: 10rem;
        max-width: 18.7rem;
        margin: 7px 10px;
        padding: 5px;
        &.right {
          background-color: #d0edf7;
          clear: both;
          float: right;
        }
        &.left {
          background-color: #fcfad0;
          clear: both;
          float: left;
        }
      }
    }

    textarea {
      border: 1px solid $gray-400;
      border-radius: 2px;
      color: $gray-800;
      height: 5rem;
      margin-bottom: 0.4rem;
      width: 100%;
    }

    .send-button-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .end-chat-link {
    color: $linkColor;
    cursor: pointer;
  }
}
