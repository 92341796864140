.modal-dialog.joiner-preview-modal {
  max-width: 90%;
  height: 90%;
}
.modal-dialog.joiner-preview-modal .modal-content {
  height: 100%;
}
.modal-dialog.joiner-preview-modal .modal-content .embed-responsive {
  width: 100%;
  height: 100%;
}
.modal-dialog.joiner-preview-modal .modal-content .embed-responsive iframe {
  width: inherit;
  height: inherit;
}

