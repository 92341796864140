@import '../../../styles/styleConstants.scss';

.rd-position-filters {
  height: 2rem;
  margin: 0 0.5rem;
  .toggle-selector {
    display: flex;
    position: relative;
    height: 2rem;
    font-size: 0.9rem;
    border: $border;
    background-color: $white;
    input {
      border: none;
      padding-left: 0.5rem;
      width: 90%;
    }
    .selector-button {
      position: absolute;
      right: 0;
      display: inline-flex;
      flex-flow: column;
      padding: 0.1rem 0.5rem 0 0.3rem;
      min-width: 1rem;
      align-self: center;
    }
  }
  .selector {
    z-index: 20;
    position: sticky;
    border: $border;
    color: $gray-700;
    background: $white;
    padding: 0.01rem 0;
    list-style: none;
    font-size: 0.9rem;
    .position-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.5rem;
      .for-checkbox {
        position: relative;
        margin: 0.3rem 0.5rem 0 0;
        padding: 0;
        bottom: 2px;
        border: $border;
        box-sizing: border-box;
        line-height: inherit;
        font-weight: 600;
      }
    }
    .position-option:hover {
      background-color: $rowHoverColor;
    }
  }
}
