.modal-dialog.cluster-analysis-create-filter-modal {
  max-width: fit-content;
  min-width: 600px;
}
.modal-dialog.cluster-analysis-create-filter-modal .enrollees {
  display: flex;
  font-size: 85%;
  margin-top: 0.5rem;
}
.modal-dialog.cluster-analysis-create-filter-modal .enrollees ul li {
  white-space: nowrap;
}
.modal-dialog.cluster-analysis-create-filter-modal .over-50-msg {
  font-size: 75%;
}

