@import '../../styles/styleConstants.scss';

.report-add-question-modal {
  .modal-body {
    .label {
      font-weight: 600;
    }
    .ant-select {
      width: 100%;
    }
    .flex-row {
      margin: 0 0 0.5rem 0;
      white-space: nowrap;
      > :first-child {
        min-width: 8rem;
      }
    }
    .top-row {
      > :nth-child(3) {
        margin: 0 0 0 1rem;
      }
    }
    .norm-row {
      input {
        width: 4.5rem;
      }
    }
    .session-override-container {
      border: $border;
      border-radius: 5px;
      max-height: 16rem;
      margin-bottom: 0.5rem;
      overflow-y: auto;
      padding: 1rem;
      > div:first-child {
        margin-bottom: 0.5rem;
      }
      .row {
        margin-left: 1.2rem;
      }
    }
  }
}
