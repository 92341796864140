.self-serve-recruit {
  padding-left: 2rem !important;
  .target-audience-section {
    margin: 4rem 0;
    .target-rule {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      .target {
        background-color: #c3daea;
        margin-right: 1rem;
        .edit-target {
          border: none;
          background-color: #c3daea;
          width: 10rem;
          line-height: 2;
        }
        .fa-pencil {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }
      .edit-number {
        margin-right: 1rem;
        width: 4rem;
      }
      .edit-number.form-control {
          padding-right: 0.1rem;
        }
    }
  }
  .additional-participant-section {
    .additional-data {
      .flex-row {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        .form-control {
          margin-bottom: 0;
        }
        .label {
          width: 10%;
          min-width: 8rem;
        }
        .question {
          width: 30%;
          min-width: 24rem;
        }
        .add-remove {
          width: 2rem;
        }
      }
    }
  }
  .save-button {
    margin: 2rem 0 1rem 0;
  }
}