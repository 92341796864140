.matrix-options label {
  display: block;
  margin-bottom: 0.5rem;
}
.matrix-options .matrix-types {
  margin-left: 0.5rem;
}
.matrix-options .range-labels .option-label {
  font-weight: 600;
}
.matrix-options .range-labels label {
  margin-bottom: 0.25rem;
}

.matrix-edit {
  white-space: nowrap;
}
.matrix-edit .rows-value-selector {
  width: 90%;
  font-size: 80%;
  font-weight: 500;
}
.matrix-edit .rows-value-selector .ant-select-selection__rendered {
  margin-left: 0.25rem;
}
.matrix-edit .choice-question-selector {
  min-width: 60%;
}
.matrix-edit .rows-area {
  min-height: 6rem;
}
.matrix-edit .ant-select-dropdown-menu-item {
  font-size: 80%;
  margin-left: 0.125rem;
}
.matrix-edit .mb-2 {
  display: flex;
  flex-wrap: nowrap;
}
.matrix-edit .mb-2 .editor-right-column {
  display: flex;
  align-items: center;
}
.matrix-edit .mb-2 .labels {
  width: 54%;
}
.matrix-edit .mb-2 .labels .label-input {
  width: 100%;
}
.matrix-edit .mb-2 .media-column {
  width: 7%;
}
.matrix-edit .mb-2 .media-input {
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  padding: 0 3px;
  background-color: #fff;
  text-align: center;
  line-height: initial;
}
.matrix-edit .mb-2 .media-input .fa-image {
  color: #545454;
}
.matrix-edit .mb-2 .media-input .image,
.matrix-edit .mb-2 .media-input .img {
  width: 2.5rem;
  height: 2rem;
}
.matrix-edit .mb-2 .media-input .real-image {
  width: 2.5rem;
  height: 2rem;
}
.matrix-edit .mb-2 .row-title-input {
  width: 20%;
}
.matrix-edit .mb-2 .add-text {
  font-size: 1.25rem;
}
.matrix-edit .mb-2 .add-input {
  color: #4fc328;
  font-weight: 600;
}
.matrix-edit .mb-2 .remove-input {
  color: red;
}
.matrix-edit .mb-2 .media-input,
.matrix-edit .mb-2 .add-input > .add-area :hover {
  cursor: pointer;
}
.matrix-edit .mb-2 .choices {
  width: 50%;
}
.matrix-edit .mb-2 .abbreviate-input {
  width: 20%;
}
.matrix-edit .mb-2 .value-input {
  width: 10%;
}
.matrix-edit .mb-2 .bipolar-labels {
  width: 35%;
}
.matrix-edit .mb-2 .bipolar-labels .label-input {
  width: 100%;
}
.matrix-edit .mb-2 .bipolar-labels-title {
  width: 17.5%;
}
.matrix-edit .mb-2 .bipolar-labels-title .label-input,
.matrix-edit .mb-2 .bipolar-labels-title .row-title-input {
  width: 100%;
}

