@import './styleConstants.scss';
@import '../../node_modules/bootstrap/scss/bootstrap';

body {

  .ant-calendar-picker,
  .ant-calendar-picker-container {
    color: inherit;
    font-family: inherit;
    .ant-calendar {
      .ant-calendar-ok-btn {
        color: $white !important;
      }
    }
  }
  .ant-calendar-picker {
    font-size: inherit;
    width: 215px !important;
    .ant-calendar-picker-input {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      padding-right: 2rem;
    }
  }
  .ant-input {
    color: #000 !important;
    font-size: 1rem !important;
  }

  .welcome-page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .welcome-text {
    color: var(--blue);
    font-size: 6rem;
    margin-bottom: 2rem;
  }

  .form-check-inline {
    white-space: nowrap;
  }

  .no-text-underline {
    text-decoration: none;
  }

  .no-text-underline:hover {
    text-decoration: none;
  }
}

.l1-bg {
  background-color: var(--app-body-l1-bg-color);
}

.l2-bg {
  background-color: var(--app-body-l2-bg-color);
}
.l3-bg {
  background-color: var(--app-body-l3-bg-color);
}

// Select placeholder
select:invalid {
  color: $gray-700;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.inline-flex {
  display: inline-flex !important;
}

.flex-div {
  display: flex;
  justify-content: space-between;
}

.space-between {
  justify-content: space-between;
}

.no-list-style {
  list-style: none;
}

.footnote {
  color: $gray-800;
  font-size: 0.9rem;
}

.support-message {
  color: $gray-800;
}

.dropdown-menu {
  background-color: var(--gray-100);
  border: none;
  box-shadow: 0 8px 10px var(--gray-400), -10px 8px 15px var(--gray-400), 10px 8px 15px var(--gray-400);
  padding: 0;
  width: 100%;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
  li:hover {
    background-color: var(--gray-200);
  }
}

.hidden {
  display: none !important;
}

.link {
  color: $linkColor;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.fa-trash-alt {
  cursor: pointer;
}

.fa-flag.gray {
  color: var(--gray-400);
  padding-top: 0.25rem;
}

.fa-flag.orange {
  color: var(--orange);
}

.fa-flag.red {
  color: var(--red);
}

.fa-flag.purple {
  color: var(--purple);
}

.fa-flag.blue {
  color: var(--blue);
}

.fa-flag.yellow {
  color: var(--yellow);
}

.fa-flag.green {
  color: var(--green);
}

.fa-flag.black {
  color: var(--black);
}

.disabled,
label[disabled],
select[disabled] {
  color: $disabledColor !important;
  cursor: default !important;
  pointer-events: none !important;
}

.clickable {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.modal-content {
  .row > * {
    flex-shrink: inherit;
  }
}

// For tags input
.react-tagsinput .react-tagsinput-tag {
  background-color: $gray-400;
  border: none;
  border-radius: 0;
  color: $gray-800;
  font-weight: 600;
}

.react-tagsinput-input {
  width: 180px;
}

// Matches the bootstrap form-control:focus style
.react-tagsinput--focused {
  border-color: #34acf9;
  box-shadow: 0 0 0 0.2rem rgba(5, 105, 169, 0.25);
}

// Quill
.ql-container {
  font-size: inherit !important;
  &.ql-disabled {
    background-color: var(--disabled-bg-color);
  }
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 314px;
  display: inline-block;
}

// For table cell containing name
.table-cell-name {
  max-width: 85%;
  display: inline-block;
}

.content-top-filler {
  background-color: $gray-400;
  height: 0.75em;
  padding: 0;
  margin: 0;
}

.favorite-icon,
.chat-icon {
  color: $gray-400;
  cursor: pointer;
}

.favorite-icon.favorite,
.chat-icon.existing-chat {
  color: $linkColor;
}

.sentiment-positive {
  background-color: var(--positive-color);
}
.sentiment-neutral {
  background-color: var(--neutral-color);
}
.sentiment-negative {
  background-color: var(--negative-color);
}

.net-top {
  background-color: var(--net-top-color);
}
.net-middle {
  background-color: var(--net-middle-color);
}
.net-bottom {
  background-color: var(--net-bottom-color);
}

.filter-bar {
  background-color: $white;
  border: $border;
  display: flex;
  align-items: center;
  height: auto;
  min-height: 2rem;
  padding: 0 0.2rem;
  .fa-filter {
    cursor: pointer;
    margin-right: 0.5rem;
  }
}

.blue-bubble {
  background-color: var(--blue);
}

.red-bubble {
  background-color: var(--red);
}

// Use in conjuction with expandedY to provide vertical expand/collapse animation
.collapsibleY {
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 0.2s;
}

// Use in conjuction with collapsibleY to provide vertical expand/collapse animation
.expandedY {
  height: 100%;
  transform: scaleY(1);
}
