.open-text-options {
  .flex-row {
    line-height: 2;
    padding-left: 1rem;
    .form-check-input {
      margin-top: 0.5rem;
    }
    select[type='select'] {
      width: 30%;
      display: inline;
      margin: 0 0.5rem;
    }
    input[type='number'] {
      width: 5rem;
      display: inline;
      margin: 0 0.5rem;
    }
    .ant-checkbox-wrapper {
      margin-left: -1rem;
    }
  }
  .left-half {
    width: 40%;
    .ant-select {
      width: 9.5rem;
    }
  }
  .right-half {
    width: 55%;
  }
  .radio-group {
    max-width: 9rem;
    display: inline-block;
    padding-top: 0.5rem;
    .radio-label {
      font-size: 1rem;
      color: rgb(0, 0, 0);
      line-height: 2;
    }
  }
  .shared-voted-group {
    border: var(--border);
    border-radius: 4px;
    padding: 0.5rem;
    background-color: #f0f0e5;
    width: 50%;
    display: inline-block;
    .flex-row {
      justify-content: space-between;
    }
  }
}
