.collapsed {
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;

  // Move up/down controls
  > div:first-child {
    padding-top: 4px;
    width: 1.2rem;
    > div {
      background-color: #5b90dc;
      border: var(--border);
      color: var(--white);
      cursor: pointer;
      line-height: 1.2;
      text-align: center;
    }
  }

  .has-rules {
    width: 0;
    height: 0;
    border-top: 1rem solid #5b90dc;
    border-right: 1rem solid transparent;
    position: absolute;
  }

  .has-rules:hover {
    opacity: 0.8;
  }

  .collapsedJoinerContainer {
    background-color: #e9e9e9;
    border: 1px solid var(--gray-500);
    position: relative;
    width: 98%;
    &.emailCollector {
      border-color: var(--gray-700);
    }
    .collapsedJoiner {
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 0.25rem;
      width: inherit;

      &.pageContainer {
        cursor: default;
      }
      > div:first-child {
        display: inline-flex;
        justify-content: space-around;
        align-items: center;
        height: 2.5rem;
        width: 3.75rem;
        .fa-ban {
          color: red;
          opacity: 0.5;
          position: absolute;
        }
        .joiner-icon {
          max-height: 100%;
          max-width: 3.75rem;
          text-align: center;
          &.fa-at {
            color: var(--gray-600);
            font-size: 120%;
          }
        }
      }
      > div:nth-child(2) {
        font-weight: 600;
        margin: 0 0.5rem;
        width: 20%;
        .questionNum {
          margin-right: 0.5rem;
        }
        .groupName {
          font-size: 90%;
          font-weight: normal;
        }
      }
      > div:nth-child(3) {
        width: 63%;
        .thumbnail-container {
          display: inline-block;
          width: 4rem;
          text-align: center;
          margin-right: 0.5rem;
          vertical-align: middle;
          .add-media-icon {
            border: 1px dashed var(--gray-700);
            border-radius: 10%;
            height: 2rem;
            width: 3rem;
            margin: auto;
            &.active {
              border-color: var(--link-color);
            }
          }
        }
        img.thumbnail,
        video.thumbnail {
          max-height: 2.5rem;
          max-width: 3.75rem;
        }
      }
      > div:nth-child(4) {
        display: inline-flex;
        align-items: center;
        position: absolute;
        right: 0.5rem;
        white-space: nowrap;
        width: 6%;
        span:first-child {
          margin-right: 0.5rem;
        }
      }
    }

    .insertJoinerIcon {
      position: absolute;
      left: 49%;
      z-index: 10;
    }
    .insertJoinerIconTop {
      top: -17px;
    }
    .insertJoinerIconBottom {
      bottom: -18px;
    }

    .collapsedCRJoiners {
      padding: 0.5rem;
      flex: 1 1 100%;
      .collapsedJoinerContainer {
        background-color: #f0f0e5;
      }
    }
  }
}
