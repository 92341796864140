.survey-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  white-space: nowrap;
}
.survey-select select {
  margin: 0 1rem;
  width: auto;
}

.select-survey {
  font-weight: 600;
}

.label {
  margin-bottom: 0.3rem;
}

