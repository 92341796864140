.target-criteria-modal.modal-dialog {
  max-width: 40%;
  .rule-name, .rule-quota {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    strong {
      width: 15%;
    }
    .text-input {
      width: 50%;
    }
  }
  .rule-criteria {
    border: 1px solid #ccc;
    padding: 0.5rem;
    margin-top: 0.5rem;
    .fa-trash-alt {
      margin: 0 1rem;
      color: #ccc;
    }
    .ant-select {
      margin-right: 3rem;
      width: -webkit-fill-available;
    }
    .ant-select-selector {
      border-radius: 4px;
    }
    .from-to {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      strong {
        width: 15%;
      }
    }
    .single-option {
      width: auto;
    }
    .radio-option {
      display: block;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .number-input {
    width: 10%;
    min-width: 4rem;
    padding-left: 0.5rem;
    padding-right: 5px;
  }
}