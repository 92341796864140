.filters-selection-menu {
  position: absolute;
  z-index: 9999;
  font-size: 0.85rem;
}
.filters-selection-menu .menu {
  background-color: #eeeeee;
  border: 1px solid #d7d7d7;
}
.filters-selection-menu .menu .scrollable-menu-items {
  max-height: 25rem;
  overflow-y: scroll;
}
.filters-selection-menu .menu .scrollable-menu-items::-webkit-scrollbar {
  width: 6px;
}
.filters-selection-menu .menu .scrollable-menu-items::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
}
.filters-selection-menu .menu .selectable-menu-item {
  padding: 0.3rem;
  cursor: pointer;
}
.filters-selection-menu .menu .selectable-menu-item:hover {
  background-color: #daeff7;
}
.filters-selection-menu .menu .selectable-menu-item.selected {
  background-color: #397aff;
  color: #fff;
}
.filters-selection-menu .menu .selectable-menu-item[disabled] {
  color: #949494;
  cursor: not-allowed;
}
.filters-selection-menu .menu .edit-filters {
  color: #076ac7;
  text-align: center;
}
.filters-selection-menu .top-menu {
  min-width: 10rem;
}

