.add-remove-icons {
  display: flex;
  > div {
    cursor: pointer;
    &[disabled] {
      color: gray;
      cursor: not-allowed;
    }
  }
  > .add {
    color: #4fc328;
    margin-right: 1rem;
  }
  > .remove {
    color: #bf2828;
  }
}
