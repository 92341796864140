.edit-modal .modal-content {
  width: 40rem;
}

.modal {
  .modal-header {
    .text-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 25rem;
    }
  }
  .modal-body {
    .edit-roles {
      .roles-row {
        font-size: 0.9rem;
        .row:nth-child(odd) {
          background-color: var(--white);
        }
        .row:nth-child(even) {
          background-color: var(--gray-100);
        }
      }
      .full-width {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.1rem 0 0.15rem 0.4rem;
        white-space: nowrap;
        > span:first-child {
          width: 40%;
        }
        .read-only,
        .full-access {
          font-weight: 600;
          width: 30%;
        }
        .view,
        .manage {
          width: 30%;
        }
      }
    }
  }
}
