.collapsed {
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
}
.collapsed > div:first-child {
  padding-top: 4px;
  width: 1.2rem;
}
.collapsed > div:first-child > div {
  background-color: #5b90dc;
  border: var(--border);
  color: var(--white);
  cursor: pointer;
  line-height: 1.2;
  text-align: center;
}
.collapsed .has-rules {
  width: 0;
  height: 0;
  border-top: 1rem solid #5b90dc;
  border-right: 1rem solid transparent;
  position: absolute;
}
.collapsed .has-rules:hover {
  opacity: 0.8;
}
.collapsed .collapsedJoinerContainer {
  background-color: #e9e9e9;
  border: 1px solid var(--gray-500);
  position: relative;
  width: 98%;
}
.collapsed .collapsedJoinerContainer.emailCollector {
  border-color: var(--gray-700);
}
.collapsed .collapsedJoinerContainer .collapsedJoiner {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.25rem;
  width: inherit;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner.pageContainer {
  cursor: default;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:first-child {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  height: 2.5rem;
  width: 3.75rem;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:first-child .fa-ban {
  color: red;
  opacity: 0.5;
  position: absolute;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:first-child .joiner-icon {
  max-height: 100%;
  max-width: 3.75rem;
  text-align: center;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:first-child .joiner-icon.fa-at {
  color: var(--gray-600);
  font-size: 120%;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(2) {
  font-weight: 600;
  margin: 0 0.5rem;
  width: 20%;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(2) .questionNum {
  margin-right: 0.5rem;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(2) .groupName {
  font-size: 90%;
  font-weight: normal;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(3) {
  width: 63%;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(3) .thumbnail-container {
  display: inline-block;
  width: 4rem;
  text-align: center;
  margin-right: 0.5rem;
  vertical-align: middle;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(3) .thumbnail-container .add-media-icon {
  border: 1px dashed var(--gray-700);
  border-radius: 10%;
  height: 2rem;
  width: 3rem;
  margin: auto;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(3) .thumbnail-container .add-media-icon.active {
  border-color: var(--link-color);
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(3) img.thumbnail,
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(3) video.thumbnail {
  max-height: 2.5rem;
  max-width: 3.75rem;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(4) {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 0.5rem;
  white-space: nowrap;
  width: 6%;
}
.collapsed .collapsedJoinerContainer .collapsedJoiner > div:nth-child(4) span:first-child {
  margin-right: 0.5rem;
}
.collapsed .collapsedJoinerContainer .insertJoinerIcon {
  position: absolute;
  left: 49%;
  z-index: 10;
}
.collapsed .collapsedJoinerContainer .insertJoinerIconTop {
  top: -17px;
}
.collapsed .collapsedJoinerContainer .insertJoinerIconBottom {
  bottom: -18px;
}
.collapsed .collapsedJoinerContainer .collapsedCRJoiners {
  padding: 0.5rem;
  flex: 1 1 100%;
}
.collapsed .collapsedJoinerContainer .collapsedCRJoiners .collapsedJoinerContainer {
  background-color: #f0f0e5;
}

