.modal-dialog.participant-data-picker-modal {
  min-width: 67rem;
}
.modal-dialog.participant-data-picker-modal input {
  cursor: pointer;
}
.modal-dialog.participant-data-picker-modal table tr :nth-child(2) {
  width: 10rem;
  max-width: 10rem;
}
.modal-dialog.participant-data-picker-modal table tr :nth-child(3) {
  width: 53rem;
  max-width: 53rem;
}
.modal-dialog.participant-data-picker-modal table tr td {
  vertical-align: top;
}
.modal-dialog.participant-data-picker-modal table tr td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
}

