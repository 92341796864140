.rd-filters {
  height: inherit;
  margin-right: 0.5rem;
  .filters-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.5rem;
    > span:first-child {
      font-weight: 600;
    }
    > span:nth-child(2) {
      font-size: 0.7rem;
    }
  }
  .filters-container {
    border: var(--border);
    font-size: 0.9rem;
    max-height: 95%;
    min-height: 10rem;
    overflow-y: auto;
    .filter-row {
      background-color: var(--white);
      .filter {
        padding: 0.1rem 0.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .selected {
        background-color: var(--highlight-yellow);
      }
    }
  }
}
