.self-serve-recruit {
  padding-left: 2rem !important;
}
.self-serve-recruit .target-audience-section {
  margin: 4rem 0;
}
.self-serve-recruit .target-audience-section .target-rule {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.self-serve-recruit .target-audience-section .target-rule .target {
  background-color: #c3daea;
  margin-right: 1rem;
}
.self-serve-recruit .target-audience-section .target-rule .target .edit-target {
  border: none;
  background-color: #c3daea;
  width: 10rem;
  line-height: 2;
}
.self-serve-recruit .target-audience-section .target-rule .target .fa-pencil {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.self-serve-recruit .target-audience-section .target-rule .edit-number {
  margin-right: 1rem;
  width: 4rem;
}
.self-serve-recruit .target-audience-section .target-rule .edit-number.form-control {
  padding-right: 0.1rem;
}
.self-serve-recruit .additional-participant-section .additional-data .flex-row {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.self-serve-recruit .additional-participant-section .additional-data .flex-row .form-control {
  margin-bottom: 0;
}
.self-serve-recruit .additional-participant-section .additional-data .flex-row .label {
  width: 10%;
  min-width: 8rem;
}
.self-serve-recruit .additional-participant-section .additional-data .flex-row .question {
  width: 30%;
  min-width: 24rem;
}
.self-serve-recruit .additional-participant-section .additional-data .flex-row .add-remove {
  width: 2rem;
}
.self-serve-recruit .save-button {
  margin: 2rem 0 1rem 0;
}

