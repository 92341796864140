@import '../../../../styles/styleConstants.scss';

.rd-status-bar {
  .rd-deploy-widget {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    > * {
      margin-right: 0.5rem;
    }
    .survey-progress {
      border: 2px solid $border-color;
      font-weight: 600;
      min-width: 7.1rem;
      padding: 0 0.5rem;
      text-align: center;
    }
    .deploy-button {
      color: $statusBarBlue;
      padding: 0 0.75rem 0.1rem 0.75rem;
    }
    .auto-manual-deploy-switch {
      width: 5.5rem;
      &.ant-switch-checked {
        background-color: $statusBarBlue;
      }
    }
    .auto-deploy-percent-input {
      width: 2.5rem;
      padding: 0;
      text-align: center;
      height: 1.6rem;
    }
  }
}
