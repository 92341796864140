@charset "UTF-8";
.survey-display-prefs table {
  width: 100%;
}
.survey-display-prefs table thead > tr :first-child {
  width: 20%;
}
.survey-display-prefs table thead > tr :nth-child(2) {
  width: 10%;
}
.survey-display-prefs table tbody:before {
  line-height: 2em;
  content: "‌";
  display: block;
}
.survey-display-prefs table .save-and-reset-buttons {
  display: flex;
}
.survey-display-prefs table .save-and-reset-buttons button {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 1rem;
}
.survey-display-prefs table .input {
  padding: 0.2rem 0.4rem;
  height: 1.6rem;
}
.survey-display-prefs table .select {
  padding: 0.1rem 0.4rem;
  height: 1.6rem;
  border: 1px solid var(--gray-300);
  border-radius: 3px;
}
.survey-display-prefs table .config-name {
  text-overflow: ellipsis;
  color: rgb(7, 106, 199);
  white-space: nowrap;
  max-width: 10rem;
  overflow: hidden;
}
.survey-display-prefs button {
  margin-top: 0;
  padding: 0 0.4rem;
}

.modal-body .survey-display-prefs input {
  border: 1px solid var(--gray-300);
  border-radius: 3px;
}
.modal-body .survey-display-prefs .option {
  width: 10rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 10rem;
  overflow: hidden;
}

