.upload-participant-data-modal {
  .modal-body {
    > label {
      font-weight: 600;
    }
    > input {
      margin-bottom: 1rem;
    }
    > button {
      margin-bottom: 0.5rem;
    }
    .fa,
    .far,
    .fas {
      margin-right: 0.5rem;
    }
  }
}

.import-participant-data-modal {
  .modal-body {
    .selected-columns {
      margin: 0.5rem 0 1rem 0;
      > div {
        display: flex;
        align-items: center;
        > div:nth-child(2) {
          border: var(--border);
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 0.5rem;
          padding: 0.2rem 0.5rem;
          width: 96%;
          .fa-times {
            color: var(--gray-700);
            cursor: pointer;
            margin-left: 1rem;
          }
        }
      }
    }
    .add-column-controls {
      display: flex;
      select {
        margin-right: 1rem;
      }
    }
  }
}
