.report-common-fields .flex-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.report-common-fields .flex-row > div:first-child {
  width: 8rem;
}
.report-common-fields .flex-row > div:nth-child(2) {
  flex-grow: 1;
}
.report-common-fields .flex-row input {
  width: 40rem;
}

