.survey-rules .flex-row {
  margin-bottom: 0.5rem;
  margin-left: 0;
  white-space: nowrap;
}
.survey-rules .add-rule-selector-row label {
  margin-right: 1rem;
}
.survey-rules .add-rule-selector-row select {
  width: 15rem;
}
.survey-rules .survey-rule {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
}
.survey-rules .survey-rule .title-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.survey-rules .survey-rule .all-or-any-selector {
  width: 5rem;
}
.survey-rules .survey-rule .segment-category-select,
.survey-rules .survey-rule .segment-select {
  margin-left: 1rem;
  width: 11rem;
}
.survey-rules .survey-rule .function-select {
  margin-bottom: 0.5rem;
  max-width: 24rem;
  min-width: 10rem;
  width: auto;
}
.survey-rules .survey-rule .function-param-controls > .row > :first-child {
  margin-right: 1rem;
}
.survey-rules .survey-rule .set-variable-top-row select,
.survey-rules .survey-rule .set-variable-top-row input {
  width: 11rem;
}
.survey-rules .survey-rule .set-flag-top-row {
  line-height: 2;
}
.survey-rules .survey-rule .set-flag-top-row .flags-dropdown {
  margin-left: 0;
}
.survey-rules .survey-rule .set-flag-top-row .flags-dropdown.selected {
  top: -15px;
}
.survey-rules .survey-rule .conditions .condition-row {
  display: flex;
}
.survey-rules .survey-rule .conditions .condition-row .condition {
  display: flex;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  margin: 0 0.5rem 1rem 0;
  padding: 0.2rem 0 0.5rem 0.5rem;
  width: 92%;
}
.survey-rules .survey-rule .conditions .condition-row .condition > div {
  margin-right: 1rem;
}
.survey-rules .survey-rule .conditions .condition-row .condition > div > div {
  white-space: nowrap;
}
.survey-rules .survey-rule .conditions .condition-row .condition .number-input {
  width: 4rem;
}
.survey-rules .survey-rule .conditions .condition-row .choice-checkboxes {
  max-width: 14rem;
}
.survey-rules .survey-rule .conditions .condition-row .choice-checkboxes label {
  display: block;
  font-weight: normal;
  margin-bottom: 0.25rem;
  padding-left: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.survey-rules .survey-rule .conditions .condition-row .choice-checkboxes label input {
  margin-right: 0.5rem;
}
.survey-rules .survey-rule .conditions .condition-row .choice-checkboxes label .fa-flag {
  font-size: 1.5rem;
}
.survey-rules .survey-rule .conditions .condition-row .matrix-table {
  border-collapse: collapse;
}
.survey-rules .survey-rule .conditions .condition-row .matrix-table td {
  border: 1px solid #d7d7d7;
  padding: 4px;
}
.survey-rules .survey-rule .conditions .condition-row .matrix-table tr > td:nth-child(n+2) {
  text-align: center;
}

