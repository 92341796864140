.rd-participant-details {
  .top-row {
    display: flex;
    margin-bottom: 2rem;
    > div {
      margin-right: 7rem;
    }
    .participant-controls {
      display: flex;
      align-items: center;
      .chat-icon,
      .favorite-icon,
      .flag-icon {
        font-size: 1.5rem;
        margin-right: 1.5rem;
      }
      .flags-dropdown.all-flags.selected {
        margin-top: -1rem;
      }
    }
  }
}

.chat-icon.disabled,
.favorite-icon.disabled,
.flag-icon.disabled {
  cursor: not-allowed !important;
  color: var(--gray-400) !important;
  pointer-events: all !important;
}
