.rd-question-details .open-question-details {
  display: flex;
}
.rd-question-details .open-question-details .left {
  margin-right: 0.3rem;
  width: 20%;
}
.rd-question-details .open-question-details .left h6 {
  font-weight: 600;
  padding: 0.25rem 0 0 0.5rem;
}
.rd-question-details .open-question-details .left .sentiments,
.rd-question-details .open-question-details .left .keywords {
  border: var(--border);
  font-size: 0.9rem;
  width: 100%;
}
.rd-question-details .open-question-details .left .sentiments > div,
.rd-question-details .open-question-details .left .keywords > div {
  display: flex;
  justify-content: space-between;
}
.rd-question-details .open-question-details .left .sentiments > div:nth-of-type(odd),
.rd-question-details .open-question-details .left .keywords > div:nth-of-type(odd) {
  padding: 0 0.25rem;
}
.rd-question-details .open-question-details .left .sentiments > div:nth-of-type(even),
.rd-question-details .open-question-details .left .keywords > div:nth-of-type(even) {
  height: 6px;
}
.rd-question-details .open-question-details .left .sentiments .clickable.selected,
.rd-question-details .open-question-details .left .keywords .clickable.selected {
  background-color: var(--highlight-yellow);
}
.rd-question-details .open-question-details .left .whitelist-link {
  font-size: 0.6rem;
}
.rd-question-details .open-question-details .right {
  width: 80%;
}
.rd-question-details .open-question-details .right .question-details {
  border: var(--border);
  overflow: scroll;
  width: 100%;
}
.rd-question-details .open-question-details .right .question-details table {
  table-layout: auto;
  white-space: nowrap;
  width: inherit;
}
.rd-question-details .open-question-details .right .question-details table thead tr th {
  font-size: 0.8rem;
  font-weight: 600;
}
.rd-question-details .open-question-details .right .question-details table td {
  font-size: 0.85rem;
}
.rd-question-details .open-question-details .right .question-details table td.response-cell {
  padding-left: 0.25rem;
  white-space: normal;
}
.rd-question-details .open-question-details .right .question-details table td.response-cell > div:first-child > span {
  color: var(--blue);
  cursor: pointer;
  font-size: 0.7rem;
}
.rd-question-details .open-question-details .right .question-details table td.response-cell .fa-copy {
  display: none;
  float: right;
  font-size: 1rem;
}
.rd-question-details .open-question-details .right .question-details table td.response-cell:hover .fa-copy {
  display: inline-block;
}
.rd-question-details .open-question-details .right .question-details table td.favorite-cell, .rd-question-details .open-question-details .right .question-details table td.chat-cell {
  font-size: 1rem;
  padding: 0.25rem;
  text-align: center;
}
.rd-question-details .open-question-details .right .question-details table td.flag-cell .flags-dropdown {
  margin: 0 auto;
}
.rd-question-details .open-question-details .right .question-details table td.flag-cell .fas {
  font-size: 0.75rem;
  padding: 0.125rem;
}
.rd-question-details .open-question-details .right .question-details table td.word-count-cell {
  padding-right: 1.5rem;
  text-align: right;
}
.rd-question-details .open-question-details .right .question-details table td.text-rank-cell {
  padding-right: 1.5rem;
  text-align: right;
}
.rd-question-details .open-question-details .right .question-details .pagination {
  font-size: 0.8rem;
}
.rd-question-details .open-question-details .right .search-bar {
  height: 1.75rem;
  width: 100%;
  display: inline-flex;
  margin-bottom: 0.3rem;
  position: relative;
}
.rd-question-details .open-question-details .right .search-bar .fa-search {
  cursor: pointer;
  margin-right: 0.3rem;
  position: absolute;
  top: 0.375rem;
  left: 0.25rem;
}
.rd-question-details .open-question-details .right .search-bar-input {
  height: 100%;
  padding-left: 1.3rem;
}
.rd-question-details .open-question-details .right .sentiment-summary-container,
.rd-question-details .open-question-details .right .wordcloud-container {
  border: var(--border);
  margin-bottom: 1rem;
  position: relative;
  width: fit-content;
}
.rd-question-details .open-question-details .right .sentiment-summary-container .fa-times-circle,
.rd-question-details .open-question-details .right .wordcloud-container .fa-times-circle {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}
.rd-question-details .open-question-details .right .pre-table-row {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
}
.rd-question-details .view-icons {
  display: flex;
}
.rd-question-details .view-icons > div {
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}
.rd-question-details .view-icons .icon {
  font-size: 80%;
}
.rd-question-details .selected {
  border: 1px solid blue;
}

