.completed-vfg {
  display: flex;
  .recording-and-bookmarks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-right: 2rem;
    min-width: fit-content;
    .recording {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      .video {
        width: 640px;
        height: 360px;
        margin-left: 1rem;
      }
    }
    .bookmarks-section {
      height: 40%;
      .bookmarks {
        width: inherit;
        overflow-y: auto;
        .bookmark {
          display: flex;
          .time {
            width: 5rem;
            color: var(--blue);
          }
          .speaker {
            width: 8rem;
            color: var(--blue);
          }
          .comment {
            width: 65%;
            margin-right: 0;
            margin-left: auto;
            padding-right: 2rem;
          }
        }
      }
    }
  }
  .transcripts-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    .search-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.5rem;
    }
    .transcripts {
      height: calc(100vh - 220px);
      overflow-y: auto;
      .transcript-detail {
        width: inherit;
        display: flex;
        .speaker {
          width: 15%;
          cursor: pointer;
        }
        .speach {
          width: 70%;
          cursor: pointer;
        }
        .time {
          width: 10%;
          text-align: end;
        }
        .participant {
          color: var(--blue);
        }
      }
    }
  }
  .moderator {
    text-transform: capitalize;
  }
  a .fa-download {
    color: black;
  }
}
