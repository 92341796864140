.modal-dialog.select-data-points-modal {
  max-width: 1200px;
}
.modal-dialog.select-data-points-modal .filter-expression {
  font-weight: 600;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem;
  width: 100%;
  border: var(--border);
  border-radius: 4px;
}
.modal-dialog.select-data-points-modal .tabs {
  display: flex;
  margin-bottom: 1rem;
}
.modal-dialog.select-data-points-modal .tabs .selected-tab {
  border-bottom: 3px solid #949494;
  font-weight: 600;
}
.modal-dialog.select-data-points-modal .tabs > div {
  padding: 0.75rem 0.5rem 0.25rem 0.5rem;
}
.modal-dialog.select-data-points-modal .select-data-points-table {
  height: 50vh;
  overflow-y: scroll;
}
.modal-dialog.select-data-points-modal .select-data-points-table table {
  table-layout: fixed;
  width: 100%;
}
.modal-dialog.select-data-points-modal .select-data-points-table table tbody tr:nth-of-type(odd) {
  background-color: #f5f5f5;
}
.modal-dialog.select-data-points-modal .select-data-points-table table tbody tr:nth-of-type(even) {
  background-color: #fff;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td {
  vertical-align: top;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td.participant-data-label {
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td .media-thumbnail {
  max-height: 1.33rem;
  max-width: 6.67rem;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td .answers-container * {
  font-size: 0.9rem;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td .answers-container .answer-row-title {
  border-top: 1px solid #949494;
  margin-top: 0.2rem;
  padding-top: 0.5rem;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td .answers-container button {
  margin-bottom: 0.2rem;
  margin-left: 0;
  padding: 0;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td .answers-container button:hover {
  text-decoration: none;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td .answers-container button.selected {
  font-weight: 600;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td .answers-container .fa-flag {
  cursor: pointer;
  padding: 5px;
}
.modal-dialog.select-data-points-modal .select-data-points-table table td .answers-container .fa-flag.selected {
  border: 1px solid var(--gray-800);
  padding: 4px;
}

