.watermark {
  display: flex;
  align-items: center;
  > * {
    margin-right: 0.5rem;
  }
  select {
    width: auto;
  }
}
