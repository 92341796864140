.prodege-config {
  .prodege-questions {
    .question-row {
      display: flex;
      margin-top: 0.5rem;
      > div:nth-child(2) {
        min-width: 1rem;
        width: 1rem;
        .fa-caret-down,
        .fa-caret-right {
          cursor: pointer;
        }
      }
      > div:nth-child(3),
      > div:nth-child(4) {
        font-weight: 600;
        margin-right: 1rem;
      }
      > div:nth-child(5) {
        color: var(--gray-500);
        font-size: 90%;
        text-transform: uppercase;
        white-space: nowrap;
        padding-top: 0.1rem;
      }
    }
    .question-options-row {
      padding-left: 4rem;
      &.expandedY {
        ul {
          margin-bottom: 0;
          li {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
