.session-emotion-data,
.session-dial-data {
  .settings-area {
    display: flex;
    align-items: baseline;
    label {
      font-size: 0.9rem;
    }
    label:hover {
      cursor: pointer;
    }
    .question-select {
      width: auto;
    }
  }
  .emotion-graph-video,
  .dial-data-graph-and-video {
    display: flex;
    margin: 1rem 0;
    .dial-data-graph {
      .recharts-wrapper {
        .custom-tooltip {
          border: 1px solid var(--black);
          background-color: rgb(239, 247, 223);
          font-size: 80%;
          padding: 0 0.25rem;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
    .emotion-graph {
      margin-right: 2rem;
      background-color: var(--white);
      .recharts-wrapper {
        // Custom x-axis tick style
        g.recharts-xAxis g.recharts-cartesian-axis-ticks > g.recharts-cartesian-axis-tick > text > tspan {
          font-size: 80%;
        }
        .custom-tooltip {
          border: 1px solid var(--black);
          background-color: rgb(239, 247, 223);
          font-size: 80%;
          p {
            margin-bottom: 0;
          }
        }
      }
      .graph-controls {
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-top: 0.5rem;
        label,
        select {
          margin: 0 1rem 0 0;
        }
        select {
          max-width: 20rem;
          width: auto;
        }
      }
    }
    .video {
      height: 300px;
      width: 600px;
      margin: 0 auto;
      video {
        height: 100%;
        width: 100%;
        margin: auto;
      }
      video:focus {
        outline: none;
      }
    }
  }
  .overlay-graph-and-video {
    width: 960px;
    height: 560px;
    position: relative;
    margin: 0 auto;
    .dial-data-graph,
    .emotion-data-graph,
    .video {
      width: inherit !important;
      height: inherit !important;
      position: absolute;
      top: 0;
      left: 0;
      .vjs-control-bar {
        z-index: 101;
      }
    }
    .dial-data-graph,
    .emotion-graph {
      position: relative;
      z-index: 100;
    }
  }
  .emotion-filters,
  .dial-data-filters-and-export {
    .emotion-filters-tables,
    .dial-data-filters {
      table {
        th:first-child,
        td:first-child {
          text-align: left;
        }
        th,
        td {
          text-align: left;
          label {
            margin-bottom: 0;
          }
        }
      }
      .color-box {
        border: var(--border);
        height: 1rem;
        width: 1rem;
        margin: auto;
      }
    }
    .emotion-filters-tables {
      padding: 0 0.5rem;
      background-color: var(--white);
      border: 1px solid var(--gray-400);
      margin-right: 1.5rem;
      display: flex;
      max-height: 16rem;
      overflow-y: overlay;
      table {
        display: inline-block;
      }
    }
    .emotion-filters-tables::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    .emotion-filters-tables::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
  .dial-data-filters-and-export {
    display: flex;
    margin: 1rem 0;
    .dial-data-filters {
      table {
        width: 100%;
        display: table;
        th:nth-child(2) {
          text-align: center;
        }
      }
      margin-right: 2rem;
      .title {
        font-weight: 600;
      }
      .filters-table {
        margin: 1rem 0;
        background-color: var(--white);
        border: var(--border);
        width: 600px;
        padding: 0.5rem;
      }
    }
    .data-export {
      margin: 0 auto;
    }
  }
  .fa,
  .far,
  .fas {
    &.fa-exclamation-triangle {
      color: red;
    }
    &.fa-check {
      color: green;
    }
  }
}

.modal {
  .emotion-settings-modal {
    background: inherit;
  }
}
