.rd-status-bar {
  .rd-time-remaining-widget {
    display: flex;
    align-items: center;
    line-height: 1;
    .time-span {
      font-size: 0.9rem !important;
      font-weight: 600;
      text-align: right;
      > span {
        white-space: nowrap;
      }
      > :last-child {
        margin-right: 0.6rem;
      }
    }
    .clock {
      margin-right: 0.5rem;
      font-size: 1.2rem !important;
    }
    .time-remaining-switch {
      margin-left: 0.5rem;
      width: 5.5rem;
      min-width: 5.5rem;
      &.ant-switch-checked {
        background-color: var(--status-bar-blue);
      }
    }
  }
}
