.modal-dialog.add-or-edit-concept-modal {
  max-width: 50rem;
}
.modal-dialog.add-or-edit-concept-modal .modal-body {
  height: 21rem;
}
.modal-dialog.add-or-edit-concept-modal .modal-body > div {
  display: flex;
  margin-bottom: 0.5rem;
}
.modal-dialog.add-or-edit-concept-modal .modal-body > div > :first-child {
  font-weight: 600;
  margin-right: 1rem;
  width: 9rem;
}
.modal-dialog.add-or-edit-concept-modal .modal-body .stim-type-selector {
  width: inherit;
  font-weight: 600;
}
.modal-dialog.add-or-edit-concept-modal .modal-body .caption-input {
  width: 38rem;
}
.modal-dialog.add-or-edit-concept-modal .modal-body .text-stim-quill {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--white);
}
.modal-dialog.add-or-edit-concept-modal .modal-body .text-stim-quill .ql-toolbar {
  border-radius: 4px 4px 0 0;
}
.modal-dialog.add-or-edit-concept-modal .modal-body .quill-text-field {
  height: 8rem;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
}

