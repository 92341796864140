.dialsmith-options {
  .flex-row {
    margin-bottom: 0.5rem;
  }
  .exit-page-selector-row {
    select {
      width: 15rem;
      margin-left: 1rem;
    }
  }
}
