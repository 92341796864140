.modal-dialog.add-or-edit-segment-category-modal {
  max-width: 30rem;
  .link-param-row {
    display: flex;
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
    > div:first-child {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    > div {
      margin-right: 0.5rem;
    }
    .fa-plus,
    .fa-minus {
      vertical-align: middle;
      margin-top: 0.6rem;
    }
  }
}
