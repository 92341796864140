@import '../../../../styles/styleConstants.scss';

.concept-rotation-edit {
  > div {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
  }
  .title-input {
    width: 16rem;
  }
  .concept-control {
    display: flex;
    align-items: center;
    line-height: 2.5;
    margin-bottom: 0.5rem;
    > :first-child {
      border: $border;
      border-radius: 4px 0 0 4px;
      cursor: pointer;
      padding-left: 0.5rem;
      width: 14rem;
      &[disabled] {
        cursor: default;
      }
    }
    > :nth-child(2) {
      background-color: $white;
      border: $border;
      border-radius: 0 4px 4px 0;
      border-left: none;
      cursor: pointer;
      margin-right: 0.75rem;
      width: 3rem;
      text-align: center;
      &[disabled] {
        cursor: default;
      }
      .fa-image {
        color: $gray-600;
        font-size: 1.2rem;
      }
      img,
      video {
        height: 100%;
        max-height: 2rem;
        max-width: 3rem;
        vertical-align: middle;
      }
    }
  }
  .stim-text-translation {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 0.5rem;
  }
}
