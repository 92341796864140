.data-export {
  width: 50%;
  .title {
    font-weight: 600;
  }
  .data-export-buttons {
    margin: 1rem 0;
    .button {
      margin-right: 1rem;
    }
  }
  .file-title {
    margin: 1rem 0;
    font-weight: 600;
  }
}
