.modal-dialog.add-or-edit-media-modal {
  max-width: 50rem;
  .label {
    font-weight: 600;
  }
  .row {
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 1rem;
    > div:first-child {
      width: 10rem;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  }
  .preview-row {
    align-items: initial;
    > div:nth-child(2) {
      .webcontent-preview {
        height: 15rem;
        iframe {
          border: var(--border);
          height: 200%;
          width: 200%;
          transform: scale(0.5);
          transform-origin: 0 0;
        }
      }
    }
  }
}
