.joiner-editor-timing .flex-row {
  line-height: 2;
}
.joiner-editor-timing .flex-row > div:first-child {
  white-space: nowrap;
  width: 16rem;
}
.joiner-editor-timing .ant-time-picker {
  width: 4.8rem;
}
.joiner-editor-timing .ant-time-picker-input {
  padding-left: 0.0225rem;
  margin-left: -0.08745rem;
  line-height: 2;
  font-size: 1rem;
}

