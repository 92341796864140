.media-stim-edit .row {
  flex-wrap: nowrap;
  align-items: center;
}
.media-stim-edit .editor-left-column {
  display: flex;
}
.media-stim-edit .editor-left-column .label {
  display: inline-block;
  vertical-align: middle;
}

.media-options label {
  display: block;
  margin-bottom: 0.5rem;
}

