.concept-rotation-options {
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .position-options {
    align-items: baseline;
    margin-left: 25px;
    select {
      width: 5rem;
      height: 30px;
    }
  }
}
