.total-survey-time {
  text-align: right;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.survey-editor-header {
  width: 100%;
  padding: 0 1rem 0 1.5rem;
  display: flex;
  justify-content: space-between;
  .total-time {
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
  }
  .survey-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > i {
      cursor: pointer;
      margin-left: 1.25rem;
    }
  }
}

.use-template {
  display: block !important;
  margin: 1rem 1.2rem 0;
}

.import-survey {
  display: block;
  margin: 1rem 1.2rem 0;
}

.name-change {
  label {
    width: 3rem;
    text-align: left;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  button {
    width: 4rem;
  }
  input {
    width: 16rem;
  }
}

.pointer {
  cursor: pointer;
}

.vertical-items {
  display: flex;
  flex-direction: column;
  .not-allowed {
    color: var(--disabled-color);
    cursor: not-allowed;
  }
}

.import-error {
  color: var(--gray-900) !important;
  margin: 0 0.5rem 0 1.25rem;
}

.select-media-popover .popover {
  max-width: initial;
}

.modal-dialog.datatable-modal {
  max-width: min-content;
}
