.modal-dialog.advance-filter-editor {
  max-width: 1100px;
}
.modal-dialog.advance-filter-editor .modal-content {
  width: auto;
}
.modal-dialog.advance-filter-editor .modal-content .hint {
  padding: 0 0 0.5rem 1rem;
  font-size: "0.75rem";
  color: #c0c0c0;
}
.modal-dialog.advance-filter-editor .modal-content .dnd-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 0.75rem;
  border: 1px solid #dddddd;
  overflow-x: auto;
}
.modal-dialog.advance-filter-editor .modal-content .filter-parts {
  display: inline-block;
  border: 1px solid #dddddd;
  margin: 0.5rem 0.5rem 0.5rem 0;
  height: inherit;
}
.modal-dialog.advance-filter-editor .modal-content .filter-parts .filter-eraser {
  font-size: 0.75rem;
  padding: 0 0.5rem;
  width: 100%;
  height: 0.75rem;
}
.modal-dialog.advance-filter-editor .modal-content .filter-parts .data-point {
  max-width: 10.5rem;
  white-space: nowrap;
  padding: 0.25rem;
  height: 3rem;
}
.modal-dialog.advance-filter-editor .modal-content .filter-parts .data-point .field,
.modal-dialog.advance-filter-editor .modal-content .filter-parts .data-point .value {
  max-width: 7rem;
  min-width: 4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.modal-dialog.advance-filter-editor .modal-content .filter-parts .data-point .field {
  color: #c0c0c0;
}
.modal-dialog.advance-filter-editor .modal-content .filter-parts .parts-label {
  font-weight: 600;
  margin: 0 0.5rem;
  height: 3rem;
  display: table-cell;
  vertical-align: middle;
  padding: 0.25rem;
}
.modal-dialog.advance-filter-editor .modal-content .filter-parts .filter-eraser:hover {
  cursor: pointer;
}
.modal-dialog.advance-filter-editor .modal-content .uppercase {
  text-transform: uppercase;
}

