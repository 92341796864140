.project-details-ss {
  padding-left: 2rem;
  .project-name-and-status {
    margin-bottom: 2rem;
    > div {
      display: flex;
      align-items: baseline;
      margin-bottom: 0.5rem;
      > div:first-child {
        font-weight: 600;
        width: 9rem;
      }
      .save-button {
        border: 0 !important;
        margin-left: 1rem;
      }
      .status-box {
        border: 1px solid var(--gray-500);
        border-radius: 6px;
        color: var(--gray-800);
        font-weight: 600;
        height: 1.7rem;
        text-align: center;
        width: 9rem;
      }
    }
    input {
      width: 25rem;
    }
  }
  .self-serve-step {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    .fa-check.ok {
      color: #6fd016;
    }
    .fa-check.tbd,
    .fa-lock {
      color: var(--gray-700);
    }
    > *:first-child {
      font-size: 2rem;
      width: 32px;
    }
    > *:nth-child(2) {
      font-size: 4rem;
    }
    .step-desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 3.5rem;
      > div:first-child {
        font-weight: 600;
        margin-bottom: .5rem;
      }
      > div:nth-child(2) {
        color: var(--gray-800);
        display: flex;
        align-items: baseline;
        font-size: 90%;
        a {
          &:hover {
            text-decoration: none;
          }
        }
        button.btn {
          font-size: inherit;
          line-height: 1;
          margin: 0;
        }
      }
    }
  }
  .align-right {
    text-align: right;
  }
}
