.report-add-question-modal .modal-body .label {
  font-weight: 600;
}
.report-add-question-modal .modal-body .ant-select {
  width: 100%;
}
.report-add-question-modal .modal-body .flex-row {
  margin: 0 0 0.5rem 0;
  white-space: nowrap;
}
.report-add-question-modal .modal-body .flex-row > :first-child {
  min-width: 8rem;
}
.report-add-question-modal .modal-body .top-row > :nth-child(3) {
  margin: 0 0 0 1rem;
}
.report-add-question-modal .modal-body .norm-row input {
  width: 4.5rem;
}
.report-add-question-modal .modal-body .session-override-container {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  max-height: 16rem;
  margin-bottom: 0.5rem;
  overflow-y: auto;
  padding: 1rem;
}
.report-add-question-modal .modal-body .session-override-container > div:first-child {
  margin-bottom: 0.5rem;
}
.report-add-question-modal .modal-body .session-override-container .row {
  margin-left: 1.2rem;
}

