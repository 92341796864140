.modal-dialog.media-choice-modal {
  max-width: 50rem;
  .row {
    flex-wrap: nowrap;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    > :first-child {
      width: 9rem;
    }
    > :nth-child(2) {
      width: 100%;
    }
  }
  .label {
    font-weight: 600;
  }
}
