.concept-rotation-options label {
  display: block;
  margin-bottom: 0.5rem;
}
.concept-rotation-options .position-options {
  align-items: baseline;
  margin-left: 25px;
}
.concept-rotation-options .position-options select {
  width: 5rem;
  height: 30px;
}

