.dial-config {
  margin-left: 3rem;
}
.dial-config .row {
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.dial-config > .row > :first-child {
  width: 4rem;
}
.dial-config > .row > :nth-child(2),
.dial-config > .row > :nth-child(4) {
  width: 6rem;
}
.dial-config > .row > :nth-child(3) {
  margin: 0 1rem;
  width: 10rem;
}
.dial-config .action-button-controls .row {
  align-items: unset;
}
.dial-config .action-button-controls .row .action-button-field {
  height: fit-content;
  width: 14rem;
  margin: 0 1rem 0 4rem;
}
.dial-config .action-button-controls .row .action-button-options {
  margin: 0 1rem 0 0;
}
.dial-config .action-button-controls .row .action-button-options .show-question-control {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.dial-config .action-button-controls .row .action-button-options .show-question-control > label {
  margin: 0 0.5rem 0 0;
}
.dial-config .action-button-controls .row .action-button-options .show-question-control select {
  min-width: 8rem;
}

