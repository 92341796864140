.modal-dialog.add-or-edit-segment-category-modal {
  max-width: 30rem;
}
.modal-dialog.add-or-edit-segment-category-modal .link-param-row {
  display: flex;
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
}
.modal-dialog.add-or-edit-segment-category-modal .link-param-row > div:first-child {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
.modal-dialog.add-or-edit-segment-category-modal .link-param-row > div {
  margin-right: 0.5rem;
}
.modal-dialog.add-or-edit-segment-category-modal .link-param-row .fa-plus,
.modal-dialog.add-or-edit-segment-category-modal .link-param-row .fa-minus {
  vertical-align: middle;
  margin-top: 0.6rem;
}

