.combo-select {
  max-width: 12rem;
  min-width: 10rem;
}
.combo-select .select-positions {
  border: 1px solid var(--gray-400);
  border-radius: 4px;
  color: inherit;
  margin: 0;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  font-size: 1rem;
  height: calc(1.5em + 0.5rem + 2px);
  line-height: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.combo-select .open {
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
}
.combo-select .position-labels {
  width: inherit;
  border: 1px solid var(--gray-400);
  border-top: 0;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(--white);
  z-index: 5;
}
.combo-select .position-labels label {
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 0.25rem;
}
.combo-select .position-labels label input {
  margin-left: 0.5rem;
}

.combo-select:hover {
  cursor: pointer;
}

