.panel-configs {
  .panel-name-row {
    display: flex;
    align-items: baseline;
  }
  .panel {
    padding-left: 0.5rem;
  }
  .selected-panel {
    border: var(--border);
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    min-width: max-content;
    .param-overrides {
      display: flex;
      > div {
        margin-right: 1rem;
      }
      .param-override-row {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        > * {
          margin-right: 0.5rem;
        }
        input {
          width: 6rem;
        }
        .param-type-small {
          font-size: 80%;
          white-space: nowrap;
        }
      }
    }
  }
  .samplify-config {
    margin-left: 1rem;
    > div {
      margin-bottom: 0.5rem;
    }
    input {
      display: inline-block;
    }
    .read-only-url-row {
      margin-left: 0.5rem;
      > * {
        font-size: 85%;
      }
      > label {
        white-space: nowrap;
        width: 6rem;
      }
      > input {
        width: 50rem;
      }
    }
  }
  .custom-panel-config {
    border: var(--border);
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    width: min-content;
    .add-link-param-link {
      margin-right: 2rem;
    }
    .btn-warn {
      display: block;
      margin: 0 0 0 auto;
    }
  }
}
