.custom-ui-form {
  font-size: 90%;
}
.custom-ui-form img {
  height: 2.5rem;
  width: auto;
}
.custom-ui-form input {
  max-width: 75%;
}
.custom-ui-form .row {
  align-items: center;
}
.custom-ui-form .logo-controls {
  padding-left: 0;
}
.custom-ui-form .logoImgPreview {
  max-height: 3rem;
  margin: 0.5rem 0;
}
.custom-ui-form .logoImgPreview .app-logo {
  margin-left: 0;
}
.custom-ui-form .form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.5rem;
  margin: 0.5rem 0;
}
.custom-ui-form .square {
  border: 1px solid black;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
}
.custom-ui-form .update-buttons {
  display: flex;
  justify-content: space-between;
  width: 33%;
  margin-top: 1.5rem;
}
.custom-ui-form .update-buttons button {
  margin-left: 0;
}

.modal-dialog.preview-custom-ui-modal {
  max-width: 750px;
}
.modal-dialog.preview-custom-ui-modal .preview-custom-ui .container {
  padding-left: 1rem;
  padding-right: 1rem;
}
.modal-dialog.preview-custom-ui-modal .preview-custom-ui .center {
  justify-content: center;
}

