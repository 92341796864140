.modal-body .participant-data-columns {
  overflow-y: auto;
}
.modal-body .participant-data-columns .hidden-section,
.modal-body .participant-data-columns .visible-section {
  display: inline-block;
  width: 48%;
  border: 1px solid #d7d7d7;
  padding: 0.5rem;
  font-size: 90%;
  vertical-align: top;
}
.modal-body .participant-data-columns .hidden-section .col-sm-2,
.modal-body .participant-data-columns .visible-section .col-sm-2 {
  padding-left: 0;
}
.modal-body .participant-data-columns .hidden-section .visible-columns,
.modal-body .participant-data-columns .visible-section .visible-columns {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c0c0c0;
  margin-bottom: 0.25rem;
  height: 2rem;
}
.modal-body .participant-data-columns .hidden-section .col-number,
.modal-body .participant-data-columns .visible-section .col-number {
  width: 12%;
}
.modal-body .participant-data-columns .hidden-section .column-item,
.modal-body .participant-data-columns .visible-section .column-item {
  width: 88%;
}
.modal-body .participant-data-columns .hidden-section .temp,
.modal-body .participant-data-columns .visible-section .temp {
  display: flex;
  align-items: center;
}
.modal-body .participant-data-columns .hidden-section .place-holder,
.modal-body .participant-data-columns .visible-section .place-holder {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 2em;
  border: 1px dashed #c0c0c0;
}
.modal-body .participant-data-columns .hidden-section .column,
.modal-body .participant-data-columns .visible-section .column {
  width: 100%;
  height: 90%;
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
}
.modal-body .participant-data-columns .hidden-section .column .title,
.modal-body .participant-data-columns .hidden-section .column .visible-title,
.modal-body .participant-data-columns .visible-section .column .title,
.modal-body .participant-data-columns .visible-section .column .visible-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: inherit;
  display: inline-flex;
  align-items: center;
}
.modal-body .participant-data-columns .hidden-section .column .title,
.modal-body .participant-data-columns .visible-section .column .title {
  width: 8rem;
  font-size: inherit;
  font-weight: 400;
}
.modal-body .participant-data-columns .hidden-section .column .visible-title,
.modal-body .participant-data-columns .visible-section .column .visible-title {
  width: 6.6rem;
}
.modal-body .participant-data-columns .hidden-section .column .no-style,
.modal-body .participant-data-columns .visible-section .column .no-style {
  border: none;
  background: none;
}
.modal-body .participant-data-columns .hidden-section .column .no-style .fas,
.modal-body .participant-data-columns .visible-section .column .no-style .fas {
  color: #000;
}
.modal-body .participant-data-columns .hidden-section .column .no-style:hover,
.modal-body .participant-data-columns .visible-section .column .no-style:hover {
  cursor: pointer;
}
.modal-body .participant-data-columns .hidden-section .wider,
.modal-body .participant-data-columns .visible-section .wider {
  width: 100%;
}
.modal-body .participant-data-columns .hidden-section {
  margin-right: 0.5rem;
}
.modal-body .participant-data-columns .visible-section {
  margin-left: 0.5rem;
}
.modal-body .participant-data-columns .column-name {
  border: 1px solid #d7d7d7;
  margin: 0.25rem 0.5rem;
  vertical-align: middle;
}

