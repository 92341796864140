.matrix-question-responses {
  font-size: 0.8rem;

  .controls-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 1rem;
    }
    .export-buttons {
      font-size: 1rem;
      margin-left: auto;
      .fa-file-excel {
        color: var(--green);
        margin: 0 1rem;
      }
      .fa-file-powerpoint {
        color: var(--red);
        margin: 0 1rem;
      }
    }
  }
  .table-container {
    overflow-x: scroll;
    table {
      table-layout: auto;
      thead {
        tr {
          th:first-child {
            min-width: 10rem;
            max-width: 30rem;
          }
          th.bipolar:nth-last-child(2) {
            background-color: var(--gray-100);
            min-width: 10rem;
            max-width: 30rem;
          }
          th {
            width: 6rem;
            padding-bottom: 0;
            padding-left: 0.5rem;
            .matrix-details-column-header {
              display: inline-flex;
              justify-content: space-between;
              align-items: baseline;
              white-space: nowrap;
              width: 100%;
              margin: 0.5rem 0;
              > span:nth-child(2) {
                display: inline-block;
                max-width: 10rem;
                overflow: hidden;
                padding-right: 0.4rem;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .text-rotate {
                vertical-align: bottom;
                text-align: left;
                line-height: 1;
                width: 75px;
                transform-origin: bottom left;
                transform: rotate(-20deg) translate(0px, 60px);
              }
            }
          }
        }
      }
      tbody {
        tr {
          background-color: transparent !important;
        }
        td:first-child {
          border: none !important;
          padding-right: 1rem;
          text-align: left;
          white-space: normal;
          padding-left: 1rem;
          text-indent: -1rem;
          .thumbnail {
            text-align: center;
            font-size: 0.8rem;
            img {
              display: block;
              max-width: 4rem;
              margin: 0 auto;
            }
          }
        }
        td.bipolar:nth-last-child(2) {
          border: none !important;
          padding-right: 1rem;
          text-align: left;
          white-space: normal;
          padding-left: 1rem;
        }
        td {
          line-height: 1.5rem;
          max-width: 10rem;
          padding: 0;
          text-align: center;
          vertical-align: middle;
          white-space: nowrap;
          .net-top,
          .net-middle,
          .net-bottom,
          .response-rate-cell {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1.5rem;
            margin: 0.25rem 0.5rem;
            font-weight: 600;
          }
        }
      }
    }
    .one-net,
    .two-nets {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      line-height: 1.5rem;
      height: 1.5rem;
    }
    .one-net {
      width: 128px;
    }
    .two-nets {
      width: 280px;
    }
    .three-nets {
      line-height: 1.5rem;
      height: 1.5rem;
      justify-content: center;
      display: flex;
      font-weight: 600;
      .middle-net {
        display: inline-block;
        vertical-align: top;
        line-height: 1.5rem;
      }
    }
  }
}
