.sentiment-summary-container {
  padding: 0.25rem;
}
.sentiment-summary-container .sentiment-summary-body {
  display: flex;
  padding: 0.5rem 2rem;
}
.sentiment-summary-container .sentiment-summary-body .sentiment-summary-left {
  margin-right: 2rem;
}
.sentiment-summary-container .sentiment-summary-body .sentiment-summary-right {
  width: 100%;
}
.sentiment-summary-container .sentiment-chart-legend {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
}
.sentiment-summary-container .sentiment-chart-legend > div {
  display: flex;
  align-items: center;
  margin-right: 0.6rem;
}
.sentiment-summary-container .sentiment-chart-legend > div > div:first-child {
  border: 1px solid #999;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.2rem;
}

