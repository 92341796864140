@import '../../../../../styles/styleConstants.scss';

.modal-dialog.custom-nets-modal {
  display: table;
  .modal-content {
    max-width: 98vw;
    .modal-body {
      margin-right: 1rem;
      overflow-x: auto;
    }
  }
  table {
    width: auto;
    thead {
      tr {
        th:first-child {
          background-color: $white;
        }
        th {
          background-color: $gray-300; // matches invoke table head bg color
          min-width: 7rem;
          max-width: 7rem;
          width: 7rem;
          padding: 0 0.25rem;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    tbody {
      td:first-child {
        border: none !important;
        padding-right: 1rem;
        text-align: left;
      }
      td {
        border: 1px solid $gray-500 !important;
        height: 3rem;
        text-align: center;
        vertical-align: middle;
        input {
          margin: auto;
          position: initial;
        }
      }
    }
  }
}
