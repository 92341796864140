.detect-options {
  margin-top: 1rem;
  .detect-options-row {
    white-space: nowrap;
    align-items: center;
    > :first-child {
      width: 14rem;
    }
    > :nth-child(2) {
      select {
        display: inline-block;
        margin-left: 1rem;
        width: auto;
      }
    }
  }
}
