.dialsmith-stim-edit .link-params {
  display: flex;
}
.dialsmith-stim-edit .link-params .link-param-row {
  display: flex;
  margin-bottom: 0.5rem;
}
.dialsmith-stim-edit .link-params .link-param-row > :first-child,
.dialsmith-stim-edit .link-params .link-param-row > :nth-child(2) {
  margin-right: 1rem;
  width: 12rem;
}

