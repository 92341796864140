.project-status-bar .participant-counts-widget {
  display: flex;
  align-items: center;
  flex-flow: column;
  padding: 0.25rem;
  margin-top: 0.25rem;
  width: 8rem;
  height: 2rem;
  user-select: none;
}
.project-status-bar .participant-counts-widget .active-counts {
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 70%;
  line-height: 2;
  width: 100%;
}
.project-status-bar .participant-counts-widget i.fas {
  font-size: 1rem !important;
}
.project-status-bar .participant-counts-widget .bubble {
  border-radius: 0.5rem;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.1rem;
  margin-right: 0.5rem;
  width: 2rem;
}
.project-status-bar .participant-counts-widget .bubble-active {
  background-color: #316500;
}
.project-status-bar .participant-counts-widget .bubble-remove {
  background-color: var(--red);
}
.project-status-bar .participant-counts-widget .bubble-accept {
  background-color: var(--status-bar-blue);
}
.project-status-bar .participant-counts-widget .bubble-invite {
  background-color: var(--gray-700);
}
.project-status-bar .view-all {
  z-index: 10;
  background-color: var(--white);
  border-radius: 4px;
  height: auto;
  position: absolute;
  top: -20px;
  left: -64px;
}

