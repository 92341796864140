.user-edit {
  .roles-container,
  .projects-container {
    max-height: 10rem;
    overflow-y: scroll;
    .user-role-project:nth-child(even) {
      background-color: var(--white);
    }
    .user-role-project:nth-child(odd) {
      background-color: var(--gray-100);
    }
    .role,
    .project {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 0;
      .row-label {
        margin: 0.25rem 0.5rem;
      }
    }
  }
  button {
    margin-right: 1rem;
  }
}
