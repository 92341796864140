.survey-settings > div {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5rem;
  min-height: 2.1rem;
}
.survey-settings > div.bottom-border {
  border-bottom: var(--border);
}
.survey-settings > div > :first-child {
  margin-right: 1rem;
  min-width: 10rem;
  padding-top: 0.32rem;
  width: 11rem;
}
.survey-settings > div > :nth-child(2) {
  margin-right: 1rem;
}
.survey-settings .take-survey-link {
  background-color: var(--gray-200);
  border: var(--border);
  border-radius: 4px;
  padding: 0.25rem 0.75rem;
}
.survey-settings .live-survey {
  background-color: #f9f9f9;
  border: var(--border);
  padding: 0 0 0.4rem 0.4rem;
}
.survey-settings .exclude-questions {
  align-items: flex-start;
}
.survey-settings .exclude-questions > div:first-child {
  margin-right: 1rem;
  white-space: nowrap;
  width: auto;
}
.survey-settings .exclude-questions > div:nth-child(2) {
  flex: 1;
}
.survey-settings .direct-to-survey-input {
  max-width: 24em;
  position: relative;
  top: 0.25em;
}
.survey-settings .unselected .date-container .ant-calendar-picker {
  border: 2px solid var(--red);
}

.modal .session-cleanup {
  max-width: 40vw;
}
.modal .session-cleanup input {
  display: inline-block;
}

