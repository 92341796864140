.report-add-segment-modal .main-section .row {
  align-items: center;
}
.report-add-segment-modal .main-section .row .col-sm-4 {
  flex-basis: 30%;
  font-weight: 600;
  white-space: nowrap;
}
.report-add-segment-modal .main-section .row:first-child {
  margin-bottom: 0.5rem;
}
.report-add-segment-modal .main-section .segment-name-field {
  width: 15rem;
}
.report-add-segment-modal .session-override-section {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 1rem;
}
.report-add-segment-modal .session-override-section .session-override-controls {
  margin-top: 1rem;
  padding-left: 1.25rem;
}
.report-add-segment-modal .session-override-section .session-override-controls .row {
  align-items: flex-end;
  margin-bottom: 0.5rem;
}
.report-add-segment-modal .session-override-section .session-override-controls .row > div.col:first-child {
  max-width: 13rem;
}
.report-add-segment-modal .session-override-section .session-override-controls .row > div.col:first-child label {
  margin-bottom: 0;
  width: 100%;
}
.report-add-segment-modal .desc-text {
  color: var(--gray-800);
}

