.fa,
.far,
.fas {
  &.fa-exclamation-triangle {
    color: darkorange;
  }
  &.fa-check {
    color: green;
  }
}
