.custom-login-time {
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    > * {
      white-space: nowrap;
    }
    > :first-child {
      width: 4.5rem;
    }
    > :nth-child(2) {
      margin-right: 0.3rem;
    }
    > :nth-child(3) {
      width: 11.5rem;
    }
    > :nth-child(4) {
      margin-right: 0.3rem;
    }
    > :nth-child(5) {
      margin-right: 0.5rem;
      width: 3rem;
    }
    input {
      margin: 0;
      position: initial;
    }
  }
}
