@import '../../styles/styleConstants.scss';

.report-add-segment-modal {
  .main-section {
    .row {
      align-items: center;
      .col-sm-4 {
        flex-basis: 30%;
        font-weight: 600;
        white-space: nowrap;
      }
    }
    .row:first-child {
      margin-bottom: 0.5rem;
    }
    .segment-name-field {
      width: 15rem;
    }
  }
  .session-override-section {
    border: $border;
    border-radius: 5px;
    margin-top: 1rem;
    padding: 1rem;
    .session-override-controls {
      margin-top: 1rem;
      padding-left: 1.25rem;
      .row {
        align-items: flex-end;
        margin-bottom: 0.5rem;
        > div.col:first-child {
          max-width: 13rem;
          label {
            margin-bottom: 0;
            width: 100%;
          }
        }
      }
    }
  }
  .desc-text {
    color: var(--gray-800);
  }
}
