.participant-list-container {
  white-space: nowrap;
  max-width: 100%;
}
.participant-list-container .invoke-table-container .table {
  table-layout: auto;
}
.participant-list-container button {
  display: block;
  margin-top: 1rem;
}

