.data-table-library {
  width: 55rem;
}
.data-table-library > * {
  margin-bottom: 1rem;
}
.data-table-library .search-input {
  display: inline-flex;
  float: right;
}
.data-table-library .on-left {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 30%;
}
.data-table-library .screener-data-table {
  width: 67%;
}

