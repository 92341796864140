.create-client-modal .modal-body {
  padding: 1.5rem;
}
.create-client-modal .modal-body .row {
  flex-wrap: nowrap;
}
.create-client-modal .modal-body .row label {
  width: 5rem;
}

