.modal-dialog.media-choice-modal {
  max-width: 50rem;
}
.modal-dialog.media-choice-modal .row {
  flex-wrap: nowrap;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}
.modal-dialog.media-choice-modal .row > :first-child {
  width: 9rem;
}
.modal-dialog.media-choice-modal .row > :nth-child(2) {
  width: 100%;
}
.modal-dialog.media-choice-modal .label {
  font-weight: 600;
}

