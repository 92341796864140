.custom-report-config .forms {
  background-color: var(--gray-100);
}
.custom-report-config .full-width {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.custom-report-config .title {
  font-size: 1rem;
}
.custom-report-config .question-options {
  font-size: 0.75rem;
}
.custom-report-config .column3 {
  width: 24%;
  overflow: auto;
  height: 100%;
}
.custom-report-config .content-column-height {
  overflow-y: auto;
  max-height: 45vh;
}
.custom-report-config .label-text {
  font-weight: 600;
}
.custom-report-config .label-text-width {
  width: 75%;
}
.custom-report-config .row {
  display: flex;
  justify-content: space-between;
}
.custom-report-config .bordered-list {
  border: 1px solid var(--gray-400);
  border-radius: 5px;
}

.modal-dialog.custom-report-config {
  max-width: 800px !important;
  margin: 1.75rem auto;
}
.modal-dialog.custom-report-config .included-title-text {
  position: fixed;
  left: 52%;
}

