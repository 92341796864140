.rd-question-details-header {
  .question-nav {
    display: flex;
    align-items: center;
    .question-nav-select {
      margin: 0 1rem;
      min-width: 15rem;
    }
    .link {
      font-size: 0.9rem;
    }
  }
}
