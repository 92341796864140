.modal-dialog.participant-enrollment-select-modal {
  max-width: 90%;
}
.modal-dialog.participant-enrollment-select-modal .participant-enrollment-selection {
  display: flex;
}
.modal-dialog.participant-enrollment-select-modal .participant-enrollment-selection .left {
  border-right: var(--border);
  padding-right: 1.5rem;
}
.modal-dialog.participant-enrollment-select-modal .participant-enrollment-selection .left label {
  display: block;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}
.modal-dialog.participant-enrollment-select-modal .participant-enrollment-selection .right {
  margin-left: 1.5rem;
  overflow-x: auto;
  width: 100%;
}
.modal-dialog.participant-enrollment-select-modal .participant-enrollment-selection .right .invoke-table-container table {
  table-layout: auto;
  white-space: nowrap;
}

