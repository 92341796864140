.participant-responses .participant-responses-table {
  border: 1px solid #d7d7d7;
  width: 100%;
}
.participant-responses .participant-responses-table thead tr th.qNum-col {
  width: 3rem;
}
.participant-responses .participant-responses-table thead tr th.group-col {
  width: 6rem;
}
.participant-responses .participant-responses-table thead tr th.title-col {
  width: 12rem;
}
.participant-responses .participant-responses-table thead tr th.type-col {
  width: 7rem;
}
.participant-responses .participant-responses-table thead tr th.chat-col {
  width: 4rem;
}
.participant-responses .participant-responses-table thead tr th.time-col {
  width: 7rem;
}
.participant-responses .participant-responses-table tbody tr:hover {
  background-color: transparent;
}
.participant-responses .participant-responses-table tbody tr.unanswered {
  background-color: #eeeeee;
}
.participant-responses .participant-responses-table tbody tr td {
  border: 1px solid #d7d7d7;
}
.participant-responses .participant-responses-table tbody tr td.qNum-cell {
  font-weight: 600;
}
.participant-responses .participant-responses-table tbody tr td.group-cell {
  padding-right: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 6rem;
}
.participant-responses .participant-responses-table tbody tr td.title-cell {
  padding-right: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12rem;
}
.participant-responses .participant-responses-table tbody tr td.content-cell {
  padding: 0.5rem;
}
.participant-responses .participant-responses-table tbody tr td.content-cell img,
.participant-responses .participant-responses-table tbody tr td.content-cell video,
.participant-responses .participant-responses-table tbody tr td.content-cell .fa-video {
  max-width: 6rem;
  max-height: 4rem;
  margin-right: 0.5rem;
}
.participant-responses .participant-responses-table tbody tr td.content-cell .question-image-and-prompt {
  display: flex;
}
.participant-responses .participant-responses-table tbody tr td.content-cell .question-image-and-prompt .question-prompt {
  white-space: pre-line;
}
.participant-responses .participant-responses-table tbody tr td.content-cell .answer-section {
  display: flex;
  font-weight: 600;
  margin-top: 0.5rem;
}
.participant-responses .participant-responses-table tbody tr td.content-cell .answer-section > div:first-child {
  margin-right: 2rem;
}
.participant-responses .participant-responses-table tbody tr td.content-cell .answer-section ul {
  margin-bottom: 0;
  padding-left: 1rem;
}
.participant-responses .participant-responses-table tbody tr td.content-cell .answer-section .choice-image {
  max-width: 6rem;
  max-height: 2rem;
  margin-bottom: 0.2rem;
}
.participant-responses .participant-responses-table tbody tr td.content-cell .answer-section .pending-answers {
  font-weight: normal;
}
.participant-responses .participant-responses-table tbody tr td.chat-cell {
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

