.data-table-library {
  width: 55rem;
  > * {
    margin-bottom: 1rem;
  }
  .search-input {
    display: inline-flex;
    float: right;
  }
  .on-left {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 30%;
  }
  .screener-data-table {
    width: 67%;
  }
}
