.data-filters {
  width: 100%;
}
.data-filters .filter-bar {
  background-color: var(--white);
  border: var(--border);
  display: flex;
  align-items: flex-start;
  height: auto;
  min-height: 2rem;
  padding: 0.2rem;
}
.data-filters .filter-bar .fa-filter {
  cursor: pointer;
  margin: 0.25rem 0.4rem 0 0;
}
.data-filters .filter-bar .filter-labels {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
}
.data-filters .filter-bar .filtered-result {
  font-size: 0.8rem;
  margin-top: 0.1rem;
  margin-left: auto;
}

