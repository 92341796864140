.edit-modal .modal-content {
  width: 40rem;
}

.modal .modal-header .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 25rem;
}
.modal .modal-body .edit-roles .roles-row {
  font-size: 0.9rem;
}
.modal .modal-body .edit-roles .roles-row .row:nth-child(odd) {
  background-color: var(--white);
}
.modal .modal-body .edit-roles .roles-row .row:nth-child(even) {
  background-color: var(--gray-100);
}
.modal .modal-body .edit-roles .full-width {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.1rem 0 0.15rem 0.4rem;
  white-space: nowrap;
}
.modal .modal-body .edit-roles .full-width > span:first-child {
  width: 40%;
}
.modal .modal-body .edit-roles .full-width .read-only,
.modal .modal-body .edit-roles .full-width .full-access {
  font-weight: 600;
  width: 30%;
}
.modal .modal-body .edit-roles .full-width .view,
.modal .modal-body .edit-roles .full-width .manage {
  width: 30%;
}

