.rd-status-bar {
  .rd-login-status-widget {
    display: flex;
    align-items: center;
    select {
      margin-left: 0.5rem;
      min-width: 9rem;
    }
  }
}
