.edit-function-form {
  .function-form-parameter {
    line-height: 2rem;
  }
  .function-code {
    font-family: monospace;
    font-size: 16px;
    height: 20rem;
  }
}
