@import '../../../../styles/styleConstants.scss';

.modal-dialog.select-data-points-modal {
  max-width: 1200px;
  .filter-expression {
    font-weight: 600;
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem;
    width: 100%;
    border: var(--border);
    border-radius: 4px;
  }
  .tabs {
    display: flex;
    margin-bottom: 1rem;
    .selected-tab {
      border-bottom: 3px solid $gray-700;
      font-weight: 600;
    }
    > div {
      padding: 0.75rem 0.5rem 0.25rem 0.5rem;
    }
  }
  .select-data-points-table {
    height: 50vh;
    overflow-y: scroll;
    table {
      table-layout: fixed;
      width: 100%;

      // Override row hovers
      tbody tr:nth-of-type(odd) {
        background-color: $gray-100;
      }
      tbody tr:nth-of-type(even) {
        background-color: $white;
      }

      td {
        vertical-align: top;
        &.participant-data-label {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .media-thumbnail {
          // Same as RDQuestionList media-thumbnail-cell
          max-height: 1.33rem;
          max-width: 6.67rem;
        }
        .answers-container {
          * {
            font-size: 0.9rem;
          }
          .answer-row-title {
            border-top: 1px solid $gray-700;
            margin-top: 0.2rem;
            padding-top: 0.5rem;
          }
          button {
            margin-bottom: 0.2rem;
            margin-left: 0;
            padding: 0;
            &:hover {
              text-decoration: none;
            }
          }
          button.selected {
            font-weight: 600;
          }
          .fa-flag {
            cursor: pointer;
            padding: 5px;
            &.selected {
              border: 1px solid var(--gray-800);
              padding: 4px;
            }
          }
        }
      }
    }
  }
}
