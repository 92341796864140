.sig-testing {
  font-size: 85%;
  margin-top: 0.5rem;
}
.sig-testing.hidden {
  display: block !important;
  height: 0;
  margin: 0;
  visibility: hidden;
}
.sig-testing > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.sig-testing label {
  margin: 0;
}
.sig-testing input[type=number],
.sig-testing select {
  height: 1.75rem;
  margin-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.2rem;
  width: 4rem;
}
.sig-testing input[type=number] {
  padding-left: 0.2rem;
}

