.video-response-icon {
  cursor: pointer;
  margin: auto 0.5rem auto 0;
}
.video-response-icon:hover {
  color: #076ac7;
}
.video-response-icon[disabled] {
  color: #aaaaaa;
  cursor: not-allowed;
}

.participant-video {
  display: block;
  width: 100%;
  min-height: 350px;
}

