.survey-qualification {
  width: 50rem;
  > div {
    display: flex;
    margin-bottom: 1rem;
    > div:first-child {
      width: 3.5rem;
    }
    > div:nth-child(2) {
      flex: 1;
    }
  }
  .email-quill-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--white);
  }
  .email-quill-text {
    min-height: 10rem;
    max-height: 20rem;
    overflow-y: auto;
  }
}
