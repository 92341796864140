@import '../../../styles/styleConstants.scss';

.participant-responses {
  .participant-responses-table {
    border: $border;
    width: 100%;
    thead {
      tr {
        th.qNum-col {
          width: 3rem;
        }
        th.group-col {
          width: 6rem;
        }
        th.title-col {
          width: 12rem;
        }
        th.type-col {
          width: 7rem;
        }
        th.chat-col {
          width: 4rem;
        }
        th.time-col {
          width: 7rem;
        }
      }
    }
    tbody {
      tr {
        &:hover {
          background-color: transparent;
        }
        &.unanswered {
          background-color: $gray-200;
        }
        td {
          border: $border;
        }
        td.qNum-cell {
          font-weight: 600;
        }
        td.group-cell {
          padding-right: 0.25rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 6rem;
        }
        td.title-cell {
          padding-right: 0.25rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 12rem;
        }
        td.content-cell {
          padding: 0.5rem;
          img,
          video,
          .fa-video {
            max-width: 6rem;
            max-height: 4rem;
            margin-right: 0.5rem;
          }
          .question-image-and-prompt {
            display: flex;
            .question-prompt {
              white-space: pre-line;
            }
          }
          .answer-section {
            display: flex;
            font-weight: 600;
            margin-top: 0.5rem;
            > div:first-child {
              margin-right: 2rem;
            }
            ul {
              margin-bottom: 0;
              padding-left: 1rem;
            }
            .choice-image {
              max-width: 6rem;
              max-height: 2rem;
              margin-bottom: 0.2rem;
            }
            .pending-answers {
              font-weight: normal;
            }
          }
        }
        td.chat-cell {
          padding: 0;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }
}
