.choice-question-details {
  .controls-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.2rem;
    button {
      margin: 0 0 0 1rem;
    }
    .export-buttons {
      margin-left: auto;
      .fa-file-excel {
        color: var(--green);
        margin: 0 1rem;
      }
      .fa-file-powerpoint {
        color: var(--red);
        margin: 0 1rem;
      }
    }
  }
  .table.invoke-table {
    font-size: 0.9rem;
    td {
      vertical-align: middle;
    }
  }
  .thumbnail {
    width: 4rem;
  }
  .status-bar {
    background-color: var(--light-blue);
  }
}

.modal-body .choice-question-custom-nets {
  .center {
    margin: 0 auto;
    width: 100%;
  }
  th {
    background-color: var(--gray-300);
    min-width: 2rem;
  }
  tr {
    td:first-child {
      padding-left: 0.3rem;
    }
  }
  .odd-row {
    background-color: #0000000d;
  }
  .nets {
    width: 5rem;
    text-align: center;
  }
  .text-ellipsis {
    max-width: 15rem;
  }
  .thumbnail {
    width: 4rem;
  }
}
