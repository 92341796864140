.project-recruit-collections {
  .body-container {
    height: 100%;
  }
  table {
    width: fit-content;
    td,
    th {
      white-space: nowrap;
    }
  }
}
