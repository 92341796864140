.upload-participant-data-modal .modal-body > label {
  font-weight: 600;
}
.upload-participant-data-modal .modal-body > input {
  margin-bottom: 1rem;
}
.upload-participant-data-modal .modal-body > button {
  margin-bottom: 0.5rem;
}
.upload-participant-data-modal .modal-body .fa,
.upload-participant-data-modal .modal-body .far,
.upload-participant-data-modal .modal-body .fas {
  margin-right: 0.5rem;
}

.import-participant-data-modal .modal-body .selected-columns {
  margin: 0.5rem 0 1rem 0;
}
.import-participant-data-modal .modal-body .selected-columns > div {
  display: flex;
  align-items: center;
}
.import-participant-data-modal .modal-body .selected-columns > div > div:nth-child(2) {
  border: var(--border);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.5rem;
  padding: 0.2rem 0.5rem;
  width: 96%;
}
.import-participant-data-modal .modal-body .selected-columns > div > div:nth-child(2) .fa-times {
  color: var(--gray-700);
  cursor: pointer;
  margin-left: 1rem;
}
.import-participant-data-modal .modal-body .add-column-controls {
  display: flex;
}
.import-participant-data-modal .modal-body .add-column-controls select {
  margin-right: 1rem;
}

