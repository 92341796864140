@import '../../../styles/styleConstants.scss';

.screener-summary {
  min-height: stretch;
  .panel-stats-table {
    width: 100%;
    th,
    td {
      padding-right: 0.5rem;
      text-align: right;
      width: 3.75rem;
    }
    th:first-child,
    td:first-child {
      text-align: left;
      width: auto;
    }
    .totals-row td {
      font-weight: bolder;
    }
  }
  .completed-total {
    border: 1px solid $gray-600;
    background-color: $gray-100;
  }
  .align-baseline {
    align-items: baseline;
  }
  .bolder {
    font-weight: bolder;
  }
  .space-between {
    justify-content: space-between;
  }
  .subtotal {
    background-color: $gray-100;
    padding: 0.5rem 1rem;
    height: stretch;
    .row-content {
      padding: 0 0.5rem;
      border-bottom: 1px solid $gray-500;
      label {
        margin-bottom: 0;
      }
      .counts {
        width: 3rem;
        text-align: end;
      }
    }
  }
}
