.response-clusters .cluster-header {
  display: flex;
  justify-content: space-between;
}
.response-clusters .response-cluster-row {
  height: 120px;
  display: flex;
  margin-bottom: 1rem;
}
.response-clusters .response-cluster-row > div {
  font-size: 80%;
  margin-right: 0.5rem;
}
.response-clusters .response-cluster-row > div:nth-child(2) {
  min-width: 12rem;
  max-width: 12rem;
  padding-left: 0.45rem;
}
.response-clusters .response-cluster-row > div:last-child {
  border: var(--border);
  margin-right: 0;
  padding: 0 0.5rem;
  overflow-y: auto;
}
.response-clusters .response-cluster-row .cluster-filter-label {
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: bottom;
}
.response-clusters .response-cluster-row .cluster-filter-label .nickname {
  display: inline-block;
  margin-left: 0.5rem;
  color: var(--link-color);
  cursor: pointer;
}
.response-clusters .response-cluster-row .selected-row {
  background-color: #daeff7;
}
.response-clusters .response-cluster-row .selected-details {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.response-clusters .response-cluster-row .selected-details .flags-dropdown .fa-flag,
.response-clusters .response-cluster-row .selected-details .flags-dropdown .fa-ban {
  font-size: inherit;
}
.response-clusters .response-cluster-row .selected-details .flags-dropdown > i {
  padding-top: 0;
}
.response-clusters .response-cluster-row .selected-box-height {
  max-height: 120px;
  overflow-y: auto;
  padding-bottom: 0.25rem;
}

