// NOTE: All new variables should be added to cssVariables.scss
// ALSO: All new code should use CSS variables

$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #dddddd;
$gray-400: #cccccc;
$gray-500: #c0c0c0;
$gray-600: #aaaaaa;
$gray-700: #949494;
$gray-800: #545454;
$gray-900: #212529;

$blue: #0569a9;
$border-color: #d7d7d7;
$statusBarBlue: #3b7bdb;
$red: #e91f25;
$black: #000;
$white: #fff;
$rowHoverColor: #daeff7;
$rowSelectedColor: #c9dee6;
$highlight-yellow: #ffff66;

$linkColor: #076ac7;
$disabledColor: $gray-700;

$border: 1px solid $border-color;

// Reactstrap/bootstrap overrides
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1296px
);
$enable-rounded: false;
$grid-columns: 12;
$grid-gutter-width: 8px;
$input-btn-padding-y: 0.25rem;
$input-btn-padding-y-sm: 0.15rem;
$input-btn-padding-y-lg: 0.375rem;
$table-cell-padding: 0.25rem !default;
$table-cell-padding-sm: 0.15rem !default;
