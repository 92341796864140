$logoPreviewHeight: 120px;
$logoPreviewWidth: 300px;

.org-admin {
  .client-settings {
    .max-password-age-input {
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      width: 3rem;
    }
    .archive-input {
      display: inline-block;
      padding: 0;
      border-radius: 4px;
      height: 2rem;
      text-align: center;
      width: 4rem;
    }
    .archive-select {
      display: inline-block;
      text-align: center;
      width: 14rem;
      height: 2rem;
    }
  }
  .client-roles {
    height: 100%;
  }
  .client-users {
    height: 100%;
    .active-users-checkbox {
      margin-bottom: 0.2rem;
      input {
        margin-right: 0.5rem;
      }
    }
  }
}

.logoImgPreview {
  height: $logoPreviewHeight + 20;
  max-width: $logoPreviewWidth + 20;
  padding: 10px;
  img {
    max-height: $logoPreviewHeight;
    max-width: $logoPreviewWidth;
  }
}
