@import '../../../styles/styleConstants.scss';

.filters-selection-menu {
  position: absolute;
  z-index: 9999;
  font-size: 0.85rem;
  .menu {
    background-color: $gray-200;
    border: $border;
    .scrollable-menu-items {
      max-height: 25rem;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    .selectable-menu-item {
      padding: 0.3rem;
      cursor: pointer;
      &:hover {
        background-color: $rowHoverColor;
      }
      &.selected {
        background-color: #397aff;
        color: $white;
      }
      &[disabled] {
        color: $disabledColor;
        cursor: not-allowed;
      }
    }
    .edit-filters {
      color: $linkColor;
      text-align: center;
    }
  }
  .top-menu {
    min-width: 10rem;
  }
}
