.media-stim-edit {
  .row {
    flex-wrap: nowrap;
    align-items: center;
  }
  .editor-left-column {
    display: flex;
    .label {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.media-options {
  label {
    display: block;
    margin-bottom: .5rem;
  }
}