.open-text-options .flex-row {
  line-height: 2;
  padding-left: 1rem;
}
.open-text-options .flex-row .form-check-input {
  margin-top: 0.5rem;
}
.open-text-options .flex-row select[type=select] {
  width: 30%;
  display: inline;
  margin: 0 0.5rem;
}
.open-text-options .flex-row input[type=number] {
  width: 5rem;
  display: inline;
  margin: 0 0.5rem;
}
.open-text-options .flex-row .ant-checkbox-wrapper {
  margin-left: -1rem;
}
.open-text-options .left-half {
  width: 40%;
}
.open-text-options .left-half .ant-select {
  width: 9.5rem;
}
.open-text-options .right-half {
  width: 55%;
}
.open-text-options .radio-group {
  max-width: 9rem;
  display: inline-block;
  padding-top: 0.5rem;
}
.open-text-options .radio-group .radio-label {
  font-size: 1rem;
  color: rgb(0, 0, 0);
  line-height: 2;
}
.open-text-options .shared-voted-group {
  border: var(--border);
  border-radius: 4px;
  padding: 0.5rem;
  background-color: #f0f0e5;
  width: 50%;
  display: inline-block;
}
.open-text-options .shared-voted-group .flex-row {
  justify-content: space-between;
}

