.filter-selector-modal .filter-group {
  margin-bottom: 1rem;
}
.filter-selector-modal .filter-group .group-name {
  font-weight: 600;
}
.filter-selector-modal .filter-group .group-item {
  padding: 0.25rem 0.5rem;
}
.filter-selector-modal .filter-group .group-item label {
  margin: 0;
}

