.survey-settings {
  > div {
    display: flex;
    align-items: baseline;
    margin-bottom: 0.5rem;
    min-height: 2.1rem;
    &.bottom-border {
      border-bottom: var(--border);
    }
    > :first-child {
      margin-right: 1rem;
      min-width: 10rem;
      padding-top: 0.32rem;
      width: 11rem;
    }
    > :nth-child(2) {
      margin-right: 1rem;
    }
  }
  .take-survey-link {
    background-color: var(--gray-200);
    border: var(--border);
    border-radius: 4px;
    padding: 0.25rem 0.75rem;
  }
  .live-survey {
    background-color: #f9f9f9;
    border: var(--border);
    padding: 0 0 0.4rem 0.4rem;
  }
  .exclude-questions {
    align-items: flex-start;
    > div:first-child {
      margin-right: 1rem;
      white-space: nowrap;
      width: auto;
    }
    > div:nth-child(2) {
      flex: 1;
    }
  }
  .direct-to-survey-input {
    max-width: 24em;
    position: relative;
    top: 0.25em;
  }
  .unselected .date-container .ant-calendar-picker {
    border: 2px solid var(--red);
  }
}

.modal .session-cleanup {
  max-width: 40vw;
  input {
    display: inline-block;
  }
}
