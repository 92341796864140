.rd-session-mgmt {
  .question-list {
    tbody {
      tr.selected {
        border: 2px solid #09549a;
      }
    }
    td {
      color: var(--gray-800);
      font-size: 0.9rem;
      &.deploy-status-cell {
        height: 1px; // hack to make deploy-status-cell > div height: 100% to work
        padding: 0;
        > div {
          background-color: var(--gray-300);
          height: 100%;
        }
        .child-joiner {
          width: 50%;
        }
        .deployed {
          background-color: #095499;
        }
        .current {
          background-color: #6fd016;
        }
      }
      &.question-number-cell {
        color: black;
        font-weight: 600;
      }
      &.num-answers-cell {
        padding-right: 0.5rem;
        text-align: right;
      }
      &.percent-cell {
        text-align: right;
      }
      &.media-thumbnail-cell {
        padding-left: 0;
        text-align: center;
        vertical-align: middle;
        .media-thumbnail {
          max-height: 1.33rem;
          max-width: 6.67rem;
          vertical-align: middle;
        }
      }
      .hidden-label {
        margin-right: 0.25rem;
        font-weight: 600;
        color: #ff8e00;
      }
    }
  }
}
