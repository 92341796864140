// This is the same as keywords-header in Keywords.scss
.sentiments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .show-sentiment-chart-link {
    font-size: 0.7rem;
    margin-right: 0.3rem;
    white-space: nowrap;
  }
}
