.screener-response-filters {
  margin: 1rem 1rem 1rem 0;
}
.screener-response-filters .filter-controls {
  display: flex;
  justify-content: space-between;
}
.screener-response-filters .filter-controls .filter-bar {
  margin-right: 1rem;
  width: 87%;
}
.screener-response-filters .filter-controls .filter-bar .filter-label {
  max-width: 97%;
}
.screener-response-filters .filter-controls select {
  width: auto;
}

.screener-responses {
  border-left: var(--border);
  border-right: var(--border);
  margin: 1rem 1rem 1rem 0;
}
.screener-responses .row-head {
  padding: 0 1rem;
  margin-left: 0;
  margin-right: 0;
  width: inherit;
  border-top: var(--border);
  border-bottom: var(--border);
  background-color: var(--gray-300);
  align-items: baseline;
  justify-content: space-between;
}
.screener-responses .row-head label {
  font-weight: bolder;
  margin: 0.2rem 0;
}
.screener-responses .invoke-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 0;
  font-size: 90%;
}
.screener-responses .invoke-table-container th {
  line-height: 1.6;
}
.screener-responses .invoke-table-container .paginator {
  padding-right: 0 !important;
}
.screener-responses .response-table {
  width: inherit;
  border: var(--border);
}
.screener-responses .header {
  background-color: var(--gray-200);
}
.screener-responses .right-border {
  border-right: var(--border);
}
.screener-responses .text-truncate {
  max-width: 50rem;
}
.screener-responses .text-truncate .image-option {
  max-width: 6rem;
  max-height: 2rem;
  margin-bottom: 0.2rem;
}
.screener-responses .response-stats {
  width: 6rem;
  text-align: right;
}

