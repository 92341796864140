@import '../../../../../styles/styleConstants.scss';

.survey-rules {
  .flex-row {
    margin-bottom: 0.5rem;
    margin-left: 0;
    white-space: nowrap;
  }
  .add-rule-selector-row {
    label {
      margin-right: 1rem;
    }
    select {
      width: 15rem;
    }
  }

  .survey-rule {
    border: $border;
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 0.5rem 0.5rem 0 0.5rem;

    .title-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    .all-or-any-selector {
      width: 5rem;
    }

    .segment-category-select,
    .segment-select {
      margin-left: 1rem;
      width: 11rem;
    }

    .function-select {
      margin-bottom: 0.5rem;
      max-width: 24rem;
      min-width: 10rem;
      width: auto;
    }

    .function-param-controls {
      > .row {
        > :first-child {
          margin-right: 1rem;
        }
      }
    }

    .set-variable-top-row {
      select,
      input {
        width: 11rem;
      }
    }

    .set-flag-top-row {
      line-height: 2;
      .flags-dropdown {
        margin-left: 0;
        &.selected {
          top: -15px;
        }
      }
    }

    .conditions {
      .condition-row {
        display: flex;
        .condition {
          display: flex;
          border: $border;
          border-radius: 4px;
          margin: 0 0.5rem 1rem 0;
          padding: 0.2rem 0 0.5rem 0.5rem;
          width: 92%;
          > div {
            margin-right: 1rem;
            > div {
              white-space: nowrap;
            }
          }
          .number-input {
            width: 4rem;
          }
        }
        .choice-checkboxes {
          max-width: 14rem;
          label {
            display: block;
            font-weight: normal;
            margin-bottom: 0.25rem;
            padding-left: 1px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            input {
              margin-right: 0.5rem;
            }
            .fa-flag {
              font-size: 1.5rem;
            }
          }
        }
        .matrix-table {
          border-collapse: collapse;
          td {
            border: $border;
            padding: 4px;
          }
          tr > td:nth-child(n + 2) {
            text-align: center;
          }
        }
      }
    }
  }
}
