.watermark {
  display: flex;
  align-items: center;
}
.watermark > * {
  margin-right: 0.5rem;
}
.watermark select {
  width: auto;
}

