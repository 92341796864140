.prodege-config .prodege-questions .question-row {
  display: flex;
  margin-top: 0.5rem;
}
.prodege-config .prodege-questions .question-row > div:nth-child(2) {
  min-width: 1rem;
  width: 1rem;
}
.prodege-config .prodege-questions .question-row > div:nth-child(2) .fa-caret-down,
.prodege-config .prodege-questions .question-row > div:nth-child(2) .fa-caret-right {
  cursor: pointer;
}
.prodege-config .prodege-questions .question-row > div:nth-child(3),
.prodege-config .prodege-questions .question-row > div:nth-child(4) {
  font-weight: 600;
  margin-right: 1rem;
}
.prodege-config .prodege-questions .question-row > div:nth-child(5) {
  color: var(--gray-500);
  font-size: 90%;
  text-transform: uppercase;
  white-space: nowrap;
  padding-top: 0.1rem;
}
.prodege-config .prodege-questions .question-options-row {
  padding-left: 4rem;
}
.prodege-config .prodege-questions .question-options-row.expandedY ul {
  margin-bottom: 0;
}
.prodege-config .prodege-questions .question-options-row.expandedY ul li {
  margin-top: 0.5rem;
}

