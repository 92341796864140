.rd-filters {
  height: inherit;
  margin-right: 0.5rem;
}
.rd-filters .filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
}
.rd-filters .filters-header > span:first-child {
  font-weight: 600;
}
.rd-filters .filters-header > span:nth-child(2) {
  font-size: 0.7rem;
}
.rd-filters .filters-container {
  border: var(--border);
  font-size: 0.9rem;
  max-height: 95%;
  min-height: 10rem;
  overflow-y: auto;
}
.rd-filters .filters-container .filter-row {
  background-color: var(--white);
}
.rd-filters .filters-container .filter-row .filter {
  padding: 0.1rem 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rd-filters .filters-container .filter-row .selected {
  background-color: var(--highlight-yellow);
}

