.rd-participant-mgmt {
  background-color: inherit;
}
.rd-participant-mgmt .small-font {
  font-size: 0.75rem;
}
.rd-participant-mgmt .filters-and-participants {
  display: grid;
  grid-template-columns: 12rem minmax(50rem, 1fr);
}
.rd-participant-mgmt .filters-and-participants .participants {
  width: auto;
  max-width: fit-content;
}
.rd-participant-mgmt .filters-and-participants .participants .space-between {
  display: flex;
  align-items: center;
}
.rd-participant-mgmt .filters-and-participants .participants .space-between .fas {
  vertical-align: middle;
}
.rd-participant-mgmt .filters-and-participants .participants .search-input {
  margin-top: 1.5rem;
  width: 40%;
}
.rd-participant-mgmt .filters-and-participants .participants .search-input .fa-search {
  width: 1.5rem;
  left: 0.5rem;
  padding-top: 0.125rem;
}
.rd-participant-mgmt .filters-and-participants .participants .search-input input {
  padding-left: 2rem;
}
.rd-participant-mgmt .filters-and-participants .participants .participant-container {
  border: var(--border);
  overflow-x: auto;
}
.rd-participant-mgmt .filters-and-participants .participants .participant-container .table {
  width: inherit;
  font-size: 0.75rem;
}
.rd-participant-mgmt .filters-and-participants .participants .participant-container .flag-cell .flags-dropdown {
  margin: 0 auto;
}
.rd-participant-mgmt .filters-and-participants .participants .participant-container .flag-cell .fas {
  font-size: 0.75rem;
  padding: 0.125rem;
}
.rd-participant-mgmt .filters-and-participants .participants .participant-container .pagination {
  font-size: 0.8rem;
}

