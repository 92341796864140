.joiner-editor-timing {
  .flex-row {
    line-height: 2;
    > div:first-child {
      white-space: nowrap;
      width: 16rem;
    }
  }
  .ant-time-picker {
    width: 4.8rem;
  }
  .ant-time-picker-input {
    padding-left: 0.0225rem;
    margin-left: -0.08745rem;
    line-height: 2;
    font-size: 1rem;
  }
}
