.survey-display-prefs {
  table {
    width: 100%;
    thead > tr {
      :first-child {
        width: 20%;
      }
      :nth-child(2) {
        width: 10%;
      }
    }
    tbody:before {
      line-height: 2em;
      content: '\200C';
      display: block;
    }
    .save-and-reset-buttons {
      display: flex;
      button {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 1rem;
      }
    }
    .input {
      padding: 0.2rem 0.4rem;
      height: 1.6rem;
    }
    .select {
      padding: 0.1rem 0.4rem;
      height: 1.6rem;
      border: 1px solid var(--gray-300);
      border-radius: 3px;
    }
    .config-name {
      text-overflow: ellipsis;
      color: rgb(7, 106, 199);
      white-space: nowrap;
      max-width: 10rem;
      overflow: hidden;
    }
  }
  button {
    margin-top: 0;
    padding: 0 0.4rem;
  }
}

.modal-body {
  .survey-display-prefs {
    input {
      border: 1px solid var(--gray-300);
      border-radius: 3px;
    }
    .option {
      width: 10rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 10rem;
      overflow: hidden;
    }
  }
}
