.active-projects .search-input {
  display: inline-block;
}
.active-projects .selected {
  border: 1px solid var(--blue);
  padding: 0.5rem;
}
.active-projects .unselected {
  border: 1px solid transparent;
  padding: 0.5rem;
}
.active-projects .project-card {
  border: var(--border);
  padding: 0.5rem;
  background: var(--white);
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  width: 300px;
  height: 200px;
}
.active-projects .project-card .users-media {
  display: flex;
}
.active-projects .project-card .users-media .media-thumbnail {
  max-width: 120px;
  max-height: 80px;
}
.active-projects .project-card.active {
  border: 1px solid var(--blue);
  box-shadow: 0 3px 5px;
}

