.matrix-question-responses {
  font-size: 0.8rem;
}
.matrix-question-responses .controls-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.matrix-question-responses .controls-row > * {
  margin-left: 1rem;
}
.matrix-question-responses .controls-row .export-buttons {
  font-size: 1rem;
  margin-left: auto;
}
.matrix-question-responses .controls-row .export-buttons .fa-file-excel {
  color: var(--green);
  margin: 0 1rem;
}
.matrix-question-responses .controls-row .export-buttons .fa-file-powerpoint {
  color: var(--red);
  margin: 0 1rem;
}
.matrix-question-responses .table-container {
  overflow-x: scroll;
}
.matrix-question-responses .table-container table {
  table-layout: auto;
}
.matrix-question-responses .table-container table thead tr th:first-child {
  min-width: 10rem;
  max-width: 30rem;
}
.matrix-question-responses .table-container table thead tr th.bipolar:nth-last-child(2) {
  background-color: var(--gray-100);
  min-width: 10rem;
  max-width: 30rem;
}
.matrix-question-responses .table-container table thead tr th {
  width: 6rem;
  padding-bottom: 0;
  padding-left: 0.5rem;
}
.matrix-question-responses .table-container table thead tr th .matrix-details-column-header {
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;
  white-space: nowrap;
  width: 100%;
  margin: 0.5rem 0;
}
.matrix-question-responses .table-container table thead tr th .matrix-details-column-header > span:nth-child(2) {
  display: inline-block;
  max-width: 10rem;
  overflow: hidden;
  padding-right: 0.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.matrix-question-responses .table-container table thead tr th .matrix-details-column-header .text-rotate {
  vertical-align: bottom;
  text-align: left;
  line-height: 1;
  width: 75px;
  transform-origin: bottom left;
  transform: rotate(-20deg) translate(0px, 60px);
}
.matrix-question-responses .table-container table tbody tr {
  background-color: transparent !important;
}
.matrix-question-responses .table-container table tbody td:first-child {
  border: none !important;
  padding-right: 1rem;
  text-align: left;
  white-space: normal;
  padding-left: 1rem;
  text-indent: -1rem;
}
.matrix-question-responses .table-container table tbody td:first-child .thumbnail {
  text-align: center;
  font-size: 0.8rem;
}
.matrix-question-responses .table-container table tbody td:first-child .thumbnail img {
  display: block;
  max-width: 4rem;
  margin: 0 auto;
}
.matrix-question-responses .table-container table tbody td.bipolar:nth-last-child(2) {
  border: none !important;
  padding-right: 1rem;
  text-align: left;
  white-space: normal;
  padding-left: 1rem;
}
.matrix-question-responses .table-container table tbody td {
  line-height: 1.5rem;
  max-width: 10rem;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}
.matrix-question-responses .table-container table tbody td .net-top,
.matrix-question-responses .table-container table tbody td .net-middle,
.matrix-question-responses .table-container table tbody td .net-bottom,
.matrix-question-responses .table-container table tbody td .response-rate-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  margin: 0.25rem 0.5rem;
  font-weight: 600;
}
.matrix-question-responses .table-container .one-net,
.matrix-question-responses .table-container .two-nets {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  line-height: 1.5rem;
  height: 1.5rem;
}
.matrix-question-responses .table-container .one-net {
  width: 128px;
}
.matrix-question-responses .table-container .two-nets {
  width: 280px;
}
.matrix-question-responses .table-container .three-nets {
  line-height: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  display: flex;
  font-weight: 600;
}
.matrix-question-responses .table-container .three-nets .middle-net {
  display: inline-block;
  vertical-align: top;
  line-height: 1.5rem;
}

