html {
  min-width: var(--min-app-width);
}
html body {
  margin: 0;
  padding: 0;
}
html body > div#root {
  height: 100%;
}
html body > div#root > .app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}
html body > div#root > .app > .container {
  background-color: var(--app-body-bg-color);
  height: 100%;
  max-width: 100%;
  padding: 0;
}
html body > div#root > .app > .container .app-body {
  display: grid;
  grid-template-columns: 7rem minmax(50rem, 1fr);
  grid-template-rows: 100%;
  height: 100%;
}
html body > div#root > .app > .container .app-body .body-container {
  padding: 0.5rem;
  height: 100%;
}

