.project-survey-left-nav .active-session {
  cursor: default;
  font-weight: 600;
}

.fa-arrow-alt-circle-left {
  color: var(--black);
}

.validation-warning {
  position: absolute;
  top: 1.4rem;
  right: 0.6rem;
}

.unread-banner {
  font-size: 0.6rem;
  color: #fff;
  background-color: var(--red);
  border-radius: 50%;
  min-width: 1rem;
  max-width: 2rem;
  min-height: 1rem;
  padding: 0.1rem;
  text-align: center;
  transform: translateY(-0.4rem);
  position: absolute;
  top: 0.2rem;
  right: 1.8rem;
}

