.uploadLogoModal .modal-content {
  width: 40rem;
}
.uploadLogoModal .instructionsHeader {
  font-weight: 600;
}
.uploadLogoModal .selectImgButton {
  margin: 1rem 1rem 1rem 0;
}
.uploadLogoModal .previewContainer {
  border: var(--border);
  margin: 1rem 0;
}
.uploadLogoModal .previewContainer > div:first-child {
  border-bottom: var(--border);
  padding: 1rem;
}
.uploadLogoModal .buttonsContainer {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0;
}
.uploadLogoModal .buttonsContainer > button {
  margin-left: 2rem;
}

