.add-to-survey-modal-container {
  padding: 0 0.5rem;
  .joiner-icon {
    cursor: pointer;
    margin: auto;
    width: 6rem;
    height: 4rem;
    .vfg-icon {
      height: 100%;
    }
  }
  .joiner-media-icon {
    height: 2.3rem;
    margin: 1rem 0.5rem 0.6rem 0.5rem;
    width: 5rem;
  }
  .flex-row {
    flex-wrap: nowrap;
    column-gap: 1rem;
    margin: 0;
  }
  .border.rounded.border-secondary {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}
