.screener-participants {
  .right-panel {
    max-width: 75%;
  }
  table.invoke-table {
    display: block;
    white-space: nowrap;
    width: inherit;
    overflow: auto;
    padding: 0;
  }
}
