.date-container {
  > label:first-child {
    margin: auto;
    width: 3rem;
  }
  .est-label {
    margin: auto 1rem;
    color: var(--gray-700);
  }
}
