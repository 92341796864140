.modal-dialog.panel-config-modal {
  max-width: 60rem;
}

.edit-panel-config > div {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5rem;
}
.edit-panel-config .link-param-ui {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.edit-panel-config .link-param-ui > * {
  font-size: 90%;
  margin-right: 0.5rem;
}
.edit-panel-config .link-param-ui input {
  width: 6rem;
}
.edit-panel-config .link-param-ui select {
  width: auto;
}
.edit-panel-config .link-param-ui .add-remove-icons {
  font-size: initial;
}
.edit-panel-config .url-input-row > label {
  width: 10rem;
}
.edit-panel-config .url-input-row > :nth-child(2) {
  width: 35rem;
}
.edit-panel-config .url-input-row .url-example {
  color: var(--gray-800);
  font-size: 90%;
  margin: 0.5rem;
}
.edit-panel-config .add-link-param-link {
  color: var(--link-color);
  cursor: pointer;
  margin-left: 1rem;
  white-space: nowrap;
}

