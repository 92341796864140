.rd-participant-mgmt {
  background-color: inherit;
  .small-font {
    font-size: 0.75rem;
  }
  .filters-and-participants {
    display: grid;
    grid-template-columns: 12rem minmax(50rem, 1fr);

    .participants {
      width: auto;
      max-width: fit-content;

      .space-between {
        display: flex;
        align-items: center;
        .fas {
          vertical-align: middle;
        }
      }
      .search-input {
        margin-top: 1.5rem;
        width: 40%;
        .fa-search {
          width: 1.5rem;
          left: 0.5rem;
          padding-top: 0.125rem;
        }
        input {
          padding-left: 2rem;
        }
      }
      .participant-container {
        border: var(--border);
        overflow-x: auto;
        .table {
          width: inherit;
          font-size: 0.75rem;
        }
        .flag-cell {
          .flags-dropdown {
            margin: 0 auto;
          }
          .fas {
            font-size: 0.75rem;
            padding: 0.125rem;
          }
        }
        .pagination {
          font-size: 0.8rem;
        }
      }
    }
  }
}
