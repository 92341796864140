.sentiments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sentiments-header .show-sentiment-chart-link {
  font-size: 0.7rem;
  margin-right: 0.3rem;
  white-space: nowrap;
}

