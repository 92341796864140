.modal-dialog.participant-enrollment-select-modal {
  max-width: 90%;
  .participant-enrollment-selection {
    display: flex;
    .left {
      border-right: var(--border);
      padding-right: 1.5rem;
      label {
        display: block;
        margin-bottom: .5rem;
        white-space: nowrap;
      }
    }
    .right {
      margin-left: 1.5rem;
      overflow-x: auto;
      width: 100%;
      .invoke-table-container table {
        table-layout: auto;
        white-space: nowrap;
      }
    }
  }
}
