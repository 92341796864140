.survey-qualification {
  width: 50rem;
}
.survey-qualification > div {
  display: flex;
  margin-bottom: 1rem;
}
.survey-qualification > div > div:first-child {
  width: 3.5rem;
}
.survey-qualification > div > div:nth-child(2) {
  flex: 1;
}
.survey-qualification .email-quill-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--white);
}
.survey-qualification .email-quill-text {
  min-height: 10rem;
  max-height: 20rem;
  overflow-y: auto;
}

