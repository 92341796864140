.modal-dialog.add-or-edit-media-modal {
  max-width: 50rem;
}
.modal-dialog.add-or-edit-media-modal .label {
  font-weight: 600;
}
.modal-dialog.add-or-edit-media-modal .row {
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
}
.modal-dialog.add-or-edit-media-modal .row > div:first-child {
  width: 10rem;
}
.modal-dialog.add-or-edit-media-modal .row > div:nth-child(2) {
  width: 100%;
}
.modal-dialog.add-or-edit-media-modal .preview-row {
  align-items: initial;
}
.modal-dialog.add-or-edit-media-modal .preview-row > div:nth-child(2) .webcontent-preview {
  height: 15rem;
}
.modal-dialog.add-or-edit-media-modal .preview-row > div:nth-child(2) .webcontent-preview iframe {
  border: var(--border);
  height: 200%;
  width: 200%;
  transform: scale(0.5);
  transform-origin: 0 0;
}

