.survey-table-container {
  .selected {
    border: 1px solid var(--blue);
    padding: 0.5rem;
  }
  .unselected {
    padding: 0.5rem;
    border: 1px solid transparent;
  }
}
