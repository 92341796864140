.flags-dropdown {
  margin-left: -0.75rem;
  background-color: var(--white);
  z-index: 2;
  width: fit-content;
}
.flags-dropdown .fa-flag,
.flags-dropdown .fa-ban {
  font-size: 1.5rem;
  margin-right: 1.5rem;
}
.flags-dropdown .fa-flag,
.flags-dropdown .fa-ban {
  padding: 0.125rem 0.5rem;
  margin-right: 0;
  cursor: pointer;
}
.flags-dropdown .fa-ban {
  color: #8b0000;
}
.flags-dropdown .fa-flag {
  display: block;
}
.flags-dropdown .colored-flags {
  visibility: hidden;
}

.all-flags {
  border: var(--border);
}
.all-flags .colored-flags {
  visibility: inherit;
  background-color: var(--white);
  display: flex;
  flex-flow: column;
}
.all-flags .colored-flags .fa-flag:hover,
.all-flags .colored-flags .fa-ban:hover {
  background-color: #c3daea;
}

.flags-dropdown.selected {
  position: absolute;
}

.flags-dropdown.unselected {
  background-color: inherit;
}

