.response-clusters {
  .cluster-header {
    display: flex;
    justify-content: space-between;
  }
  .response-cluster-row {
    height: 120px;
    display: flex;
    margin-bottom: 1rem;
    > div {
      font-size: 80%;
      margin-right: 0.5rem;
    }
    > div:nth-child(2) {
      min-width: 12rem;
      max-width: 12rem;
      padding-left: 0.45rem;
    }
    > div:last-child {
      border: var(--border);
      margin-right: 0;
      padding: 0 0.5rem;
      overflow-y: auto;
    }
    .cluster-filter-label {
      text-overflow: ellipsis;
      max-width: 80%;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: bottom;
      .nickname {
        display: inline-block;
        margin-left: 0.5rem;
        color: var(--link-color);
        cursor: pointer;
      }
    }
    .selected-row {
      background-color: #daeff7;
    }
    .selected-details {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .flags-dropdown {
        .fa-flag,
        .fa-ban {
          font-size: inherit;
        }
        > i {
          padding-top: 0;
        }
      }
    }
    .selected-box-height {
      max-height: 120px;
      overflow-y: auto;
      padding-bottom: 0.25rem;
    }
  }
}
