.report-config-norms-modal {
  width: 700px !important;
}
.report-config-norms-modal h6 {
  font-weight: bolder;
}
.report-config-norms-modal .top-section {
  padding: 0 1rem 0.5rem 0.5rem;
  border-bottom: 1px solid #eeeeee;
}
.report-config-norms-modal .main-section {
  padding: 0.5rem 0.5rem 0 0.5rem;
  height: 400px;
  overflow-y: auto;
}
.report-config-norms-modal .main-section .row-answer-data {
  display: flex;
  justify-content: space-between;
  margin: 0.25rem 0;
  padding-left: 0.5rem;
}
.report-config-norms-modal .main-section .row-answer-data input {
  height: 80%;
  width: 4rem;
}

