.data-filters {
  width: 100%;
  .filter-bar {
    background-color: var(--white);
    border: var(--border);
    display: flex;
    align-items: flex-start;
    height: auto;
    min-height: 2rem;
    padding: 0.2rem;
    .fa-filter {
      cursor: pointer;
      margin: 0.25rem 0.4rem 0 0;
    }
    .filter-labels {
      display: flex;
      flex-wrap: wrap;
      max-width: 80%;
    }
    .filtered-result {
      font-size: 0.8rem;
      margin-top: 0.1rem;
      margin-left: auto;
    }
  }
}
