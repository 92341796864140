.rd-question-details {
  .open-question-details {
    display: flex;
    .left {
      margin-right: 0.3rem;
      width: 20%;
      h6 {
        font-weight: 600;
        padding: 0.25rem 0 0 0.5rem;
      }
      .sentiments,
      .keywords {
        border: var(--border);
        font-size: 0.9rem;
        width: 100%;
        > div {
          display: flex;
          justify-content: space-between;
        }
        > div:nth-of-type(odd) {
          padding: 0 0.25rem;
        }
        > div:nth-of-type(even) {
          height: 6px;
        }
        .clickable.selected {
          background-color: var(--highlight-yellow);
        }
      }
      .whitelist-link {
        font-size: 0.6rem;
      }
    }
    .right {
      width: 80%;
      .question-details {
        border: var(--border);
        overflow: scroll;
        width: 100%;
        table {
          table-layout: auto;
          white-space: nowrap;
          width: inherit;
          thead {
            tr {
              th {
                font-size: 0.8rem;
                font-weight: 600;
              }
            }
          }
          td {
            font-size: 0.85rem;
            &.response-cell {
              padding-left: 0.25rem;
              white-space: normal;
              > div:first-child {
                > span {
                  color: var(--blue);
                  cursor: pointer;
                  font-size: 0.7rem;
                }
              }
              .fa-copy {
                display: none;
                float: right;
                font-size: 1rem;
              }
              &:hover {
                .fa-copy {
                  display: inline-block;
                }
              }
            }
            &.favorite-cell,
            &.chat-cell {
              font-size: 1rem;
              padding: 0.25rem;
              text-align: center;
            }
            &.flag-cell {
              .flags-dropdown {
                margin: 0 auto;
              }
              .fas {
                font-size: 0.75rem;
                padding: 0.125rem;
              }
            }
            &.word-count-cell {
              padding-right: 1.5rem;
              text-align: right;
            }
            &.text-rank-cell {
              padding-right: 1.5rem;
              text-align: right;
            }
          }
        }
        .pagination {
          font-size: 0.8rem;
        }
      }
      .search-bar {
        .fa-search {
          cursor: pointer;
          margin-right: 0.3rem;
          position: absolute;
          top: 0.375rem;
          left: 0.25rem;
        }
        height: 1.75rem;
        width: 100%;
        display: inline-flex;
        margin-bottom: 0.3rem;
        position: relative;
      }
      .search-bar-input {
        height: 100%;
        padding-left: 1.3rem;
      }
      .sentiment-summary-container,
      .wordcloud-container {
        border: var(--border);
        margin-bottom: 1rem;
        position: relative;
        width: fit-content;
        .fa-times-circle {
          position: absolute;
          top: 0.2rem;
          right: 0.2rem;
        }
      }
      .pre-table-row {
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
      }
    }
  }
  .view-icons {
    display: flex;
    > div {
      display: inline-flex;
      width: 2rem;
      height: 2rem;
      justify-content: center;
      align-items: center;
    }
    .icon {
      font-size: 80%;
    }
  }
  .selected {
    border: 1px solid blue;
  }
}
