.concept-rotation-media label {
  display: block;
}
.concept-rotation-media .row {
  flex-wrap: nowrap;
  align-items: center;
}
.concept-rotation-media .add-input {
  color: #4fc328;
  padding: 0 1rem;
}
.concept-rotation-media .remove-input {
  color: #bf2828;
  padding: 0 1rem;
}
.concept-rotation-media .text-only-media {
  max-width: 75%;
  padding: 0.25rem 0.5rem;
  border: 1px solid #d7d7d7;
  border-radius: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.concept-rotation-media .row .editor-right-column .add-input,
.concept-rotation-media .editor-right-column .row .remove-input,
.concept-rotation-media .editor-right-column .row .text-only-media:hover {
  cursor: pointer;
}
.concept-rotation-media .editor-right-column .flex-row .small-preview {
  border: 1px solid #d7d7d7;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #fff;
}
.concept-rotation-media .editor-right-column .flex-row .small-preview img,
.concept-rotation-media .editor-right-column .flex-row .small-preview video {
  max-height: 2.5rem;
}

