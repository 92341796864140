.participant-metadata {
  margin-bottom: 1rem;
}
.participant-metadata .participant-metadata-container {
  border: 1px solid #d7d7d7;
  margin-top: 0.5rem;
  padding: 0.5rem;
  width: 100%;
}
.participant-metadata .participant-metadata-container > div:first-child {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 50%;
}
.participant-metadata .participant-metadata-container > div:first-child > div > span:first-child {
  margin-right: 2rem;
}
.participant-metadata .participant-metadata-container .show-all-link {
  color: #076ac7;
  cursor: pointer;
  font-size: 0.7rem;
}
.participant-metadata .participant-metadata-container .label-value-list {
  list-style: none;
  margin-top: 1rem;
  padding: 0;
}
.participant-metadata .participant-metadata-container .label-value-list li {
  margin-bottom: 0.5rem;
}
.participant-metadata .participant-metadata-container .label-value-list li .item-label {
  color: #545454;
}
.participant-metadata .participant-metadata-container .label-value-list li .item-value {
  color: #000;
  font-weight: 500;
}
.participant-metadata .participant-metadata-container .metadata-box-list-column-3 {
  column-count: 3;
}

