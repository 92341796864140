.chart-report,
.crosstab-report,
.raw-data-report {
  .config-area {
    display: flex;
    .config-column {
      margin-right: 0.5rem;
      width: auto;
      > div {
        border: var(--border);
        margin-bottom: 0.5rem;
        padding: 0.25rem 0.5rem;
        > div:first-child {
          font-weight: 600;
        }
        > div,
        > label {
          display: block;
          margin: 0.25rem 0 0 0;
          white-space: nowrap;
        }
        label {
          margin-bottom: 0;
        }
      }
      > div:last-child {
        margin-bottom: 0;
      }
      .configure-button {
        background-color: unset !important;
        margin: 0 0 0 2rem;
        padding: 0;
        line-height: 1;
      }
      .subCheck {
        margin-left: 1rem;
      }
    }
  }
}
