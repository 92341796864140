.screener-response-filters {
  margin: 1rem 1rem 1rem 0;
  .filter-controls {
    display: flex;
    justify-content: space-between;
    .filter-bar {
      margin-right: 1rem;
      width: 87%;
      .filter-label {
        max-width: 97%;
      }
    }
    select {
      width: auto;
    }
  }
}

.screener-responses {
  border-left: var(--border);
  border-right: var(--border);
  margin: 1rem 1rem 1rem 0;
  .row-head {
    padding: 0 1rem;
    margin-left: 0;
    margin-right: 0;
    width: inherit;
    border-top: var(--border);
    border-bottom: var(--border);
    background-color: var(--gray-300);
    align-items: baseline;
    justify-content: space-between;
    label {
      font-weight: bolder;
      margin: 0.2rem 0;
    }
  }
  .invoke-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0;
    font-size: 90%;
    th {
      line-height: 1.6;
    }
    .paginator {
      padding-right: 0 !important;
    }
  }
  .response-table {
    width: inherit;
    border: var(--border);
  }
  .header {
    background-color: var(--gray-200);
  }
  .right-border {
    border-right: var(--border);
  }
  .text-truncate {
    max-width: 50rem;
    .image-option {
      max-width: 6rem;
      max-height: 2rem;
      margin-bottom: 0.2rem;
    }
  }
  .response-stats {
    width: 6rem;
    text-align: right;
  }
}
