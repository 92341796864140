html {
  min-width: var(--min-app-width);
  body {
    margin: 0;
    padding: 0;
    > div#root {
      height: 100%;
      > .app {
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100vh;
        > .container {
          background-color: var(--app-body-bg-color);
          height: 100%;
          max-width: 100%;
          padding: 0;
          .app-body {
            display: grid;
            grid-template-columns: 7rem minmax(50rem, 1fr);
            grid-template-rows: 100%;
            height: 100%;
            .body-container {
              padding: 0.5rem;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
