.chart-report .config-area,
.crosstab-report .config-area,
.raw-data-report .config-area {
  display: flex;
}
.chart-report .config-area .config-column,
.crosstab-report .config-area .config-column,
.raw-data-report .config-area .config-column {
  margin-right: 0.5rem;
  width: auto;
}
.chart-report .config-area .config-column > div,
.crosstab-report .config-area .config-column > div,
.raw-data-report .config-area .config-column > div {
  border: var(--border);
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
}
.chart-report .config-area .config-column > div > div:first-child,
.crosstab-report .config-area .config-column > div > div:first-child,
.raw-data-report .config-area .config-column > div > div:first-child {
  font-weight: 600;
}
.chart-report .config-area .config-column > div > div,
.chart-report .config-area .config-column > div > label,
.crosstab-report .config-area .config-column > div > div,
.crosstab-report .config-area .config-column > div > label,
.raw-data-report .config-area .config-column > div > div,
.raw-data-report .config-area .config-column > div > label {
  display: block;
  margin: 0.25rem 0 0 0;
  white-space: nowrap;
}
.chart-report .config-area .config-column > div label,
.crosstab-report .config-area .config-column > div label,
.raw-data-report .config-area .config-column > div label {
  margin-bottom: 0;
}
.chart-report .config-area .config-column > div:last-child,
.crosstab-report .config-area .config-column > div:last-child,
.raw-data-report .config-area .config-column > div:last-child {
  margin-bottom: 0;
}
.chart-report .config-area .config-column .configure-button,
.crosstab-report .config-area .config-column .configure-button,
.raw-data-report .config-area .config-column .configure-button {
  background-color: unset !important;
  margin: 0 0 0 2rem;
  padding: 0;
  line-height: 1;
}
.chart-report .config-area .config-column .subCheck,
.crosstab-report .config-area .config-column .subCheck,
.raw-data-report .config-area .config-column .subCheck {
  margin-left: 1rem;
}

