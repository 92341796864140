.concept-rotation-edit > div {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}
.concept-rotation-edit .title-input {
  width: 16rem;
}
.concept-rotation-edit .concept-control {
  display: flex;
  align-items: center;
  line-height: 2.5;
  margin-bottom: 0.5rem;
}
.concept-rotation-edit .concept-control > :first-child {
  border: 1px solid #d7d7d7;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  padding-left: 0.5rem;
  width: 14rem;
}
.concept-rotation-edit .concept-control > :first-child[disabled] {
  cursor: default;
}
.concept-rotation-edit .concept-control > :nth-child(2) {
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 0 4px 4px 0;
  border-left: none;
  cursor: pointer;
  margin-right: 0.75rem;
  width: 3rem;
  text-align: center;
}
.concept-rotation-edit .concept-control > :nth-child(2)[disabled] {
  cursor: default;
}
.concept-rotation-edit .concept-control > :nth-child(2) .fa-image {
  color: #aaaaaa;
  font-size: 1.2rem;
}
.concept-rotation-edit .concept-control > :nth-child(2) img,
.concept-rotation-edit .concept-control > :nth-child(2) video {
  height: 100%;
  max-height: 2rem;
  max-width: 3rem;
  vertical-align: middle;
}
.concept-rotation-edit .stim-text-translation {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 0.5rem;
}

