.sentiment-summary-container {
  padding: 0.25rem;
  .sentiment-summary-body {
    display: flex;
    padding: 0.5rem 2rem;
    .sentiment-summary-left {
      margin-right: 2rem;
    }
    .sentiment-summary-right {
      width: 100%;
    }
  }
  .sentiment-chart-legend {
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    > div {
      display: flex;
      align-items: center;
      margin-right: 0.6rem;
      > div:first-child {
        border: 1px solid #999;
        height: 0.5rem;
        width: 0.5rem;
        margin-right: 0.2rem;
      }
    }
  }
}
