.project-details-ss {
  padding-left: 2rem;
}
.project-details-ss .project-name-and-status {
  margin-bottom: 2rem;
}
.project-details-ss .project-name-and-status > div {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5rem;
}
.project-details-ss .project-name-and-status > div > div:first-child {
  font-weight: 600;
  width: 9rem;
}
.project-details-ss .project-name-and-status > div .save-button {
  border: 0 !important;
  margin-left: 1rem;
}
.project-details-ss .project-name-and-status > div .status-box {
  border: 1px solid var(--gray-500);
  border-radius: 6px;
  color: var(--gray-800);
  font-weight: 600;
  height: 1.7rem;
  text-align: center;
  width: 9rem;
}
.project-details-ss .project-name-and-status input {
  width: 25rem;
}
.project-details-ss .self-serve-step {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.project-details-ss .self-serve-step .fa-check.ok {
  color: #6fd016;
}
.project-details-ss .self-serve-step .fa-check.tbd,
.project-details-ss .self-serve-step .fa-lock {
  color: var(--gray-700);
}
.project-details-ss .self-serve-step > *:first-child {
  font-size: 2rem;
  width: 32px;
}
.project-details-ss .self-serve-step > *:nth-child(2) {
  font-size: 4rem;
}
.project-details-ss .self-serve-step .step-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 3.5rem;
}
.project-details-ss .self-serve-step .step-desc > div:first-child {
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.project-details-ss .self-serve-step .step-desc > div:nth-child(2) {
  color: var(--gray-800);
  display: flex;
  align-items: baseline;
  font-size: 90%;
}
.project-details-ss .self-serve-step .step-desc > div:nth-child(2) a:hover {
  text-decoration: none;
}
.project-details-ss .self-serve-step .step-desc > div:nth-child(2) button.btn {
  font-size: inherit;
  line-height: 1;
  margin: 0;
}
.project-details-ss .align-right {
  text-align: right;
}

