@import '../../../../styles/styleConstants.scss';

.modal-dialog.advance-filter-editor {
  max-width: 1100px;
  .modal-content {
    width: auto;
    .hint {
      padding: 0 0 0.5rem 1rem;
      font-size: '0.75rem';
      color: $gray-500;
    }
    .dnd-area {
      display: flex;
      align-items: center;
      width: 100%;
      height: 6rem;
      padding: 0.75rem;
      border: 1px solid $gray-300;
      overflow-x: auto;
    }
    .filter-parts {
      display: inline-block;
      border: 1px solid $gray-300;
      margin: 0.5rem 0.5rem 0.5rem 0;
      height: inherit;
      .filter-eraser {
        font-size: 0.75rem;
        padding: 0 0.5rem;
        width: 100%;
        height: 0.75rem;
      }
      .data-point {
        max-width: 10.5rem;
        white-space: nowrap;
        padding: 0.25rem;
        height: 3rem;
        .field,
        .value {
          max-width: 7rem;
          min-width: 4rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .field {
          color: $gray-500;
        }
      }
      .parts-label {
        font-weight: 600;
        margin: 0 0.5rem;
        height: 3rem;
        display: table-cell;
        vertical-align: middle;
        padding: 0.25rem;
      }
      .filter-eraser:hover {
        cursor: pointer;
      }
    }
    .uppercase {
      text-transform: uppercase;
    }
  }
}
