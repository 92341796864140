.rd-status-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2;
  max-height: 2.5rem;
  padding: 0.25rem 0;
  width: 100%;
}
.rd-status-bar * {
  font-size: 90% !important;
}

