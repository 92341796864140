.rd-question-details-header .question-nav {
  display: flex;
  align-items: center;
}
.rd-question-details-header .question-nav .question-nav-select {
  margin: 0 1rem;
  min-width: 15rem;
}
.rd-question-details-header .question-nav .link {
  font-size: 0.9rem;
}

