.media-library {
  width: inherit;
  min-width: 42.5rem;
  .filters {
    display: flex;
    margin-bottom: 0.5rem;

    .search-input {
      margin-right: 1rem;
    }

    .react-select__control {
      width: 18rem;
      margin-right: 1rem;
      height: calc(2rem + 2px);
      min-height: calc(2rem + 2px);
    }
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.5rem;
    .buttons {
      button {
        margin: 0 0.5rem 0 0;
      }
    }
    .view-icons {
      > i {
        border: 1px solid transparent;
        border-radius: 10%;
        cursor: pointer;
        margin-left: 0.25rem;
        padding: 0.25rem;
        &.selected {
          border-color: var(--link-color);
          cursor: default;
        }
      }
    }
  }

  .media-grid {
    width: max-content;
    > table {
      background-color: var(--white);
      td {
        padding: 0.3rem;
        > div {
          border: var(--border);
          cursor: pointer;
          width: 160px;
          max-width: 160px;
          &.disabled {
            pointer-events: auto !important;
          }
          &.selected {
            background-color: var(--row-selected-color);
          }
          > div:first-child {
            height: 6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            > * {
              max-height: 5.5rem;
              max-width: 9rem;
            }
          }
          > div:nth-child(2) {
            border-top: var(--border);
            font-size: 90%;
            height: 2rem;
            padding: 0.25rem 1rem;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.media-tooltip .tooltip-inner {
  max-width: none;
  text-align: left;
}
.uploading-filename-label {
  margin-bottom: 1rem;
}
.progress-label {
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
}
