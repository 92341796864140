.choice-options label {
  display: block;
  margin-bottom: 0.5rem;
}
.choice-options .choice-types {
  margin-left: 0.5rem;
}
.choice-options .select-option-rows {
  align-items: baseline;
}
.choice-options .select-options {
  width: 21%;
}
.choice-options .select-option-input {
  width: 9%;
}
.choice-options .select-option-input-min {
  width: 9%;
}
.choice-options .select-option-input-max {
  width: 12%;
}

.choice-editor {
  white-space: nowrap;
}
.choice-editor > .mb-2 {
  display: flex;
  flex-wrap: nowrap;
}
.choice-editor > .mb-2 > .editor-right-column {
  width: 90%;
}
.choice-editor > .mb-2 > .editor-right-column .add-remove-icons {
  margin-left: 1rem;
}
.choice-editor > .mb-2 .choice-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.25rem;
}
.choice-editor > .mb-2 .choice-row > :first-child {
  width: 28rem;
}
.choice-editor > .mb-2 .choice-row > :nth-child(2) {
  width: auto;
}
.choice-editor > .mb-2 .choice-row > :nth-child(3) {
  width: 6rem;
}
.choice-editor > .mb-2 .choice-row > :nth-child(4) {
  padding: 0.25rem 0.5rem;
  width: 4rem;
}
.choice-editor > .mb-2 .other-choice-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.25rem;
}
.choice-editor > .mb-2 .other-choice-row > :first-child {
  width: 25rem;
}
.choice-editor > .mb-2 .other-input-select {
  width: 25rem;
}
.choice-editor > .mb-2 .media-input {
  text-align: center;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  padding: 0 3px;
  background-color: #fff;
  line-height: initial;
}
.choice-editor > .mb-2 .media-input .fa-image {
  color: #545454;
}
.choice-editor > .mb-2 .add-text {
  font-size: 1rem;
  font-weight: 600;
  color: #4fc328;
  cursor: pointer;
}
.choice-editor .label-text {
  font-size: 75%;
  margin-left: 0.5%;
}
.choice-editor .image,
.choice-editor .img {
  width: 2.5rem;
  height: 2rem;
}
.choice-editor .image:hover {
  cursor: pointer;
}

