.rd-question-details-header {
  margin-bottom: 0.5rem;
}
.rd-question-details-header .flex-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.rd-question-details-header .flex-row .fa-arrows-alt-h {
  cursor: pointer;
  margin-top: 0.25rem;
}
.rd-question-details-header .flex-row .joiner-icon {
  max-height: 3rem;
  max-width: 6rem;
}
.rd-question-details-header .flex-row .joiner-icon img,
.rd-question-details-header .flex-row .joiner-icon video {
  max-height: inherit;
  max-width: inherit;
}
.rd-question-details-header .flex-row .question-prompt {
  background-color: transparent;
  height: 3rem;
  overflow: hidden;
  padding-left: 0.5rem;
  position: relative;
  width: 100%;
}
.rd-question-details-header .flex-row .question-prompt.expanded-prompt {
  height: auto;
}
.rd-question-details-header .flex-row .question-prompt .clickable-span {
  background-color: var(--gray-100);
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: center;
  width: 3rem;
}
.rd-question-details-header .flex-row .fa-times-circle {
  cursor: pointer;
  margin-right: 0;
}
.rd-question-details-header .rd-filters-bar {
  display: flex;
  justify-content: space-between;
}

