.virtual-focus-group {
  .top-row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .link-row {
    display: flex;
    align-items: baseline;
    margin-bottom: 0.5rem;
    > div:first-child {
      white-space: nowrap;
      width: 8rem;
    }
    .read-only-link {
      background-color: var(--gray-200);
      border: var(--border);
      border-radius: 4px;
      margin-right: 0.5rem;
      padding: 0.25rem 0.75rem;
      min-width: 15rem;
      max-width: 40rem;
    }
  }
  .waiting-and-active {
    display: flex;
    margin-top: 2rem;
    > div {
      width: 50%;
    }
    .participant-filter {
      vertical-align: bottom;
      margin-left: 0.5rem;
    }
    .ant-select {
      .ant-select-selection {
        border: none;
        box-shadow: none;
        margin-left: -1.5rem;
        opacity: 0.7;
        position: absolute;
        margin-top: -1rem;
        width: 100%;
      }
      .ant-select-selection:hover {
        cursor: pointer;
      }
      ul:first-child {
        margin-left: 1.5rem;
      }
    }
  }
  .waiting-table-settings {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 98%;
  }
  .table-container {
    width: 98%;
    height: 50vh;
    border: var(--border);
    overflow-y: auto;
  }
  .vfg-table {
    height: 100%;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    .small-font,
    i.fa-flag,
    i.fa-ban {
      font-size: 1.25rem;
    }
    th {
      border: none;
    }
    tbody {
      overflow-y: auto;
    }
  }
}
