.survey-comparative-views .translate-label {
  margin-left: 0.4rem;
}
.survey-comparative-views .filter-bar {
  margin: 0 0.4rem;
  border-radius: 5px;
}
.survey-comparative-views .comparative-view {
  border: 1px solid var(--gray-300);
  border-radius: 5px;
  margin: 0.4rem;
  padding: 1rem;
  width: auto;
  min-width: fit-content;
}
.survey-comparative-views .comparative-view .name-and-config {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.survey-comparative-views .comparative-view .name-and-config .view-title {
  max-width: 15rem;
}
.survey-comparative-views .comparative-view .name-and-config .select {
  max-width: 12rem;
}
.survey-comparative-views .comparative-view .name-and-config .display-options {
  width: 9rem;
  display: inline-block;
}
.survey-comparative-views .comparative-view .name-and-config .copy-clone {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 5.5rem;
}
.survey-comparative-views .comparative-view .name-and-config .copy-clone > * {
  margin: 0 0.5rem;
}
.survey-comparative-views .comparative-view .name-and-config i:hover,
.survey-comparative-views .comparative-view .name-and-config .display-options:hover,
.survey-comparative-views .comparative-view .name-and-config .select:hover {
  cursor: pointer;
}
.survey-comparative-views .comparative-view .table-and-sig-testing {
  display: flex;
  justify-content: space-between;
}
.survey-comparative-views .comparative-view .table-and-sig-testing .comparative-table-view {
  min-width: 80%;
}
.survey-comparative-views .comparative-view .table-and-sig-testing .comparative-table-view table {
  width: 100%;
}
.survey-comparative-views .comparative-view .table-and-sig-testing .comparative-table-view table tr {
  line-height: 2;
}
.survey-comparative-views .comparative-view .table-and-sig-testing .sig-testing-selection {
  margin: 0 1rem;
}

.modal .comparative-view-question-answer {
  max-width: 70vw;
  margin: 1.75rem auto;
}
.modal .comparative-view-question-answer .field-name {
  width: 10rem;
  display: inline-block;
  margin-left: 1rem;
}
.modal .comparative-view-question-answer .survey-content {
  border-bottom: 4px solid var(--gray-700);
}
.modal .comparative-view-question-answer .truncated {
  display: inline-block;
  max-width: inherit;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal .comparative-view-question-answer .non-truncated {
  color: var(--gray-900);
  white-space: normal;
}
.modal .comparative-view-question-answer .selected-row {
  background-color: var(--lightblue);
  border: 2px solid #95cafe;
}
.modal .comparative-view-question-answer .disabled-row {
  cursor: default;
  opacity: 0.25;
}
.modal .comparative-view-question-answer .disabled-row:hover {
  background-color: inherit;
}
.modal .comparative-view-question-answer .hidden-answer-selections {
  display: none;
}
.modal .comparative-view-question-answer .answer-selections {
  display: block;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  white-space: normal;
}
.modal .comparative-view-question-answer .answer-selections a {
  padding: 0 0.3rem;
}
.modal .comparative-view-question-answer .selected-answer {
  background-color: var(--lightblue);
}
.modal .comparative-view-question-answer .capitalize {
  text-transform: capitalize;
}

