.choice-answers-table {
  display: flex;
  margin-bottom: 2rem;
  table {
    thead {
      th {
        padding-right: 2rem;
        .fa-caret-up,
        .fa-caret-down {
          margin-left: 0.5rem;
        }
        .column-header {
          display: flex;
          align-items: center;
          width: 100%;
          .name-and-sort-caret {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 1rem;
            width: 6rem;
            .column-name {
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .fa-window-close {
            cursor: pointer;
            margin-left: auto;
          }
        }
      }
    }
    tbody {
      tr {
        &.selected {
          background-color: #d4e3f8;
          td:first-child {
            visibility: visible;
          }
        }
        td {
          padding: 0.5rem 2rem 0.5rem 0;
        }
        td:first-child {
          background-color: var(--white);
          color: #296ca9;
          padding-right: 0;
          visibility: hidden;
        }
        td:nth-child(2) {
          line-height: 1;
          width: 20rem;
          ul {
            margin: 0;
            padding-left: 1.5rem;
            li {
              font-size: larger;
              > span {
                color: initial;
                font-size: initial;
                position: relative;
                left: -5px;
              }
            }
          }
        }
        td.response-rate-cell {
          min-width: 5rem;
          .response-rate-div {
            display: flex;
            justify-content: space-between;
            vertical-align: middle;
            width: 100%;
            .response-rate-bar {
              height: 1.5rem;
              margin-right: 0.5rem;
            }
            .response-rate {
              font-weight: 600;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .compare-link {
    margin: 0 0 0 2rem;
    padding: 0;
    vertical-align: top;
  }
}

.other-answers-table {
  margin: 0 0 2rem 3.25rem;
}
