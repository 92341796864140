.create-client-modal {
  .modal-body {
    padding: 1.5rem;
    .row {
      flex-wrap: nowrap;
      label {
        width: 5rem;
      }
    }
  }
}
