.filter-response-counts {
  margin: 0 0 2rem 3.25rem;
}
.filter-response-counts table {
  color: var(--gray-800);
  margin: 1rem 0;
}
.filter-response-counts table tbody tr td .response-rate-bar {
  background-color: var(--gray-600);
  height: 1.5rem;
  margin: 0 1rem;
}

