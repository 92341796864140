.short-url-options {
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    > div:first-child {
      width: 8rem;
      white-space: nowrap;
    }
    input[type='number'] {
      width: 44px;
      height: 22px;
      text-align: end;
      padding: 0;
    }
  }
}
