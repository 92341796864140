.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: 'Small';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: 'Large';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  font-size: 20px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='40px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='40px']::before {
  content: 'Huge';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='40px']::before {
  font-size: 40px;
}
