@import '../../../../styles/styleConstants.scss';

.participant-metadata {
  margin-bottom: 1rem;
  .participant-metadata-container {
    border: $border;
    margin-top: 0.5rem;
    padding: 0.5rem;
    width: 100%;
    > div:first-child {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      width: 50%;
      > div {
        > span:first-child {
          margin-right: 2rem;
        }
      }
    }
    .show-all-link {
      color: $linkColor;
      cursor: pointer;
      font-size: 0.7rem;
    }
    .label-value-list {
      list-style: none;
      margin-top: 1rem;
      padding: 0;
      li {
        margin-bottom: 0.5rem;
        .item-label {
          color: $gray-800;
        }
        .item-value {
          color: $black;
          font-weight: 500;
        }
      }
    }
    .metadata-box-list-column-3 {
      column-count: 3;
    }
  }
}
