.virtual-focus-group .top-row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.virtual-focus-group .link-row {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.5rem;
}
.virtual-focus-group .link-row > div:first-child {
  white-space: nowrap;
  width: 8rem;
}
.virtual-focus-group .link-row .read-only-link {
  background-color: var(--gray-200);
  border: var(--border);
  border-radius: 4px;
  margin-right: 0.5rem;
  padding: 0.25rem 0.75rem;
  min-width: 15rem;
  max-width: 40rem;
}
.virtual-focus-group .waiting-and-active {
  display: flex;
  margin-top: 2rem;
}
.virtual-focus-group .waiting-and-active > div {
  width: 50%;
}
.virtual-focus-group .waiting-and-active .participant-filter {
  vertical-align: bottom;
  margin-left: 0.5rem;
}
.virtual-focus-group .waiting-and-active .ant-select .ant-select-selection {
  border: none;
  box-shadow: none;
  margin-left: -1.5rem;
  opacity: 0.7;
  position: absolute;
  margin-top: -1rem;
  width: 100%;
}
.virtual-focus-group .waiting-and-active .ant-select .ant-select-selection:hover {
  cursor: pointer;
}
.virtual-focus-group .waiting-and-active .ant-select ul:first-child {
  margin-left: 1.5rem;
}
.virtual-focus-group .waiting-table-settings {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 98%;
}
.virtual-focus-group .table-container {
  width: 98%;
  height: 50vh;
  border: var(--border);
  overflow-y: auto;
}
.virtual-focus-group .vfg-table {
  height: 100%;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
.virtual-focus-group .vfg-table .small-font,
.virtual-focus-group .vfg-table i.fa-flag,
.virtual-focus-group .vfg-table i.fa-ban {
  font-size: 1.25rem;
}
.virtual-focus-group .vfg-table th {
  border: none;
}
.virtual-focus-group .vfg-table tbody {
  overflow-y: auto;
}

