.user-edit .roles-container,
.user-edit .projects-container {
  max-height: 10rem;
  overflow-y: scroll;
}
.user-edit .roles-container .user-role-project:nth-child(even),
.user-edit .projects-container .user-role-project:nth-child(even) {
  background-color: var(--white);
}
.user-edit .roles-container .user-role-project:nth-child(odd),
.user-edit .projects-container .user-role-project:nth-child(odd) {
  background-color: var(--gray-100);
}
.user-edit .roles-container .role,
.user-edit .roles-container .project,
.user-edit .projects-container .role,
.user-edit .projects-container .project {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 0;
}
.user-edit .roles-container .role .row-label,
.user-edit .roles-container .project .row-label,
.user-edit .projects-container .role .row-label,
.user-edit .projects-container .project .row-label {
  margin: 0.25rem 0.5rem;
}
.user-edit button {
  margin-right: 1rem;
}

